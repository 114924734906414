import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  Heading,
  Textarea,
  Input,
  Spinner,
  Center,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Flex,
  useColorMode,
  Code
} from '@chakra-ui/react';
import { useWizard } from 'react-use-wizard';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useFormContext } from '../utils/useFormContext';
import { updateData } from '../utils/utilities';
import { NoDataFound } from './common/NoDataFound';
import { baseBRDBuilderUrl, baseDevasUrl, baseUrl } from '../utils/constants';

export function WorkflowList() {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const { previousStep, nextStep } = useWizard();
    const [allAPIs, setAllAPIs] = useState([]);
    const { workFlowData, setWorkFlowData, setCurrentStep, mappedFyndApis, extraFyndApis, selectedExternalAPIs } = useFormContext();
    const [reviewComments, setReviewComments] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [editableWorkflow, setEditableWorkflow] = useState({ name: '', information: '' });
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
    const [newWorkflow, setNewWorkflow] = useState({ name: '', information: '' });
    const [loadingIndices, setLoadingIndices] = useState(new Set()); // Tracks the indices of workflows being enriched

    // Trigger workflow enrichment via API for a specific workflow
    const enrichWorkflow = async (index) => {
        setLoadingIndices((prev) => new Set([...prev, index]));
        const workflowToEnrich = allAPIs[index];
        const fyndAPIs = [...mappedFyndApis, ...extraFyndApis];

        try {
            const response = await fetch(`${baseDevasUrl}devas/chat/enrich_workflow`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    workflow_name: workflowToEnrich["name"],
                    workflow: workflowToEnrich["information"],
                    selected_apis: selectedExternalAPIs,
                    fynd_apis: fyndAPIs,
                }),
            });
            const enrichedData = await response.json();
            enrichedData["name"] = enrichedData["workflow_name"];
            enrichedData["information"] = enrichedData["workflow"];
            // Replace only the workflow at the specific index
            setAllAPIs((prevAPIs) => {
                const updatedAPIs = [...prevAPIs];
                updatedAPIs[index] = enrichedData;
                return updatedAPIs;
            });
            // Ensure the global workflow data is up-to-date
            setWorkFlowData((prevAPIs) => {
                const updatedAPIs = [...prevAPIs];
                updatedAPIs[index] = enrichedData;
                return updatedAPIs;
            });
        } catch (error) {
            console.error('Failed to enrich workflow:', error);
        }
        setLoadingIndices((prev) => {
            const newSet = new Set(prev);
            newSet.delete(index);
            return newSet;
        });
    };

    const handleReviewChange = (event) => {
        setReviewComments(event.target.value);
    };

    const handleNextClick = (event) => {
        setCurrentStep((prev) => prev + 1);
        updateData();
        nextStep();
    };

    const handlePreviousClick = (event) => {
        setCurrentStep((prev) => prev - 1);
        previousStep();
    };

    const handleEditClick = (index) => {
        setEditingIndex(index);
        setEditableWorkflow(allAPIs[index]);
    };

    const handleWorkflowChange = (event) => {
        const { name, value } = event.target;
        setEditableWorkflow((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSaveClick = () => {
        const updatedAPIs = allAPIs.map((workflow, index) =>
            index === editingIndex ? { ...editableWorkflow } : workflow
        );
        setAllAPIs(updatedAPIs);
        setWorkFlowData(updatedAPIs);
        setEditingIndex(null); // Exit edit mode
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
    };

    // New Workflow Modal Handlers
    const handleNewWorkflowChange = (event) => {
        const { name, value } = event.target;
        setNewWorkflow({ ...newWorkflow, [name]: value });
    };

    const handleAddNewWorkflow = () => {
        setAllAPIs((prevAPIs) => [...prevAPIs, newWorkflow]);
        setWorkFlowData((prevAPIs) => [...prevAPIs, newWorkflow]);
        setNewWorkflow({ name: '', information: '' });
        setIsModalOpen(false);
    };

    const handleDeleteWorkflow = (index) => {
        // Remove the workflow from both allAPIs and workFlowData
        setAllAPIs((prevAPIs) => prevAPIs.filter((_, i) => i !== index));
        setWorkFlowData((prevAPIs) => prevAPIs.filter((_, i) => i !== index));
    };

    useEffect(() => {
        setAllAPIs(workFlowData);
    }, []);

    return (
        <Box borderWidth='1px' borderRadius='lg' overflow='hidden' p={6} m={6}>
            <Flex justify='space-between' align='center' mb={4}>
                <Heading size='lg'>API Flows</Heading>
                <Button colorScheme='gray' leftIcon={<AddIcon />} onClick={() => setIsModalOpen(true)}>
                    Add API Flow
                </Button>
            </Flex>
            {allAPIs.length > 0 ? (
                <VStack spacing={4} align='stretch'>
                    {allAPIs.map((workflow, index) =>
                        index === editingIndex ? (
                            <Box key={index} p={5} shadow='md' borderWidth='1px'>
                                <Input
                                    name='name'
                                    value={editableWorkflow.name}
                                    onChange={handleWorkflowChange}
                                    placeholder='Workflow Name'
                                    mb={2}
                                    borderColor="gray.300"
                                    focusBorderColor="purple.400"
                                />
                                <Textarea
                                    name='information'
                                    value={editableWorkflow.information}
                                    onChange={handleWorkflowChange}
                                    placeholder='Workflow Information'
                                    borderColor="gray.300"
                                    focusBorderColor="purple.400"
                                />
                                <HStack mt={2} justify='center'>
                                    <Button onClick={handleSaveClick}>Save</Button>
                                    <Button onClick={handleCancelEdit}>Cancel</Button>
                                </HStack>
                            </Box>
                        ) : (
                            <Box key={index} p={5} shadow='md' borderWidth='1px'>
                                <Text fontWeight='bold'>
                                    {index + 1}. {workflow.name}
                                </Text>
                                <Code mt={5} mb={5} p={2} borderRadius='md' whiteSpace='pre-wrap' display='block' textAlign="left" bg={"gray.100"}>
                                    {workflow.information}
                                </Code>
                                <HStack mt={2} justifyContent='center'>
                                    <Button
                                        onClick={() => handleEditClick(index)}
                                        isDisabled={loadingIndices.has(index)}
                                    >
                                        Edit
                                    </Button>
                                    <Button onClick={() => enrichWorkflow(index)} isDisabled={loadingIndices.has(index)}>
                                        {loadingIndices.has(index) ? <Spinner size="sm" /> : 'Enrich Workflow'}
                                    </Button>
                                    <Button
                                        colorScheme='red'
                                        onClick={() => handleDeleteWorkflow(index)}
                                        size='md'
                                        isDisabled={loadingIndices.has(index)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </HStack>
                            </Box>
                        )
                    )}
                </VStack>
            ) : (
                <NoDataFound forTable={false} />
            )}
            <Textarea
                placeholder='Add your comments here...'
                display={"none"}
                size='md'
                mt={10}
                value={reviewComments}
                onChange={handleReviewChange}
            />
            <HStack mt={10} justify='center'>
                <Button onClick={handlePreviousClick}>Back</Button>
                <Button onClick={handleNextClick}>Next</Button>
            </HStack>

            {/* Modal to Add New Workflow */}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Workflow</ModalHeader>
                    <ModalBody>
                        <FormControl mb={3}>
                            <FormLabel>Workflow Name</FormLabel>
                            <Input name='name' value={newWorkflow.name} onChange={handleNewWorkflowChange} borderColor="gray.300"
                                    focusBorderColor="purple.400" />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel>Information/Description</FormLabel>
                            <Textarea name='information' value={newWorkflow.information} onChange={handleNewWorkflowChange} borderColor="gray.300"
                                    focusBorderColor="purple.400" />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='gray' onClick={handleAddNewWorkflow}>Add Workflow</Button>
                        <Button ml={3} onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
