import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  InputGroup,
  Spinner,
  useColorModeValue,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useToast
} from '@chakra-ui/react';
import { FiSettings, FiHelpCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PrSideBar from './PrSideBar';
import PrHeader from './PrHeader';
import { baseReviewPilotUrl, baseDevasUrl } from '../../utils/constants';
import { getOrgNameById, getSessionDetails, isManager } from '../../utils/utilities';
import { NoDataFound } from '../common/NoDataFound';

const PrRepositories = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [repositories, setRepositories] = useState([]);
  const [tokens, setTokens] = useState(true);
  const [azureRepositories, setAzureRepositories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [projectSearchTerm, setProjectSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [filteredRepos, setFilteredRepos] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [isPatModalOpen, setIsPatModalOpen] = useState(false);
  const [azurePat, setAzurePat] = useState('');
  const [devopsUrl, setDevopsUrl] = useState('');
  const debounceTimeout = useRef(null);
  const token = JSON.parse(atob(localStorage.getItem("token")));
  const roleSlug = token?.session?.passport?.user?.roleSlug;

  let adoPat;

  const fetchData = async () => {
    setLoading(true);
    if (roleSlug === "super-admin") {
      try {
        const queryParams = new URLSearchParams({
          page: currentPage,
          size: 10,
        });

        if (searchTerm && searchTerm.length > 0) queryParams.set('search_repo', searchTerm);
        if (projectSearchTerm && projectSearchTerm.length > 0) queryParams.set('search_project', projectSearchTerm);

        const response = await fetch(`${baseReviewPilotUrl}codereviewer/list_repos?${queryParams}`, { credentials: 'include' });
        const data = await response.json();
        setRepositories(data.data);
        setTotalPages(Math.ceil(data.pagination.total_records / 10));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch(`${baseReviewPilotUrl}codereviewer/repos/integrated`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_pat: localStorage.getItem("adoPat"),
            search_term: searchTerm,
            page: currentPage,
            size: 10
          }),
          credentials: 'include'
        });
        const data = await response.json();
        if (data.success) {
          setRepositories(data.data);
          setTotalPages(Math.ceil(data.pagination.total_records / 10));
        } else {
		  setRepositories([]);
        }
      } catch (error) {
        setError(error);
        console.error("Fetch repositories error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchTokens = async () => {
	if (roleSlug == "org-owner") {
		const resp = await fetch(`${baseReviewPilotUrl}org_details/${getSessionDetails().session.passport.user.orgId}`, {
		  method: 'GET',
		  headers: {
			  'Content-Type': 'application/json'
		  },
		  credentials: 'include'
	   });
	   const data = await resp.json();
  
	   if (data.success === true) {
		// const data = await resp.json();
		localStorage.setItem("adoPat", data.data.vc_pat);
		setTokens(true);
		fetchData();
	   } else {
		setIsPatModalOpen(true);
		setTokens(false);
	   }
	} else {
		try {
			const response = await fetch(`${baseDevasUrl}user/config/${localStorage.getItem("email")}`, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json'
			  },
			  credentials: 'include'
			});
	  
			const data = await response.json();
	  
			if (data.success === true) {
			  adoPat = data.data.azure_pat_token;
			  localStorage.setItem("adoPat", adoPat);
			  setTokens(true);
			  fetchData();
			} else {
			  setRepositories([]);
			//   setIsPatModalOpen(true);
			  setTokens(false);
			}
		  } catch (error) {
			console.error('Error fetching tokens:', error);
			setTokens(false);
		  }
	}
    
  };


  const savePatToken = async () => {
    const userEmail = localStorage.getItem("email");
    const sessionDetails = getSessionDetails();
    const orgId = sessionDetails.session.passport.user.orgId;
    const orgName = getOrgNameById(sessionDetails.session.passport.user.workspace, orgId);
    const roleSlug = sessionDetails.session.passport.user.roleSlug;

    if (!azurePat || !devopsUrl) return;

    try {
        const response = await fetch(`${baseDevasUrl}user/config`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ email_id: userEmail, azure_pat_token: azurePat })
        });

        const data = await response.json();

        if (data.success) {
            if (roleSlug === "org-owner") {
                await fetch(`${baseReviewPilotUrl}org_details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        org_id: orgId,
                        org_name: orgName,
                        vc_url: devopsUrl,
                        vc_pat: azurePat
                    })
                });
            }

            setIsPatModalOpen(false);
            toast({
                title: "Azure PAT saved successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            fetchData();
            setLoading(false)
        } else {
            toast({
                title: "Failed to save Azure PAT!",
                description: data.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    } catch (error) {
        toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
        });
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  useEffect(() => {
	setLoading(false);
  }, [isPatModalOpen]);

  const refreshData = () => {
    fetchData();
  }

  const fetchAzureRepos = async () => {
    setModalLoading(true);
    try {
      const response = await fetch(`${baseReviewPilotUrl}codereviewer/list_repos/provider`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_pat: localStorage.getItem("adoPat") }),
        credentials: 'include'
      });
      const data = await response.json();
      setAzureRepositories(data.data);
      setFilteredRepos(data.data);
    } catch (error) {
      console.error('Error fetching Azure repositories:', error);
    } finally {
      setModalLoading(false);
    }
  };

  const boxBg = useColorModeValue('white', 'gray.700');
  const mainBg = useColorModeValue('blue.50', 'gray.800');

  const handleSearch = (value) => {
    fetchData(value, projectSearchTerm, 1);
  };

  const handleProjectSearch = (value) => {
    fetchData(searchTerm, value, 1);
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => handleSearch(event.target.value), 1000);
  };

  const handlePaginationClick = (newPage) => {
    setCurrentPage(newPage);
    fetchData(searchTerm, projectSearchTerm, newPage);
  };

  const handleModalSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilteredRepos(azureRepositories.filter(repo => repo.name.toLowerCase().includes(value)));
  };

  const handleAddRepository = async (repoId) => {
    if (repoId) {
      setModalLoading(true);
      try {
        const repoToAdd = azureRepositories.find(repo => repo.id === repoId);
        const response = await fetch(`${baseReviewPilotUrl}codereviewer/repo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([{
            id: repoToAdd.id,
            name: repoToAdd.name,
            project_id: repoToAdd.project_id,
            project_name: repoToAdd.project_name
          }]),
          credentials: 'include'
        });

        if (response.ok) {
          toast({
            title: 'Repository added',
            description: `Repository ${repoToAdd.name} was added successfully.`,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          fetchData();
        } else {
          throw new Error('Failed to add repository');
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsModalOpen(false);
        setSelectedRepo(null);
        setModalLoading(false);
      }
    }
  };

  if (error) {
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <Flex h="100vh" flexDir="column" bg={mainBg}>
      <PrHeader userName="prathm201999" changeWorkspace={refreshData} tokens={tokens} />
      <Flex flex="1" overflow="hidden" direction={{ base: 'column', md: 'row' }}>
        <PrSideBar currentPage="/repositories" />
        <Box flex="1" p="6" bg={boxBg} overflowY="auto">
          <Flex justifyContent="space-between" mb="6" direction={{ base: 'column', md: 'row' }}>
            <Flex alignItems="center" mb={{ base: '4', md: '0' }}>
              <Text fontSize="2xl" fontWeight="bold" mr={1} mb={1}>Repositories</Text>
              {isManager() && (
                <Tooltip label="These are all the repositories accessible to Review Pilot. To add a repository, click the 'Add Repositories' button on the right." aria-label="A tooltip" hasArrow>
                  <span><FiHelpCircle /></span>
                </Tooltip>
              )}
            </Flex>
            {isManager() && (
              <Button ml="2" colorScheme="purple" onClick={() => {
                setIsModalOpen(true);
                fetchAzureRepos();
              }}>Add Repository</Button>
            )}
          </Flex>

          <Flex mb="4">
            <InputGroup size="md">
              <Input
                type="text"
                placeholder="Search by repository or project name..."
                value={searchTerm}
                onChange={handleSearchInputChange}
                bg="white"
                borderColor="gray.300"
                focusBorderColor="purple.400"
                rounded="md"
                _placeholder={{ color: 'gray.400' }}
              />
            </InputGroup>
          </Flex>
  
          {loading ? (
            <Flex justify="center" align="center" height="100%">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Table>
              <Thead>
                <Tr>
                  <Th>Repository</Th>
                  <Th>Project</Th>
                  {isManager() && <Th>Actions</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {repositories.length === 0 ? (
                  <NoDataFound forTable={true} />
                ) : (
                  repositories.map(repo => (
                    <Tr key={repo.id}>
                      <Td>{repo.name}</Td>
                      <Td>{repo.project_name}</Td>
                      {isManager() && (
                        <Td>
                          <IconButton
                            icon={<FiSettings />}
                            variant="outline"
                            colorScheme="gray"
                            aria-label="Configure"
                            onClick={() => navigate(`/configuration/${repo.id}`, {
                              state: {
                                repo_name: repo.name
                              }
                            })}
                          />
                        </Td>
                      )}
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          )}
  
          {/* Pagination */}
          {repositories.length > 0 && (
            <HStack mt="4" justifyContent="center">
              <IconButton
                icon={<ChevronLeftIcon />}
                aria-label="Previous Page"
                onClick={() => handlePaginationClick(currentPage - 1)}
                isDisabled={currentPage === 1}
              />
              <Text>
                Page {currentPage} of {totalPages}
              </Text>
              <IconButton
                icon={<ChevronRightIcon />}
                aria-label="Next Page"
                onClick={() => handlePaginationClick(currentPage + 1)}
                isDisabled={currentPage === totalPages}
              />
            </HStack>
          )}
        </Box>
      </Flex>
  
      {/* Modal for adding repository */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Repository</ModalHeader>
          <ModalCloseButton color="black" _hover={{ color: 'white', bg: 'purple.600' }} />
          <ModalBody maxH="400px" overflowY="auto" pt="0">
            <Box position="sticky" top="0" bg="white" zIndex="1" p="4">
              <InputGroup size="md" mb="4">
                <Input
                  type="text"
                  placeholder="Search repository..."
                  onChange={handleModalSearchChange}
                  bg="white"
                  borderColor="gray.300"
                  focusBorderColor="purple.400"
                  rounded="md"
                  _placeholder={{ color: 'gray.400' }}
                />
              </InputGroup>
              {modalLoading && <Flex justify="center" align="center" height="100%">
                <Spinner size="md" />
              </Flex>}
            </Box>
            {!modalLoading && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Repository Name</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
				{filteredRepos.length === 0 ? (
                  <NoDataFound forTable={true} />
                ) : (
                  filteredRepos.map(repo => (
                    <Tr key={repo.id}>
                      <Td>{repo.name}</Td>
                      <Td>
                        <Button
                          colorScheme="purple"
                          onClick={() => handleAddRepository(repo.id)} // Adjust handleAddRepository to work with repo.id directly
                        >
                          Add
                        </Button>
                      </Td>
                    </Tr>
                  )))}
                </Tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button ml={3} onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for entering Azure PAT Token */}
      <Modal isOpen={isPatModalOpen} onClose={() => setIsPatModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Azure DevOps PAT Required</ModalHeader>
          <ModalCloseButton color={"black"} _hover={{ "color": "white", bg: "purple.600" }} />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Azure PAT Token</FormLabel>
              <Input
                value={azurePat}
                onChange={(e) => setAzurePat(e.target.value)}
                placeholder="Enter Azure PAT Token"
                focusBorderColor="purple.400"
              />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>DevOps URL</FormLabel>
              <Input
                value={devopsUrl}
                onChange={(e) => setDevopsUrl(e.target.value)}
                placeholder="Enter DevOps URL"
                focusBorderColor="purple.400"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="purple"
              onClick={savePatToken}
              isDisabled={!azurePat || !devopsUrl}
            >
              Save
            </Button>
            <Button
              variant="ghost"
              onClick={() => setIsPatModalOpen(false)}
              ml={3}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
  
};

export default PrRepositories;
