import React, { useState } from 'react';
import {
  Box, Button, FormControl, Input, VStack, Heading, useToast, Center, Text
} from '@chakra-ui/react';
import { baseNejiUrl } from '../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const location = useLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const handlePasswordReset = async () => {
    if (newPassword !== confirmNewPassword) {
      toast({
        title: 'Error',
        description: 'Passwords do not match',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      return;
    }

    const url = `${baseNejiUrl}authentication/set-password`;
    const body = { password: newPassword, code: code };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        credentials: 'include'
      });

      const data = await response.json();
      if (response.ok) {
        toast({
          title: 'Password Reset',
          description: 'Your password has been successfully reset.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        });
        navigate("/");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  return (
    <Center h="100vh">
      <Box
        p={8}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        bg="white"
        maxWidth="400px"
        width="100%"
        textAlign="center"
      >
        <VStack spacing={6}>
          <Heading as="h2" size="lg">Reset Password</Heading>
          <FormControl id="new-password">
            <Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              focusBorderColor="purple.400"
              size="lg"
              variant="filled"
              bg="gray.100"
            />
          </FormControl>
          <FormControl id="confirm-new-password">
            <Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              focusBorderColor="purple.400"
              size="lg"
              variant="filled"
              bg="gray.100"
            />
          </FormControl>
          <Button
            colorScheme="purple"
            onClick={handlePasswordReset}
            isDisabled={!newPassword || !confirmNewPassword}
            size="lg"
            width="100%"
          >
            Reset Password
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default ForgotPasswordForm;
