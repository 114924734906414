import { baseBRDBuilderUrl, baseCerebrumUrl, baseUrl, roleHierarchy } from "./constants";

export function allStorage() {

    let archive = {}, // Notice change here
        keys = Object.keys(localStorage),
        i = keys.length;

    while ( i-- ) {
        if (keys[i] !== "token" && keys[i] !== "email" && keys[i] !== "current_extension_id" && keys[i] !== "chakra-ui-color-mode" && keys[i] !== "login_type") {
            archive[ keys[i] ] = localStorage.getItem( keys[i] );
        }
    }

    return archive;
}

export const updateData = async () => {
    const data = allStorage();
    const resp = await fetch(`${baseCerebrumUrl}extensions?row_id=` + localStorage.getItem("current_extension_id"), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
}

export const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};


export const isManager = () => {
    const token = JSON.parse(atob(localStorage.getItem("token")));
    const roleSlug = token?.session?.passport?.user?.roleSlug;
    return roleSlug === "engg-manager" || roleSlug === "org-owner";
}

export const getSessionDetails = () => {
    const token = JSON.parse(atob(localStorage.getItem("token")));
    return token;
}


export const getOrgNameById = (workspace, orgId) => {
    const org = workspace.find(item => item.orgId === orgId);
    return org ? org.orgName : null;
}

export const canInvite = (currentRole, invitedRole) => {
    if (currentRole) {
        const allowedRoles = roleHierarchy[currentRole];
        return allowedRoles.includes(invitedRole);
    }
}