import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./apis_list_page.css";

const APIListPage = (props) => {
  const location = useLocation();
  
  const [labels, setLabels] = useState(['Label 1', 'Label 2', 'Label 3']);
  const [quipLinks, setQuipLinks] = useState([]);
  const [allAPIs, setAllAPIs] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [selectedAPIs, setSelectedAPIs] = useState([]);
  const [reviewComments, setReviewComments] = useState("");
  const [mermaidTexts, setMermaidTexts] = useState([]);
  const navigate = useNavigate();

  const fetchAPIs = async () => {
    setProcessing(true);
    try {
      const response = await fetch('https://api.fexz0.de/service/public/v1.0/devas/chat/extract_api', {
        // const response = await fetch('http://localhost:8081/v1.0/devas/chat/extract_api', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ quip_links: location.state.quipLinks }),
      }
    );
      const data = await response.json();
      setAllAPIs(data);
    } catch (error) {
      console.error('Failed to fetch labels:', error);
    }
    setProcessing(false);
  };

  const handleNavigateToHomePage = () => {
    navigate("/", {
      state: {
        quipLinks: quipLinks,
        selectedAPIs: selectedAPIs,
        allAPIs: allAPIs,
      },
    });
  }

  const handleNavigateToDevas = () => {
    navigate("/devas", {
      state: {
        reviewComments: reviewComments,
        quipLinks: quipLinks,
        mermaidTexts: mermaidTexts,
      },
    });
  };

  // Dummy functions for button actions
  const handleNext = () => console.log('Next clicked');
  const handleRetry = () => {
    fetchAPIs();
  };
  const handleBack = () => console.log('Back clicked');
  const handleHomepage = () => console.log('Homepage clicked');

  useEffect(() => {
    setQuipLinks(location.state.quipLinks);
    setReviewComments(location.state.reviewComments);
    setMermaidTexts(location.state.mermaidTexts);

    fetchAPIs();
  }, [location.state.quipLinks]);

  const handleCheckboxChange = (api) => {
    if (selectedAPIs.includes(api)) {
      setSelectedAPIs(selectedAPIs.filter(selectedApi => selectedApi !== api));
    } else {
      setSelectedAPIs([...selectedAPIs, api]);
    }
  };

  return (
    <div className="apis-list-container">
      <div className="left-column">
        <button onClick={handleNavigateToHomePage}>Homepage</button>
        <button onClick={handleNavigateToDevas}>Generate Code</button>
        <button onClick={handleRetry}>Retry</button>
        {/* <button onClick={handleBack}>Back</button> */}
      </div>
      <div className="right-column">
        <h1>Select APIs For Code Generation</h1>
        {allAPIs.map((api, index) => (
          <div key={index} className="api-item">
            <span className="api-item-selection">
            <input
              type="checkbox"
              className="api-checkbox"
              id={`checkbox-${index}`}
              onChange={() => handleCheckboxChange(api.name)}
              checked={selectedAPIs.includes(api.name)}
            />
            <label htmlFor={`checkbox-${index}`} className="api-name">
              {api.name}
            </label>
            </span>
            <label htmlFor={`checkbox-${index}`} className="api-description">
              {api.description}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default APIListPage;

