import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { defaultScreenshot } from "../../utils/screenshot";
import { baseDevasUrl, baseDevasWebsocketUrl } from "../../utils/constants"
import {
    Box,
    Button,
    Input,
    Text,
    VStack,
    Flex,
    Heading,
    Divider,
    keyframes,
    useColorModeValue,
    useColorMode,
    IconButton,
    Spacer,
    Image,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { ArrowForwardIcon, DownloadIcon } from "@chakra-ui/icons";
import MarkdownEditor from '@uiw/react-markdown-editor';
import ChatComponent from './../common/ChatComponent';

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.7);
  }
`;

function PRDDevasPage(props) {
    let { projectId } = useParams();
    console.log("projectId >>", projectId);
    const location = useLocation();
    const { brdData } = location.state || { brdData: '' };
    const { toggleColorMode, setColorMode, colorMode } = useColorMode();
    let brdSentOnce = false;
    const [socket, setSocket] = useState(null);
    const [userMessage, setUserMessage] = useState("");
    const [file, setFile] = useState(null);
    const [externalLink, setExternalLink] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [markdownContent, setMarkdownContent] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const initialColorModeSet = useRef(false);
    const [url, setUrl] = useState(null);
    const [screenshot, setScreenshot] = useState(defaultScreenshot);
    const [pastThreads, setPastThreads] = useState([]);
    const [chatConfig, setchatConfig] = useState('');
    const getThreadsApi = `${baseDevasUrl}projects/${projectId}/threads`;

    let tempchatConfig = {
        communicationURL: brdData.length > 0 ? `${baseDevasWebsocketUrl}devas/chat/prd_builder/${projectId}` : '',
        communicationMethod: 'websocket',
        getCommunicationURL: (thread_id) => `${baseDevasWebsocketUrl}devas/chat/prd_builder/${thread_id}`,
        isCrateNewSocket: false,
        socket: socket,
        colors: {
            background: {
                light: "gray.50",
                dark: "gray.800"
            },
            userMessageBackground: {
                light: "green.100",
                dark: "green.800"
            },
            assistantMessageBackground: {
                light: "blue.100",
                dark: "blue.800"
            },
            planMessageBackground: {
                light: "blue.100",
                dark: "blue.900"
            },
            cardBackground: {
                light: "white",
                dark: "gray.700"
            }
        }
    };

    const fetchPastThreads = async () => {
        try {
            const response = await fetch(getThreadsApi);
            if (!response.ok) {
                throw new Error('Failed to fetch past threads');
            }
            const response_data = await response.json();
            if (response_data.data.length > 0) {
                response_data.data.sort((x, y) => y.id - x.id);
                setPastThreads(response_data.data);
                loadThreadMessages(response_data.data[0].id);
                tempchatConfig.communicationURL = `${baseDevasWebsocketUrl}devas/chat/prd_builder/${response_data.data[0].id}`
                setchatConfig(tempchatConfig);
            }
        } catch (error) {
            console.error('Error fetching past threads:', error);
        }
    };

    const loadThreadMessages = (thread_id) => {
        setMarkdownContent("")
        setChatMessages([]);
        fetch(`${baseDevasUrl}threads/${thread_id}/messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.success && response.data.length > 0) {
                    const messages = response.data
                    // console.log('messages >>', messages);
                    for (const message of messages) {
                        // console.log("message >>", message);
                        // console.log("chatMessages >>", chatMessages);
                        if (message.is_json) {
                            const data = JSON.parse(message.content);
                            console.log("all data=",data)
                             if(data.window === 'markdown'){
                                console.log("markdown content",data.message)
                                setMarkdownContent(data.message)
                            }
                            else if (data.window === 'browser') {
                                updateBrowser(data);
                            } else if (data.type === 'plan') {
                                receiveMessage(data.message, 'plan');
                            } else {
                                receiveMessage(data.message, 'text');
                            }
                        } else {
                            setChatMessages((previousChat) => [...previousChat, message]);
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    const createChatThread = async (name) => {
        setChatMessages([]);
        const response = await fetch(`${baseDevasUrl}threads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "requested_by": localStorage.getItem("email"),
                "alternate_id": `${projectId}`
            }),
        });

        if (response.ok) {
            const response_data = await response.json();
            await fetchPastThreads();
            const thread_id = response_data.data.thread_id;
            return thread_id;
        }
    };

    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };

    const updateBrowser = (data) => {
        if (data.type === "link") {
            setUrl(data.message.link);
        } else if (data.type === "image") {
            setScreenshot(data.message.image);
        }
    };

    const receiveMessage = (message, type) => {
        if (type === 'plan') {
            const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'plan' }]);
        } else {
            setChatMessages((prev) => [...prev, { role: "assistant", content: message, type: 'text' }]);
        }
    };

    const inputParser = (input) => {
        return input.trim();
    };

    const outputParser = (output_data) => {
        const data = JSON.parse(output_data);
        if (data.window === 'markdown') {
            setMarkdownContent(data.message);
        } 
        else if (data.type === 'plan') {
            const steps = Object.entries(data.message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            return { role: "assistant", content: steps, type: 'plan' };
        } else {
            return { role: "assistant", content: data.message, type: 'text' };
        }
    };

    const inputmessageParser = (messageData) => {
        return { role: "user", content: messageData };
    };

    const renderMessages = (chatMessages) => {
        return chatMessages.map((msg, index) => (
            msg.type === 'plan' ? (
                <Box key={index} bg={planMessageBgColor} p={4} borderRadius="md" alignSelf="flex-start">
                    {msg.content.split("\n").map((line, idx) => (
                        <Text key={idx} fontSize="md" mb={2}>{line}</Text>
                    ))}
                </Box>
            ) : (
                <Text key={index} bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor} p={3} borderRadius="md" alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}>{msg.content}</Text>
            )
        ));
    };

    useEffect(() => {
        setColorMode("dark");
        setchatConfig(tempchatConfig);
        fetchPastThreads();
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleLinkChange = (event) => {
        setExternalLink(event.target.value);
    };

    const handleSendAttachment = () => {
        console.log("Sending file:", file);
        console.log("Sending link:", externalLink);
    };

    const downloadPDF =  async () => {
        console.log("calling the markdown to pdf api")
        const formData = new FormData();
        formData.append('markdownContent', markdownContent);
        const response = await fetch(`${baseDevasUrl}devas/util/markdown_to_pdf`, {
          method: 'POST',
          body:formData,
        });
        
        if (response.ok) {
            // Create a blob from the PDF stream
            const blob = await response.blob();
            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            // Create a temporary anchor element and trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = "downloaded.pdf"; // You can name the file anything you want
            document.body.appendChild(a); // Append the anchor to the body
            a.click(); // Simulate click on the anchor to trigger the download
            window.URL.revokeObjectURL(url); // Clean up by revoking the blob URL
            a.remove(); // Remove the anchor from the body
        } else {
            console.error("Failed to download PDF. Status: ", response.status);
        }
    };

    const sendMessage = () => {
        if (socket && socket.readyState === WebSocket.OPEN && userMessage.trim()) {
            const message = { role: "user", content: userMessage };
            socket.send(JSON.stringify(userMessage));
            setChatMessages((prev) => [...prev, message]);
            setUserMessage("");
            setIsTyping(true);
        }
    };

    const bgColor = useColorModeValue("gray.50", "gray.800");
    const cardBgColor = useColorModeValue("white", "gray.700");
    const inputBgColor = useColorModeValue("white", "gray.600");
    const userMessageBgColor = useColorModeValue("green.100", "green.800");
    const assistantMessageBgColor = useColorModeValue("blue.100", "blue.800");
    const planMessageBgColor = useColorModeValue("blue.100", "blue.900");

    return (
        <Flex h="100vh" p={4} bg={bgColor}>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} mr={4}>
                <ChatComponent
                    chatConfig={chatConfig}
                    inputParser={inputParser}
                    outputParser={outputParser}
                    inputmessageParser={inputmessageParser}
                    chatMessages={chatMessages}
                    renderMessages={renderMessages}
                    setChatMessages={setChatMessages}
                    loadThreadMessages={(thread_id) => loadThreadMessages(thread_id)}
                    createChatThread={(name) => createChatThread(name)}
                    pastThreads={pastThreads}
                    setPastThreads={setPastThreads}
                />
            </Flex>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} maxW="800px" width="100%" margin="center">
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Heading fontSize="xl">PRD Viewer</Heading>
                    <Spacer />
                    <IconButton
                        ml={4}
                        onClick={toggleColorMode}
                        icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
                        aria-label="Toggle color mode"
                    />
                </Flex>
                <MarkdownEditor
                    value={markdownContent}
                    onChange={(editor, data, value) => setMarkdownContent(value)}
                    height={635}
                    visiableDragbar={false}
                    theme="light"
                    visible={true}
                    previewWidth={"100%"}
                    toolbars={[]}
                    view={{ menu: false, md: false, html: true, fullScreen: true }}
                />
                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                    <Spacer />
                    <Button onClick={downloadPDF} colorScheme="teal" mr={1}>Export As PDF <DownloadIcon ml={2}/></Button>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default PRDDevasPage;
