import React from 'react';
import {
  Box, Flex, Text, Stack, Heading, Image, useColorModeValue, Icon, Link, useColorMode
} from '@chakra-ui/react';
import { FaCode, FaTools, FaDraftingCompass ,FaFileAlt , FaProjectDiagram} from 'react-icons/fa';
import ProfileMenu from './common/MenuProfile';

const LandingPage = () => {
  const { setColorMode } = useColorMode();
  setColorMode("light");
  const boxBg = useColorModeValue('white', 'white');
  const mainBg = useColorModeValue('gray.50', 'gray.50');
  const textColor = useColorModeValue('gray.800', 'gray.800');
  const descriptionColor = useColorModeValue('gray.500', 'gray.400');
  const cardBg = useColorModeValue('white', 'white');
  const cardHoverBg = useColorModeValue('gray.100', 'gray.100');

  const boxHeight = "300px"; // Fixed height for uniformity

  return (
    <Box bg={mainBg} minH="100vh">
      <Flex justifyContent="space-between" alignItems="center" p={4} bg={boxBg} boxShadow="sm">
        <Flex alignItems="center">
          <Image src="https://res.cloudinary.com/ddf4fufyx/image/upload/v1714634024/ai-logo-in_njazhv.png" alt="FEX Logo" boxSize="50px" mr={4} />
          <Heading color={textColor} size="lg">Fynd Engineering X</Heading>
        </Flex>
        <ProfileMenu hideWorkspace={true} hideConfig={true} />
      </Flex>
      <Box p={10}>
        <Flex justifyContent="center" mb={8}>
          <Heading color={textColor}>Our Products</Heading>
        </Flex>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={8} align="center" justify="center">
          <Link href="/org-selection" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaCode} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>Code Quality Agent</Heading>
              <Text color={descriptionColor}>A powerful AI-based tool to automate and streamline code reviews for pull requests.</Text>
            </Box>
          </Link>
          <Link href="/my-brds" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaDraftingCompass} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>TDD Builder</Heading>
              <Text color={descriptionColor}>Build and manage your own design docs with AI.</Text>
            </Box>
          </Link>
          <Link href="/documents/projects" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaFileAlt} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>Doc Creator</Heading>
              <Text color={descriptionColor}>An AI Chief Product Officer that crafts and refines high-quality Documents (PRD/BRD)."</Text>
            </Box>
          </Link>

          <Link href="sequencediagram/projects" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaProjectDiagram} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>Sequence Diagramm Creator</Heading>
              <Text color={descriptionColor}>A tool that automatically generates sequence diagrams to visualize the interaction between different system components."</Text>
            </Box>
          </Link>


          <Link href="/devas/projects" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaTools} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>Devas</Heading>
              <Text color={descriptionColor}>Generate a full-fledged software using AI-based chain of thoughts.</Text>
            </Box>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default LandingPage;
