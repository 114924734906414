import React, { useState } from 'react';
import { Box, Button, Input, VStack, Flex, Text, Image, useToast, Center, IconButton, Tooltip, useColorMode } from "@chakra-ui/react";
import { AddIcon, DeleteIcon, ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';

function StartDevasPage() {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const [gitUrl, setGitUrl] = useState("");
    const [quipLinks, setQuipLinks] = useState([""]);
    const navigate = useNavigate();
    const toast = useToast();

    const handleGoToDevas = () => {
        if (!gitUrl && quipLinks.every(link => link === "")) {
            toast({
                title: "Invalid request",
                description: "Add the required github URL and quip links",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        navigate('/devas', { state: { gitUrl, quipLinks } });
    };

    const addQuipLinkInput = () => {
        setQuipLinks([...quipLinks, ""]);
    };

    const removeQuipLinkInput = (index) => {
        if (quipLinks.length > 1) {
            setQuipLinks(quipLinks.filter((_, i) => i !== index));
        }
    };

    const updateQuipLink = (index, value) => {
        const updatedQuipLinks = quipLinks.map((link, i) => i === index ? value : link);
        setQuipLinks(updatedQuipLinks);
    };

    return (
        <Center height="100vh">
            <VStack spacing={6} p={8} align="stretch" maxWidth="600px" w="full">
                <Flex align="center" justifyContent="center" mb={5}>
                    <Image src="https://res.cloudinary.com/ddf4fufyx/image/upload/v1714634024/ai-logo-in_njazhv.png" boxSize="70px" alt="Company Logo" />
                    <Text fontSize="2xl" fontWeight="normal" ml={2}>Fynd Engineering X</Text>
                </Flex>
                <Flex align="center">
                    <Input
                        placeholder="GitHub Repository URL"
                        value={gitUrl}
                        onChange={(e) => setGitUrl(e.target.value)}
                        mr={2}
                    />
                    <Tooltip label="GitHub Repository URL you want to make changes to" hasArrow>
                        <IconButton
                            aria-label="Info for Git URL"
                            icon={<InfoIcon />}
                            size="sm"
                            variant="ghost"
                        />
                    </Tooltip>
                </Flex>
                {quipLinks.map((link, index) => (
                    <Flex key={index} align="center">
                        <Input
                            placeholder={`Enter Quip Document Link ${index + 1}`}
                            value={link}
                            onChange={(e) => updateQuipLink(index, e.target.value)}
                            flex="1"
                        />
                        <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => removeQuipLinkInput(index)}
                            aria-label="Remove Quip Link"
                            ml={2}
                            colorScheme="red"
                            isDisabled={quipLinks.length === 1}
                        />
                    </Flex>
                ))}
                <Button leftIcon={<AddIcon />} onClick={addQuipLinkInput} colorScheme="green">Add Another Quip Link</Button>
                <Button colorScheme="gray" onClick={handleGoToDevas} rightIcon={<ExternalLinkIcon />}>Go to Devas</Button>
            </VStack>
        </Center>
    );
}

export default StartDevasPage;
