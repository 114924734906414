class WebSocketSingleton {
    // constructor(url) {
    //   if (WebSocketSingleton.instance?.url != url) {
    //     this.url = url;
    //     this.connection = null;
    //     this.initializeWebSocket();
    //     WebSocketSingleton.instance = this;
    //   }
  
    //   return WebSocketSingleton.instance;
    // }
  
    initializeWebSocket() {
      this.connection = new WebSocket(this.url);
  
      this.connection.onopen = () => {
        console.log('WebSocket connection opened');
      };
  
      this.connection.onmessage = (event) => {
        console.log('WebSocket message received:', event.data);
      };
  
      this.connection.onclose = () => {
        console.log('WebSocket connection closed');
      };
  
      this.connection.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
  
    sendMessage(message) {
      if (this.connection && this.connection.readyState === WebSocket.OPEN) {
        this.connection.send(message);
      } else {
        console.error('WebSocket is not open. Ready state:', this.connection.readyState);
      }
    }

    closeWebSocket() {
      if (this.connection) {
        this.connection.close();
      }
    }
  
    openWebSocket(url) {
      
      if (WebSocketSingleton.instance?.url != url) {
        console.log(WebSocketSingleton.instance)
        if (this.connection) {
          this.connection.close();
          this.connection = null;
        }
        this.url = url;
        this.initializeWebSocket();
        WebSocketSingleton.instance = this;
      }
  
      return WebSocketSingleton.instance;
    }
  }
  
  
  // const webSocketInstance = new WebSocketSingleton('wss://api.fexz0.de/service/websocket/v1.0/code/completion');
  // Object.freeze(webSocketInstance);
  
  export default WebSocketSingleton;
  