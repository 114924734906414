import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  useToast,
  Text,
  Box,
} from '@chakra-ui/react';
import { baseReviewPilotUrl } from '../../utils/constants';

const PromptModal = ({ isOpen, onClose, field, repoId, onSavePrompt }) => {
  const [prompt, setPrompt] = useState('');
  const [exampleCode, setExampleCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [regeneratedPrompt, setRegeneratedPrompt] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (!isOpen) {
      setPrompt('');
      setExampleCode('');
      setRegeneratedPrompt('');
      setIsLoading(false);
    }
  }, [isOpen]);

  const handleSave = async () => {
    setIsLoading(true);
    onSavePrompt(field, regeneratedPrompt); // Save prompt to the respective state in the parent component
    onClose();
  };

  const handleRegenerate = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseReviewPilotUrl}codereviewer/prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: field,
          rule: prompt,
          rule_example: exampleCode
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setRegeneratedPrompt(data.data.res_prompt);
        toast({ title: 'Prompt regenerated successfully', status: 'success' });
      } else {
        console.error('Failed to regenerate prompt:', response.statusText);
      }
    } catch (error) {
      console.error('Error regenerating prompt:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Prompt and Example Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            placeholder="Add custom prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            mb={3}
            focusBorderColor="purple.400"
          />
          <Textarea
            placeholder="Add example code"
            value={exampleCode}
            onChange={(e) => setExampleCode(e.target.value)}
            focusBorderColor="purple.400"
          />
          {regeneratedPrompt ? (
            <Box mt={3} p={3} borderWidth="1px" borderRadius="lg" bg="gray.50">
              <Text fontWeight="bold" mb={2}>Regenerated Prompt:</Text>
              <Text whiteSpace="pre-wrap">{regeneratedPrompt}</Text>
            </Box>
          ) : ''}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="purple" mr={3} onClick={handleRegenerate} isLoading={isLoading}>
            Generate
          </Button>
          <Button colorScheme="purple" mr={3} onClick={handleSave} isLoading={isLoading}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PromptModal;
