import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, IconButton, Input, Text, VStack, HStack, useColorMode } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons';
import { MdFileUpload } from 'react-icons/md';
import { useWizard } from "react-use-wizard";
import { useFormContext } from "../utils/useFormContext";
import slugify from "slugify";
import { updateData } from '../utils/utilities';
import { baseBRDBuilderUrl, baseDevasUrl, baseUrl } from '../utils/constants';


export function DocStep() {
	const { setColorMode } = useColorMode();
    setColorMode("light");
    const { previousStep, nextStep } = useWizard();
    const { formData, setFormData, currentStep, setApiMappings, setCurrentStep, setWorkFlowData, setBrdData, setDiagramsData, setMappingData, setApiData, setExtraFyndApis } = useFormContext();
    const [documents, setDocuments] = useState(formData.documents || [{ name: 'Document 1', url: '' }]);
    const fileInputRefs = useRef([]);

    useEffect(() => {
        setFormData({...formData, documents: documents.map(doc => ({ name: doc.name, url: doc.url })) });
    }, [documents]);

    const addDocument = () => {
        setDocuments(docs => [...docs, { name: `Document ${docs.length + 1}`, url: '' }]);
		setApiData([]);
		setWorkFlowData([]);
		setBrdData(null);
		setDiagramsData([]);
		setApiMappings([]);
		setExtraFyndApis([]);
    };

    const removeDocument = (index) => {
        setDocuments(docs => docs.filter((_, i) => i !== index));
        fileInputRefs.current.splice(index, 1);  // Ensure refs array is also updated
		setApiData([]);
		setWorkFlowData([]);
		setBrdData(null);
		setDiagramsData([]);
		setApiMappings([]);
    };

    const handleDocumentChange = (value, index) => {
        const newDocs = [...documents];
        newDocs[index].url = value;
        setDocuments(newDocs);
		setApiData([]);
		setWorkFlowData([]);
		setBrdData(null);
		setDiagramsData([]);
		setApiMappings([]);
    };

	const readFileData = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
			reader.readAsArrayBuffer(file);
		});
	}

	const handleNextClick = (event) => {
		
        let step = currentStep;
        setCurrentStep(step + 1);
		updateData();
		nextStep();
    }

	const handlePreviousClick = (event) => {
		let step = currentStep;
        setCurrentStep(step - 1);
		previousStep();
	}

    const handleFileUpload = async (file, index) => {
        if (file) {
			
			const fileData = await readFileData(file);
			const subPath = "surface";
			const signMethod = "put";
			// const reportBugApiUrl = `http://localhost:8081/v1.0/files/signed_urls/${subPath}/${signMethod}`;
			const reportBugApiUrl = `${baseDevasUrl}files/signed_urls/${subPath}/${signMethod}`;
			const filepath = document.getElementById("file-input-dummy-" + index).value;
			const timestamp = new Date().getTime();
			let filename = `${timestamp}_${slugify(file.name)}`;
			const signedUrlResponse = await fetch(reportBugApiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ "filenames": [filename] }),
			});
			const signedUrlJson = await signedUrlResponse.json();
			console.log("Signed URL JSON: ", signedUrlJson);
			let signedUrls = signedUrlJson?.data;
			let signedUrl = signedUrls[filename];
			try {
				const response = await fetch(signedUrl, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/pdf'
					},
					body: fileData
				});
				if (response.ok) {
					console.log('File uploaded successfully');
					const newDocs = [...documents];
					newDocs[index].url = filename;  // Update with the new file name
					setDocuments(newDocs);
				} else {
					console.error('Failed to upload the file');
				}
			} catch(err) {
				console.error(err);
			}
			
        }
    };

	// useEffect(async () => {
	// 	let resp = await fetch("http://localhost:8081/v1.0/extensions?email=mitanshubhatt@gofynd.com", {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},

	// 	});
	// }, [])

    const triggerFileInputClick = (index) => {
        fileInputRefs.current[index].click();
    };

    return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} m={6}>
            <Text fontSize="xl" mb={4}>Upload Documents</Text>
            <VStack spacing={4}>
                {documents.map((doc, index) => (
                    <HStack key={index} width="100%">
                        <Input placeholder={doc.name}
                            value={doc.url}
                            onChange={(e) => handleDocumentChange(e.target.value, index)}
							id={index}
							borderColor="gray.300"
							focusBorderColor="purple.400"
                        />
                        <input
                            type="file"
							id={`file-input-dummy-${index}`}
                            hidden
                            onChange={e => handleFileUpload(e.target.files[0], index)}
                            ref={el => fileInputRefs.current[index] = el}  // Assign ref
                        />
                        <IconButton
                            icon={<MdFileUpload />}
                            onClick={() => triggerFileInputClick(index)}
							display={"none"}
                        />
                        {documents.length > 1 && (
                            <IconButton
                                aria-label={`Remove ${doc.name}`}
                                icon={<CloseIcon />}
                                onClick={() => removeDocument(index)}
                            />
                        )}
                    </HStack>
                ))}
            </VStack>
            <Button mt={4} onClick={addDocument}>Add Another Document</Button>
            <HStack mt={6} justify={"center"}>
                <Button onClick={handlePreviousClick}>Back</Button>
                <Button onClick={handleNextClick}>Next</Button>
            </HStack>
        </Box>
    );
}
