import React from 'react';
import { Button, Stack, Box } from '@chakra-ui/react';

const steps = [
    { label: 'Basic Info', index: 0 },
    { label: 'Documents', index: 1 },
    { label: 'API Selection', index: 2 },
    { label: 'Workflows', index: 3 },
    { label: 'Flow Diagrams', index: 4 },
    { label: 'BRD Viewer', index: 5 }
];

// Directly receive the `method` prop
export function TopNav({ method }) {
    return (
        // Add margin and background color
        <Box bg="white" py={3} px={5} boxShadow="md" mb={4} borderBottom="1px solid #e2e8f0">
        <Stack direction="row" spacing={2} justifyContent="space-around">
            {steps.map((step) => (
            <Button
                key={step.index}
                variant="outline"
                size="sm"
                colorScheme="gray"
                onClick={() => method(step.index)}
            >
                {step.label}
            </Button>
            ))}
        </Stack>
        </Box>
    );
}
