import React from "react";
import { Box, VStack, Link, Text, Image, Flex, Button, Icon } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi"; // Import an icon from react-icons
import { useNavigate, useLocation } from "react-router-dom";
import { baseNejiUrl } from "../../utils/constants";

const Navbar = () => {
	const navigate = useNavigate();
	const location = useLocation(); // Get the current path

	const handleLogout = async () => {
		try {
			const response = await fetch(`${baseNejiUrl}authentication/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					credentials: 'include'
				}
			});

			if (response.ok) {
				// Remove tokens and other related data
				localStorage.removeItem("token");
				localStorage.removeItem("email");
				navigate("/");
				console.log("User has logged out");
			} else {
				console.error('Failed to log out');
			}
		} catch (error) {
			console.error('An error occurred during logout:', error);
		}
	};

	// Function to determine if the current route is active
	const isActiveRoute = (route) => location.pathname === route;

	return (
		<Box
			bg="transparent" // Make the background transparent
			w="40vh"
			h="100vh"
			px={4}
			py={8}
			position="absolute"
			borderRight="2px solid lightgray" // Add a light border line on the right side
		>
			<Flex flexDirection="column" h="100%">
				<VStack spacing={5} align="stretch" alignItems={"center"} flex="1">
					<Flex alignItems="center">
						<Image
							src="https://res.cloudinary.com/ddf4fufyx/image/upload/v1714634024/ai-logo-in_njazhv.png"
							boxSize="30px" // Adjust the size of the logo
							alt="Company Logo"
							mr={2} // Add margin to the right of the logo
						/>
						<Text fontSize="2xl" fontWeight="bold" color="black">
							Fynd Engineering X
						</Text>
					</Flex>
					<Link
						py={3}
						px={20}
						rounded="md"
						href="/my-brds"
						// Apply a different background color if the route is active
						bg={isActiveRoute("/my-brds") ? "purple.100" : "transparent"}
						_hover={{ bg: "gray.100" }}
					>
						My Design Docs
					</Link>
				</VStack>
				<Flex justifyContent="center" mt="auto"> {/* Align at the bottom */}
					<Button
						leftIcon={<Icon as={FiLogOut} />} // Use the logout icon
						colorScheme="red" // Red color to signify a critical action like logging out
						variant="solid"
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Navbar;
