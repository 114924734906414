// NoDataFound.js
import React from 'react';
import { Center, Text, Tr, Td, Box } from '@chakra-ui/react';

// Add a prop to control the message rendering style
export const NoDataFound = ({ colSpan = 5, forTable = false }) => {
    if (forTable) {
        return (
        <Tr>
            <Td colSpan={colSpan}>
            <Center>
                <Text color='gray.500' fontSize='lg'>
                    No data found
                </Text>
            </Center>
            </Td>
        </Tr>
        );
    }

    // Render the message inside a Box for non-table scenarios
    return (
        <Box borderWidth='1px' borderRadius='lg' p={6} my={6} textAlign='center'>
        <Text color='gray.500' fontSize='lg'>
            No data found.
        </Text>
        </Box>
    );
};
