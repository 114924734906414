// AddApiModal.js
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button
} from '@chakra-ui/react';

export const AddApiModal = ({ isOpen, onClose, onSave }) => {
    const [newApiDetails, setNewApiDetails] = useState({
        name: '',
        description: '',
        method: 'GET',
        sampleResponse: '',
        samplePayload: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewApiDetails({ ...newApiDetails, [name]: value });
    };

    const handleSave = () => {
        onSave(newApiDetails);
        setNewApiDetails({ name: '', description: '', method: 'GET', sampleResponse: '', samplePayload: '' });
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add New API</ModalHeader>
            <ModalBody>
            <FormControl mb={3}>
                <FormLabel>API Name</FormLabel>
                <Input name="name" value={newApiDetails.name} onChange={handleInputChange} />
            </FormControl>
            <FormControl mb={3}>
                <FormLabel>Description</FormLabel>
                <Input name="description" value={newApiDetails.description} onChange={handleInputChange} />
            </FormControl>
            <FormControl mb={3}>
                <FormLabel>Method</FormLabel>
                <Select name="method" value={newApiDetails.method} onChange={handleInputChange}>
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
                </Select>
            </FormControl>
            <FormControl mb={3}>
                <FormLabel>Sample Response</FormLabel>
                <Textarea name="sampleResponse" value={newApiDetails.sampleResponse} onChange={handleInputChange} />
            </FormControl>
            <FormControl mb={3}>
                <FormLabel>Sample Payload</FormLabel>
                <Textarea name="samplePayload" value={newApiDetails.samplePayload} onChange={handleInputChange} />
            </FormControl>
            </ModalBody>
            <ModalFooter>
            <Button colorScheme="gray" onClick={handleSave}>Add</Button>
            <Button ml={3} onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    );
};
