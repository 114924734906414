import React, { useState } from 'react';
import { Box, Heading, SimpleGrid, Text, Flex, Badge, Button, useDisclosure, Modal, Image, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import icons from '../utils/icons';

const dataSources = [
    { name: "Jira", count: 2053, status: "Enabled", progress: "Synced" },
    { name: "Google Drive", count: 11000, status: "Custom", progress: "Synced" },
    { name: "GitHub", count: 5020, status: "Enabled", progress: "Synced" },
    { name: "Slack", count: 5020, status: "Enabled", progress: "Synced" },
];

const IntegrationItem = ({ name, count, status, progress }) => (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg="white">
        <Flex alignItems="center" gap="4">
            <Box boxSize="40px" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icons[name]}</Box>
            <Heading fontSize="xl" noOfLines={1} isTruncated>{name}</Heading>
        </Flex>
        <Text mt={4}>{count} documents</Text>
        <Badge colorScheme={status === "Enabled" ? "green" : "blue"}>{status}</Badge>
        <Flex alignItems="center" gap="1" mt={4}>
            <Box boxSize="22px" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icons.Completed}</Box>
            <Text>{progress}</Text>
        </Flex>
        
    </Box>
);

const Integrations = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newDataSource, setNewDataSource] = useState({ name: "", count: 0, status: "", progress: "" });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDataSource(prev => ({ ...prev, [name]: value }));
    };

    const addDataSource = () => {
        dataSources.push({...newDataSource, count: parseInt(newDataSource.count)});
        onClose(); // Close modal after adding
    };

    return (
        <Box p={5}>
            <Button colorScheme="blue" mb={4} onClick={onOpen}>Add data source</Button>
            <SimpleGrid columns={2} spacing={10}>
                {dataSources.map((source, index) => (
                    <IntegrationItem key={index} {...source} />
                ))}
            </SimpleGrid>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add a new data source</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input name="name" onChange={handleInputChange} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Document Count</FormLabel>
                            <Input name="count" type="number" onChange={handleInputChange} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Status</FormLabel>
                            <Select name="status" onChange={handleInputChange}>
                                <option value="Enabled">Enabled</option>
                                <option value="Custom">Custom</option>
                                <option value="Disabled">Disabled</option>
                            </Select>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Progress</FormLabel>
                            <Input name="progress" onChange={handleInputChange} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={addDataSource}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Integrations;
