import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { defaultScreenshot } from "../../utils/screenshot";
import { baseDevasUrl, baseDevasWebsocketUrl } from "../../utils/constants"
import Mermaid from "../../mermaid_dgm.js"; 
import mermaid from 'mermaid';
import { saveAs } from 'file-saver';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';



import {
    Box,
    Button,
    Input,
    Text,
    VStack,
    Flex,
    Heading,
    Divider,
    keyframes,
    useColorModeValue,
    useColorMode,
    IconButton,
    Spacer,
    Image,
    ListItem,
    ListIcon,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import ChatComponent from "../common/ChatComponent";

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.7);
  }
`;


function SequenceDiagram(props) {
    let { projectId } = useParams();
    const location = useLocation();
    const { gitUrl, quipLinks } = location.state || { gitUrl: '', quipLinks: [] };
    const { brdData } = location.state || { brdData: '' };
    const { toggleColorMode, setColorMode, colorMode } = useColorMode();
    let brdSentOnce = false;
    const [socket, setSocket] = useState(null);
    const [userMessage, setUserMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [markdownContent, setMarkdownContent] = useState(""); // State to store Markdown content
    const [mermaidContent, setMermaidContent] = useState(""); // State to store Markdown content
    
    const [terminalHistory, setTerminalHistory] = useState([]);
    const [mermaidHistory,setMermaidHistory] = useState([]); // State to store mermaid content
    const [currentCommand, setCurrentCommand] = useState('');
    const [url, setUrl] = useState(null);
    const [screenshot, setScreenshot] = useState(defaultScreenshot);
    const [pastThreads, setPastThreads] = useState([]); // State to store past threads fetched from API
    const [chatConfig, setchatConfig] = useState('');


    const bgColor = useColorModeValue("gray.50", "gray.800");
    const cardBgColor = useColorModeValue("white", "gray.700");
    const planMessageBgColor = useColorModeValue('blue.100', 'blue.900');
    const userMessageBgColor = useColorModeValue('green.100', 'green.800');
    const assistantMessageBgColor = useColorModeValue('blue.100', 'blue.800');
    const mermaidBgColor = useColorModeValue("white", "gray.100");
    const inputBgColor = useColorModeValue("white", "gray.600");
    const svgContainerRef = useRef(null); // Ref for the hidden div
    const [exportFormat, setExportFormat] = useState('svg');
    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level (percent)
    const [threadId,setThreadId] = useState(null);

    const handleZoomIn = () => {
        setZoomLevel((prevZoomLevel) => prevZoomLevel * 1.1); // Increase zoom by 10%
    };

    const handleZoomOut = () => {
        setZoomLevel((prevZoomLevel) => prevZoomLevel / 1.1); // Decrease zoom by 10%
    };

    const openNewURL = (thread_id) => {
        window.open(`https://diagramcreator.fexz0.de/${thread_id}`, '_blank');
    };
    const handleThreadIdChange = (newThreadId) => {
        console.log("thread_id",newThreadId)
        setThreadId(newThreadId);
    };


    const handleFormatChange = (event) => {
        setExportFormat(event.target.value);
    };
    const handleUndo = () => {
        if (undoHistory.length > 0) {
            const previousContent = undoHistory.pop();
            setRedoHistory([...redoHistory, mermaidContent]);
            setMermaidContent(previousContent);
            setUndoHistory([...undoHistory]);
        }
    };

    const handleRedo = () => {
        if (redoHistory.length > 0) {
            const nextContent = redoHistory.pop();
            setUndoHistory([...undoHistory, mermaidContent]);
            setMermaidContent(nextContent);
            setRedoHistory([...redoHistory]);
        }
    };

    
    

    const getThreadsApi = `${baseDevasUrl}projects/${projectId}/threads`;
    const handleExport = async (format) => {
        const { svg } = await mermaid.render('mermaid-diagram-' + new Date().getTime(), mermaidContent);
        const svgContainer = svgContainerRef.current;
        if (svgContainer) {
            svgContainer.innerHTML = svg;
            const svgElement = svgContainer.querySelector('svg');
            if (svgElement) {
                if (exportFormat === 'svg') {
                    const svgData = new Blob([svgElement.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
                    saveAs(svgData, 'diagram.svg');
                } else if (exportFormat === 'png') {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const img = document.createElement('img');
                    // const img = new Image();
                    img.onload = () => {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);
                        canvas.toBlob((blob) => {
                            saveAs(blob, 'diagram.png');
                        });
                    };
                    img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgElement.outerHTML);
                }
            }
        }
    };
    
    

    let tempchatConfig = {
        communicationURL: brdData.length > 0 ? `${baseDevasWebsocketUrl}devas/chat/mermaid_builder/${projectId}` : '',
        communicationMethod: 'websocket',
        getCommunicationURL: (thread_id) => `${baseDevasWebsocketUrl}devas/chat/mermaid_builder/${thread_id}`,
        isCrateNewSocket: false,
        socket: socket,
        colors: {
            background: {
                light: "gray.50",
                dark: "gray.800"
            },
            userMessageBackground: {
                light: "green.100",
                dark: "green.800"
            },
            assistantMessageBackground: {
                light: "blue.100",
                dark: "blue.800"
            },
            planMessageBackground: {
                light: "blue.100",
                dark: "blue.900"
            },
            cardBackground: {
                light: "white",
                dark: "gray.700"
            }
        }
    };
    

    const fetchPastThreads = async () => {
        try {
            const response = await fetch(getThreadsApi);
            if (!response.ok) {
                throw new Error('Failed to fetch past threads');
            }
            const response_data = await response.json();
            if (response_data.data.length > 0){
                response_data.data.sort((x, y) => y.id - x.id);
                setPastThreads(response_data.data);
                loadThreadMessages(response_data.data[0].id);
                tempchatConfig.communicationURL = `${baseDevasWebsocketUrl}devas/chat/mermaid_builder/${response_data.data[0].id}`
                setchatConfig(tempchatConfig);

            }
            

        } catch (error) {
            console.error('Error fetching past threads:', error);
        }
    };

    const loadThreadMessages = (thread_id) => {
        setThreadId(thread_id)
        // setTerminalHistory([]);
        // setMermaidHistory([])
        setMermaidContent("")
        setChatMessages([]);
        fetch(`${baseDevasUrl}threads/${thread_id}/messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                // conso
                // console.log(response);
                // console.log(response.data);
                if (response.success && response.data.length > 0) {
                    const messages = response.data
                    // console.log('messages >>', messages);
                    for (const message of messages) {
                        // console.log("message >>", message);
                        // console.log("chatMessages >>", chatMessages);
                        if (message.is_json) {
                            const data = JSON.parse(message.content);
                            console.log("all data=",data)
                            if (data.window === 'terminal') {
                                updateTerminal(data.message);
                            }
                            else if(data.window === 'mermaid'){
                                console.log("mermaid_data",data.message)
                                setMermaidContent(data.message)
                            }
                            else if (data.window === 'browser') {
                                updateBrowser(data);
                            } else if (data.type === 'plan') {
                                receiveMessage(data.message, 'plan');
                            } else {
                                receiveMessage(data.message, 'text');
                            }
                        } else {
                            setChatMessages((previousChat) => [...previousChat, message]);
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    const createChatThread = async (name) => {
        setChatMessages([]);
        const response = await fetch(`${baseDevasUrl}threads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    "name": name,
                    "requested_by": localStorage.getItem("email"),
                    "alternate_id":`${projectId}`
            }),
        });

        if (response.ok) {
            const response_data= await response.json()
            await fetchPastThreads();
            const thread_id = response_data.data.thread_id;
            return thread_id;
        }
    };


    useEffect(() => {
        setColorMode("dark");
        setchatConfig(tempchatConfig);
        // createProject();
        fetchPastThreads();
        setThreadId(threadId)
    }, []);


    // browser
    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };

    const updateBrowser = (data) => {
        if (data.type === "link") {
            setUrl(data.message.link);
        } else if (data.type === "image") {
            setScreenshot(data.message.image);
        }
    }

    const receiveMessage = (message, type) => {
        if (type === 'plan') {
            const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'plan' }]);
        } else {
            setChatMessages((prev) => [...prev, { role: "assistant", content: message, type: 'text' }]);
        }
    };

    const updateTerminal = (data) => {
        setTerminalHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const lastEntry = prevHistory[prevHistory.length - 1];
                const isSameCommand = lastEntry.command === data.command;

                if (isSameCommand) {
                    // Append new output to the latest command output
                    const updatedHistory = [...prevHistory];
                    updatedHistory[updatedHistory.length - 1].output += "\n" + data.output; // Append new output
                    return updatedHistory;
                }
            }
            // New command or not recent, add to history
            return [
                ...prevHistory,
                { command: data.command, output: data.output }
            ];
        });
        setCurrentCommand('');
    };
    const updateMermaid = (data) => {
        setMermaidHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const lastEntry = prevHistory[prevHistory.length - 1];
                const isSameCommand = lastEntry.command === data.command;

                if (isSameCommand) {
                    // Append new output to the latest command output
                    const updatedHistory = [...prevHistory];
                    updatedHistory[updatedHistory.length - 1].output += "\n" + data.output; // Append new output
                    return updatedHistory;
                }
            }
            // New command or not recent, add to history
            return [
                ...prevHistory,
                { command: data.command, output: data.output }
            ];
        });
        // setCurrentCommand('');
    };

    // Define these functions in a separate file or at the top of your current file
    const inputParser = (input) => {
        return input.trim()
    };

    const outputParser = (output_data) => {
        // Example: Add a prefix to the output message
        const data = JSON.parse(output_data);
        if (data.window === 'mermaid'){
            // setMermaidContent("flowchart TD\n    A[Start] --> B[Choose vegetables]\n    B --> C[Wash vegetables]\n    C --> D[Cut vegetables]\n    D --> E[Cook vegetables]\n    E --> F[Season vegetables]\n    F --> G[Vegetables ready]\n    G --> H[End]")
            setMermaidContent(data.message)
        }
        else if (data.window === 'terminal') {
            updateTerminal(data.message);
        } else if (data.window === 'browser') {
            updateBrowser(data);
        } else if (data.type === 'plan') {
            const steps = Object.entries(data.message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            return { role: "assistant", content: steps, type: 'plan' }
        } else {
            return { role: "assistant", content: data.message, type: 'text' }
        }
    };

    const inputmessageParser = (messageData) => {
        return { role: "user", content: messageData };
    };

    const renderMessages = (chatMessages) => {
        return chatMessages.map((msg, index) => (
            msg.type === 'plan' ? (
                <Box key={index} bg={planMessageBgColor} p={4} borderRadius="md" alignSelf="flex-start">
                    {msg.content.split("\n").map((line, idx) => (
                        <Text key={idx} fontSize="md" mb={2}>{line}</Text>
                    ))}
                </Box>
            ) : (
                <Text key={index} bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor} p={3} borderRadius="md" alignSelf={msg.role === "user" ? "flex-end" : "flex-start"} maxWidth="80%" > {msg.content}</Text>
            )
        ));
    };

    return (
        <Flex h="100vh" p={4} bg={bgColor}>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} mr={4} maxWidth="50%">
                <ChatComponent
                    chatConfig={chatConfig}
                    inputParser={inputParser}
                    outputParser={outputParser}
                    inputmessageParser={inputmessageParser}
                    chatMessages={chatMessages}
                    renderMessages={renderMessages}
                    setChatMessages={setChatMessages}
                    loadThreadMessages={(thread_id) => loadThreadMessages(thread_id)}
                    createChatThread={(name) => createChatThread(name)}
                    pastThreads={pastThreads}
                    setPastThreads={setPastThreads}
                />
            </Flex>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={mermaidBgColor} maxW="1200px" width="100%"  style = {{"overflow":"auto"}} >
                <Flex justifyContent="space-between" alignItems="center" mb={4} style = {{"top":"30px"}}>
                    <Heading fontSize="xl" color="black">Mermaid Diagram Viewer</Heading>
                    <Spacer />
                    <IconButton mr = "4px" icon={<AddIcon />} colorScheme="blue" onClick={handleZoomIn} aria-label="Zoom in" />
                    <IconButton icon={<MinusIcon />} colorScheme="red" onClick={handleZoomOut} aria-label="Zoom out" />
                </Flex>
                <Flex flexGrow={1} width="100%" overflow="auto" mt="10%">
                    <div style={{ transform: `scale(${zoomLevel / 100})`, transformOrigin: '0 0' }}>
                        {mermaidContent ? <Mermaid text={mermaidContent} id_index="1" /> : ''}
                    </div>
                </Flex>
                {/* <Flex flexGrow={1} width="100%" overflow="auto" mt="10%">
                {
                    mermaidContent ? <Mermaid text={mermaidContent} id_index="1" /> : ''
                }
                </Flex > */}
                    
                <div ref={svgContainerRef} style={{ display: 'none' }}></div>
                <Flex style = {{"position":"absolute","bottom":"30px","right":"10px"}}> 
                <select value={exportFormat} onChange={handleFormatChange}>
                    <option value="svg">SVG</option>
                    <option value="png">PNG</option>
                </select>
                <Button onClick={handleExport} bg="brown">Export</Button>
                <Button onClick={() => threadId && openNewURL(threadId)} colorScheme="blue" ml="2" isDisabled={!threadId}>
                Open Diagram Creator
                </Button>
            </Flex>
            </Flex>
        </Flex>
    );
}

export default SequenceDiagram;
