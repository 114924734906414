// src/context/WorkspaceContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';

const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
    const [currentWorkspace, setCurrentWorkspace] = useState(() => {
        // Initialize state from localStorage if available
    const savedWorkspace = localStorage.getItem('currentWorkspace');
    return savedWorkspace ? JSON.parse(savedWorkspace) : null;
    });

    useEffect(() => {
    // Save currentWorkspace to localStorage whenever it changes
    if (currentWorkspace) {
        localStorage.setItem('currentWorkspace', JSON.stringify(currentWorkspace));
    } else {
        localStorage.removeItem('currentWorkspace');
    }
    }, [currentWorkspace]);

  return (
    <WorkspaceContext.Provider value={{ currentWorkspace, setCurrentWorkspace }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => useContext(WorkspaceContext);
