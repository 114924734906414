import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { defaultScreenshot } from "../../utils/screenshot";
import { baseDevasUrl, baseDevasWebsocketUrl } from "../../utils/constants"
import {
    Box,
    Button,
    Input,
    Text,
    VStack,
    Flex,
    Heading,
    Divider,
    keyframes,
    useColorModeValue,
    useColorMode,
    IconButton,
    Spacer,
    Image,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.7);
  }
`;

function DevasBrdPage(props) {
    const location = useLocation();
    const { gitUrl, quipLinks } = location.state || { gitUrl: '', quipLinks: [] };
    const { brdData } = location.state || { brdData: '' };
    const { toggleColorMode, setColorMode, colorMode } = useColorMode();
    let brdSentOnce = false;
    const [socket, setSocket] = useState(null);
    const [userMessage, setUserMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [terminalHistory, setTerminalHistory] = useState([]);
    const [currentCommand, setCurrentCommand] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const initialColorModeSet = useRef(false);
    const [url, setUrl] = useState(null);
    const [screenshot, setScreenshot] = useState(defaultScreenshot);

    const getMessages = () => {
        fetch(`${baseDevasUrl}threads/1/messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                // console.log(response.data);
                if (response.success && response.data.length > 0) {
                    const messages = response.data
                    console.log('messages >>', messages);
                    for (const message of messages) {
                        console.log("message >>", message);
                        console.log("chatMessages >>", chatMessages);
                        if (message.is_json) {
                            const data = JSON.parse(message.content);
                            setIsTyping(false);
                            if (data.window === 'terminal') {
                                updateTerminal(data.message);
                            } else if (data.window === 'browser') {
                                updateBrowser(data);
                            } else if (data.type === 'plan') {
                                receiveMessage(data.message, 'plan');
                            } else {
                                receiveMessage(data.message, 'text');
                            }
                        } else {
                            setChatMessages((previousChat) => [...previousChat, message]);
                        }
                    }
                }
                // if (response.success && Object.keys(response.data).length > 0) {
                //     const messages = response.data.messages
                //     console.log('messages >>', messages);
                //     for (const message of messages) {
                //         console.log("message >>", message);
                //         if (message.is_json) {
                //             const data = JSON.parse(message.content);
                //             setIsTyping(false);
                //             if (data.window === 'terminal') {
                //                 updateTerminal(data.message);
                //             } else if (data.window === 'browser') {
                //                 updateBrowser(data);
                //             } else if (data.type === 'plan') {
                //                 receiveMessage(data.message, 'plan');
                //             } else {
                //                 receiveMessage(data.message, 'text');
                //             }
                //         } else {
                //             setChatMessages((prev) => [...prev, message]);
                //         }
                //     }
                // }
                // Handle the data from the response here
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    const connectWebSocket = () => {
        let newSocket;
        if (brdData.length > 0) {
            newSocket = new WebSocket(`${baseDevasWebsocketUrl}devas/chat/brd_project`);
        } else {
            newSocket = new WebSocket(`${baseDevasWebsocketUrl}devas/chat/1`);
        }
        setSocket(newSocket);

        newSocket.onopen = () => {
            setIsConnected(true);
            if (brdData.length > 0 && !brdSentOnce) {
                if (newSocket) {
                    const name = location.state.name;
                    const orgId = 0;
                    const createdBy = localStorage.getItem("email");
                    newSocket.send(JSON.stringify({
                        name: name,
                        org_id: orgId,
                        created_by: createdBy,
                        tdd: brdData
                    }));
                    brdSentOnce = true;
                }
            }
        };

        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setIsTyping(true);
            setTimeout(() => {
                setIsTyping(false);
                if (data.window === 'terminal') {
                    updateTerminal(data.message);
                } else if (data.window === 'browser') {
                    updateBrowser(data);
                } else if (data.type === 'plan') {
                    receiveMessage(data.message, 'plan');
                } else {
                    receiveMessage(data.message, 'text');
                }
            }, 2000); // Adjust delay as needed
        };

        newSocket.onerror = () => {
            setIsConnected(false);
        };

        newSocket.onclose = () => {
            setIsConnected(false);
            setTimeout(connectWebSocket, 1000);
        };
    };

    const createProject = async () => {
        const name = location.state.name;
        const orgId = 0;
        const createdBy = localStorage.getItem("email");

        const response = await fetch(`${baseDevasUrl}`)
    }

    useEffect(() => {
        setColorMode("dark");
        // createProject();
        // getMessages();
        connectWebSocket();


        
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    // browser
    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };


    const updateBrowser = (data) => {
        if (data.type === "link") {
            setUrl(data.message.link);
        } else if (data.type === "image") {
            setScreenshot(data.message.image);
        }
    }


    const sendMessage = () => {
        if (socket && socket.readyState === WebSocket.OPEN && userMessage.trim()) {
            const message = { role: "user", content: userMessage };
            socket.send(userMessage);
            setChatMessages((prev) => [...prev, message]);
            setUserMessage("");
            setIsTyping(true);
        }
    };

    const receiveMessage = (message, type) => {
        if (type === 'plan') {
            const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'plan' }]);
        } else {
            setChatMessages((prev) => [...prev, { role: "assistant", content: message, type: 'text' }]);
        }
    };

    const updateTerminal = (data) => {
        setTerminalHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const lastEntry = prevHistory[prevHistory.length - 1];
                const isSameCommand = lastEntry.command === data.command;
    
                if (isSameCommand) {
                    // Append new output to the latest command output
                    const updatedHistory = [...prevHistory];
                    updatedHistory[updatedHistory.length - 1].output += "\n" + data.output; // Append new output
                    return updatedHistory;
                }
            }
            // New command or not recent, add to history
            return [
                ...prevHistory,
                { command: data.command, output: data.output }
            ];
        });
        setCurrentCommand('');
    };

    // Save the previous color mode





    // const [prevColorMode, setPrevColorMode] = useState(colorMode);

    const bgColor = useColorModeValue("gray.50", "gray.800");
    const cardBgColor = useColorModeValue("white", "gray.700");
    const inputBgColor = useColorModeValue("white", "gray.600");
    const userMessageBgColor = useColorModeValue("green.100", "green.800");
    const assistantMessageBgColor = useColorModeValue("blue.100", "blue.800");
    const planMessageBgColor = useColorModeValue("blue.100", "blue.900");


    return (
        <Flex h="100vh" p={4} bg={bgColor}>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} mr={4}>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Heading fontSize="xl">Chat Window</Heading>
                    <Spacer />
                    <Flex alignItems="center">
                        <Box
                            size="12px"
                            bg={isConnected ? "green.500" : "red.500"}
                            borderRadius="full"
                            w={4}
                            h={4}
                            animation={isConnected ? `${pulse} 1.5s infinite` : "none"}
                            mr={2}
                        />
                        <Text>{isConnected ? "Active" : "Inactive"}</Text>
                    </Flex>
                    <IconButton
                        ml={4}
                        onClick={toggleColorMode}
                        icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
                        aria-label="Toggle color mode"
                    />
                </Flex>
                <Divider my={4} />
                <VStack
                    spacing={4}
                    overflowY="auto"
                    h="calc(100vh - 200px)"
                    p={3}
                    bg={bgColor}
                    borderRadius="lg"
                >

                    {chatMessages.map((msg, index) => (
                        msg.type === 'plan' ? (
                            <Box key={index} bg={planMessageBgColor} p={4} borderRadius="md" alignSelf="flex-start">
                                {msg.content.split("\n").map((line, idx) => (
                                    <Text key={idx} fontSize="md" mb={2}>{line}</Text>
                                ))}
                            </Box>
                        ) : (
                            <Text key={index} bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor} p={3} borderRadius="md" alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}>{msg.content}</Text>
                        )
                    ))}
                    {isTyping && (
                        <Box bg={assistantMessageBgColor} p={3} borderRadius="md" alignSelf="flex-start" display="flex">
                            <Box display="flex">
                                {Array.from({ length: 3 }).map((_, idx) => (
                                    <Box key={idx}
                                        as="span"
                                        mx="1px"
                                        height="10px"
                                        width="10px"
                                        borderRadius="full"
                                        bg="blue.500"
                                        animation={`${bounce} 1.4s ease-in-out infinite`}
                                        animationDelay={`${idx * 0.2}s`}
                                    ></Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                </VStack>
                <Flex mt={4}>
                    <Input
                        placeholder="Type your message here..."
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        flex="1"
                        isDisabled={isTyping}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '8px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: '0 2px 6px rgba(0,0,0,0.1)'
                        }}
                    />
                    <IconButton
                        icon={<ArrowForwardIcon />}
                        colorScheme="teal" // Use a theme color that suits your app's color scheme
                        onClick={sendMessage}
                        ml={2}
                        isLoading={isTyping}
                        isDisabled={isTyping}
                        rounded="full"
                        size="md"
                        _hover={{
                            bg: 'teal.600', // Darken the background on hover
                            transform: 'scale(1.1)', // Lift the button slightly on hover
                            shadow: 'md' // Increase shadow to give depth
                        }}
                        _active={{
                            bg: 'teal.700', // Further darken on click
                            transform: 'scale(1)', // Depress the button slightly on click
                            shadow: 'sm' // Decrease shadow to simulate pressing
                        }}
                        shadow="sm" // Regular state shadow
                        aria-label="Send Message"
                    />
                </Flex>
            </Flex>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor}>
                <Box mb="4" h="50%">
                    <Heading fontSize="xl">Terminal</Heading>
                    <Divider my="4" />
                    <Box className="terminal" h="calc(100% - 60px)" p={2} overflowX="auto">
                        {terminalHistory.map((cmd, index) => (
                            <Box key={index} className="terminal-command">
                                <Text className="command-prompt">devas@MacBook-Pro-210 % <Text className="terminal-input">{cmd.command}</Text></Text>
                                <Text className="terminal-output" size={10}><pre style={{ fontSize: '13px' }}>{cmd.output}</pre></Text>
                            </Box>
                        ))}
                        <Box className="terminal-command">
                            <Text className="command-prompt">devas@MacBook-Pro-210 % <Text className="terminal-input">{currentCommand}</Text><Text className="blinking-cursor"></Text></Text>
                        </Box>
                    </Box>
                </Box>
                <Box h="50%">
                    <Heading fontSize="xl">Browser Window</Heading>
                    <Divider my="4" />
                    <Box className="browser" h="calc(100% - 60px)" p={2}>
                        <input
                            type="text"
                            value={url}
                            disabled={true}
                            onChange={handleInputChange}
                            placeholder="devas://newtab"
                            style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '8px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                boxShadow: '0 2px 6px rgba(0,0,0,0.1)'
                            }}
                        />
                        {/* Scrollable container specifically for the image */}
                        <Box overflowY="auto" maxH="85%">
                            <Image
                                className="browser-img"
                                src={screenshot}
                                alt="Browser snapshot"
                                w="100%"
                                objectFit="contain"
                                loading="lazy"
                            />
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default DevasBrdPage;
