const globalConfig = window._conf ? window._conf : {};


const environment = {
    ...globalConfig,
};


export const baseCerebrumUrl = environment.REACT_APP_BASE_CEREBRUM_URL || "https://api.fexz0.de/service/public/v1.0/"
export const baseDevasUrl = environment.REACT_APP_BASE_DEVAS_URL || "https://api.fexz0.de/service/devas/v1.0/"
export const baseDevasWebsocketUrl = environment.REACT_APP_BASE_DEVAS_WEBSOCKET_URL || "wss://api.fexz0.de/service/devas/websocket/v1.0/"
export const baseReviewPilotUrl = environment.REACT_APP_BASE_REVIEW_PILOT_URL || "https://api.fexz0.de/review_pilot/v1.0/"
export const baseNejiUrl = environment.REACT_APP_BASE_NEJI_URL || "https://api.fexz0.de/service/panel/users/v1.0/"
export const baseNejiWebhookUrl = environment.REACT_APP_BASE_NEJI_WEBHOOK_URL || "https://api.fexz0.de/service/webhook/users/v1.0/"
export const baseHiruzenPlatformUrl = environment.REACT_APP_BASE_HIRUZEN_PLATFORM_URL || "https://api.fexz0.de/service/platform/organization/v1.0/"
export const baseHiruzenAdminUrl = environment.REACT_APP_BASE_HIRUZEN_ADMIN_URL || "https://api.fexz0.de/service/___/administrator/organization/v1.0/"
export const baseHiruzenInternalUrl = environment.REACT_APP_BASE_HIRUZEN_INTERNAL_URL || "https://api.fexz0.de/___/internal/organization/v1.0/"

export const roles = [
    {name: "Member", slug: 'member'},
    {name: "Org Owner", slug: 'org-owner'},
    {name: "Engg Manager", slug: 'engg-manager'}
];

export const roleHierarchy = {
    'org-owner': ['member', 'engg-manager', 'org-owner'],
    'engg-manager': ['member', 'engg-manager'],
    'member': ['member']
};

// Development Env

// export const baseCerebrumUrl = "http://localhost:8085/v1.0/";
// export const baseDevasUrl = "http://localhost:8081/v1.0/";
// export const baseDevasWebsocketUrl = "ws://localhost:8082/v1.0/";
// export const baseReviewPilotUrl = "http://localhost:8003/v1.0/";
// export const baseNejiUrl = "http://localhost:8085/v1.0/";
