import React, { useState, useEffect } from 'react';
import {
  Menu, MenuButton, MenuList, MenuItem, Button, Avatar, Box, Divider, Text, useToast, VStack
} from '@chakra-ui/react';
import { FaSignOutAlt, FaCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { baseNejiUrl } from '../../utils/constants';
import { useWorkspace } from '../../utils/workspaceContext';
import { getSessionDetails } from '../../utils/utilities';

// const CORS_ANYWHERE = 'https://cors-anywhere.herokuapp.com/';

const getInitialsFromEmail = (email) => {
  if (!email) return 'U';
  const parts = email.split('@')[0].split('.');
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase();
  } else {
    return (parts[0].charAt(0) + parts[1].charAt(0)).toUpperCase();
  }
};

const ProfileMenu = ({ hideWorkspace = false, hideConfig = false, onWorkSpaceChange }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const userEmail = localStorage.getItem("email");
  const userInitials = getInitialsFromEmail(userEmail);
  // const sessionData = JSON.parse(atob(localStorage.getItem("token")));
  const { currentWorkspace, setCurrentWorkspace } = useWorkspace();

  // const [currentWorkspace, setCurrentWorkspace] = useState(sessionData?.session?.passport?.user?.orgId);
  const [workspaces, setWorkSpaces] = useState(getSessionDetails().session?.passport?.user?.workspace);

  useEffect(() => {
    const selectFirstWorkspace = async (workspace) => {
      if (!workspace) return;
      const firstWorkspace = workspace;
      await handleWorkspaceSwitch(firstWorkspace.orgId, firstWorkspace.urmId);
    };
    selectFirstWorkspace(currentWorkspace);
  }, [workspaces]);

  
  const handleLogout = async () => {
    localStorage.removeItem("token");
    const endpoint = 'authentication/logout';
    const url = `${baseNejiUrl}${endpoint}`;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
    
    navigate("/");
  };

  const handleConfig = () => {
    navigate("/config");
  };

  const handleWorkspaceSwitch = async (workspaceId, urmId) => {
    const endpoint = `authentication/login/workspace/${urmId}`;
    const url = `${baseNejiUrl}${endpoint}`;
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });
      
      if (response.ok) {
        setCurrentWorkspace({orgId: workspaceId, urmId: urmId});
        // toast({
        //   title: "Workspace switched",
        //   description: `You have switched to workspace with ID ${workspaceId}`,
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true
        // });
        const resp = await response.json();
        localStorage.setItem('token', btoa(JSON.stringify(resp)));
        onWorkSpaceChange();
      } else {
        throw new Error('Failed to switch workspace');
      }
    } catch (error) {
      if (window.location.href.endsWith("dashboard")) {
        toast({
          title: "Error",
          description: "Failed to switch workspace",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
      
    }
  };

  useEffect(() => {
    
  }, [currentWorkspace]);

  return (
    <Menu>
      <MenuButton as={Button} rounded="full" variant="link" cursor="pointer">
        <Avatar name={userInitials} bg="purple.500" color="white" />
      </MenuButton>
      <MenuList border="1px" borderColor="gray.200" boxShadow="lg" p={2} rounded="md">
        {!hideWorkspace && (
          <>
            <Box mb={2} mt={2}>
              <Text fontWeight="bold" mb={2} ml={3}>Workspaces</Text>
              <VStack align="start" pl={6} spacing={2}>
                {workspaces && workspaces.length > 0 ? workspaces.map((workspace) => (
                  <MenuItem
                    key={workspace.orgId}
                    onClick={() => handleWorkspaceSwitch(workspace.orgId, workspace.urmId)}
                    bg={currentWorkspace.orgId === workspace.orgId ? "purple.100" : "white"}
                    color={currentWorkspace.orgId === workspace.orgId ? "purple.800" : "black"}
                    _hover={{ bg: "purple.200", color: "purple.900" }}
                    py={2}
                    px={4}
                    rounded="md"
                    w="full"
                    textDecoration={currentWorkspace.orgId === workspace.orgId ? "underline" : "none"}
                  >
                    {workspace.orgName}
                  </MenuItem>
                )) : (
                  <Text>No workspaces found</Text>
                )}
              </VStack>
            </Box>
            <Divider />
          </>
          )}
        {!hideConfig && (
        <>
          <Box mt={2}>
            <MenuItem 
              icon={<FaCog />} 
              _hover={{ bg: "purple.100", color: "purple.800" }} 
              onClick={handleConfig} 
              py={3}
            >
              Tokens
            </MenuItem>
          </Box>
          <Divider />
        </>
        )}
        
        <Box mt={2}>
          <MenuItem 
            icon={<FaSignOutAlt />} 
            _hover={{ bg: "purple.100", color: "purple.800" }} 
            onClick={handleLogout} 
            py={3}
          >
            Logout
          </MenuItem>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
