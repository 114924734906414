// src/components/ChatComponent.js
import React, { useState, useRef, useEffect } from 'react';
import { baseDevasUrl } from "../../utils/constants"
import {
    Box,
    Flex,
    Input,
    Text,
    IconButton,
    keyframes,
    VStack,
    Heading,
    Divider,
    useColorModeValue,
    useColorMode,
    Spacer,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    List,
    ListItem,
    ListIcon,
    Icon,
    Image,
    Textarea,
} from '@chakra-ui/react';
import WebSocketSingleton from './../../utils/websocket-singleton';
import { SunIcon, MoonIcon, ArrowForwardIcon, HamburgerIcon, AddIcon , AttachmentIcon } from "@chakra-ui/icons";

const wave = keyframes`
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.0);
  }
`;

const ChatComponent = ({ chatConfig,
    inputParser,
    outputParser,
    inputmessageParser,
    renderMessages,
    chatMessages,
    setChatMessages,
    loadThreadMessages,
    createChatThread,
    pastThreads,
    setPastThreads
}) => {
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [socket, setSocket] = useState(null);
    const [showPastThreads, setShowPastThreads] = useState(false); // State to manage showing past threads
    const { toggleColorMode, setColorMode } = useColorMode();
    const messagesEndRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage side panel visibility
    const [newThreadTitle, setnewThreadTitle] = useState('');
    const [createNewThread, setcreateNewThread] = useState(false);
    const [newThreadsendMessage, setNewThreadsendMessage] = useState('');
    const [textAreaHeight, setTextAreaHeight] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileContent, setFileContent] = useState('');

    
    const lineHeightEm = 1.5; // Adjust based on your design
    const maxLines = 4;
    const maxHeight = `${lineHeightEm * maxLines}em`; // 4 lines max
    const minHeight = `${lineHeightEm}em`; // 1 line high




    const LIGHTBGCOLOR = chatConfig?.colors?.background?.light || 'gray.50';
    const DARKBGCOLOR = chatConfig?.colors?.background?.dark || 'gray.800';

    const LIGHTUSERMESSAGEBGCOLOR = chatConfig?.colors?.userMessageBackground?.light || 'green.100';
    const DARKUSERMESSAGEBGCOLOR = chatConfig?.colors?.userMessageBackground?.dark || 'green.800';

    const LIGHTASSISTANTMESSAGEBGCOLOR = chatConfig?.colors?.assistantMessageBackground?.light || 'blue.100';
    const DARKASSISTANTMESSAGEBGCOLOR = chatConfig?.colors?.assistantMessageBackground?.dark || 'blue.800';

    const LIGHTPLANMESSAGEBGCOLOR = chatConfig?.colors?.planMessageBackground?.light || 'blue.100';
    const DARKPLANMESSAGEBGCOLOR = chatConfig?.colors?.planMessageBackground?.dark || 'blue.900';

    const LIGHTCARDBGCOLOR = chatConfig?.colors?.cardBackground?.light || 'white';
    const DARKCARDBGCOLOR = chatConfig?.colors?.cardBackground?.dark || 'gray.700';

    const bgColor = useColorModeValue(LIGHTBGCOLOR, DARKBGCOLOR);
    const userMessageBgColor = useColorModeValue(LIGHTUSERMESSAGEBGCOLOR, DARKUSERMESSAGEBGCOLOR);
    const assistantMessageBgColor = useColorModeValue(LIGHTASSISTANTMESSAGEBGCOLOR, DARKASSISTANTMESSAGEBGCOLOR);
    const planMessageBgColor = useColorModeValue(LIGHTPLANMESSAGEBGCOLOR, DARKPLANMESSAGEBGCOLOR);
    const cardBgColor = useColorModeValue(LIGHTCARDBGCOLOR, DARKCARDBGCOLOR);
    const websocketManager = new WebSocketSingleton()

    let eventSource = null;
    const communicationMethod = chatConfig.communicationMethod;

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        setColorMode("dark");
        if (pastThreads.length == 0) {
            setcreateNewThread(true);
        }
        else {
            setcreateNewThread(false);
        }
        cleanupConnection();
        if (chatConfig.communicationURL == undefined || chatConfig.communicationURL == "") {
            setIsConnected(true); // for dummy 
            return;
        }
        if (communicationMethod === 'websocket') {
            connectWebSocket(chatConfig.communicationURL);
        } else if (communicationMethod === 'sse') {
            eventSource = new EventSource(chatConfig.communicationURL);
            eventSource.onmessage = handleMessage;
            eventSource.onerror = (error) => {
                console.error('SSE error:', error);
            };
        }

    }, [chatConfig.communicationURL]);

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages, isLoading]);

    const handleFileChange = async (e) => {
        setFileContent('');
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
            console.log("Selected file:", file.name);
    
            const formData = new FormData();
            formData.append('file', file); // Assuming the server expects a 'file' key
    
            try {
                const response = await fetch(`${baseDevasUrl}devas/util/pdf_to_text`, {
                    method: 'POST',
                    body: formData,
                });
    
                if (response.ok) {
                    const data = await response.json(); // Parse the JSON response
                    console.log("data",data)
                    console.log("PDF text content:", data.text); // Assuming the text is returned under the 'text' key
                    setFileContent(data.text);
                    // Now you can do something with the extracted text, like displaying it in the UI
                } else {
                    console.error("Failed to convert PDF. Status: ", response.status);
                }
            } catch (error) {
                console.error("Error during API call:", error);
            }
        }
    };
    
    

    const triggerFileInput = () => {
        console.log("Trigger File Input")
        document.getElementById('fileInput').click();
    };


    const connectWebSocket = (communicationURL) => {
        const webSocketInstance = websocketManager.openWebSocket(communicationURL);
        setSocket(webSocketInstance);
        

        webSocketInstance.connection.onopen = () => {
            setIsConnected(true);
        };

        webSocketInstance.connection.onmessage = handleMessage;

        webSocketInstance.connection.onerror = (error) => {
            console.error('WebSocket error:', error);
            setIsConnected(false);
        };

        webSocketInstance.connection.onclose = () => {
            console.log('WebSocket connection closed');
            setIsConnected(false);
            setTimeout(connectWebSocket(communicationURL), 1000);
        };
    };

    useEffect(()=>{
        console.log(isConnected);
        if (isConnected && socket && socket.connection.readyState== WebSocket.OPEN && newThreadsendMessage != ""){
            socket.sendMessage(inputParser(newThreadsendMessage));
            setNewThreadsendMessage("")
        }
    }, [isConnected]);

    const cleanupConnection = () => {
        if (communicationMethod === 'websocket') {
            if (socket && socket.connection) {
                socket.connection.onmessage = null;
                socket.connection.onerror = null;
                socket.connection.onclose = null;
                socket.closeWebSocket();
                // socket.connection.close();

                setIsConnected(false);
            }
        }
        if (eventSource) {
            eventSource.close();
            eventSource = null;
        }
    };


    // Function to toggle side panel visibility
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleInputChange = (e) => {
        setInput(e.target.value);

        // Reset height to auto to correctly reduce size when deleting content
        setTextAreaHeight('');
    };
    const handleInput = (e) => {
        const textArea = e.target;
        // Reset height to auto to correctly reduce size when deleting content
        textArea.style.height = '';
        // Calculate the number of lines (rounded up) by dividing the scrollHeight by the line height in pixels
        const lines = Math.ceil(textArea.scrollHeight / (lineHeightEm * parseFloat(getComputedStyle(textArea).fontSize)));
        // Calculate the new height in em units
        const newHeightEm = lines * lineHeightEm;
        // Apply the new height, respecting the min and max height constraints
        textArea.style.height = `${Math.min(Math.max(newHeightEm, parseFloat(minHeight)), parseFloat(maxHeight))}em`;
    };
    
    
    // Function to handle clicking on a past thread
    const handlePastThreadClick = (thread_id) => {
        // Trigger function passed as prop to load past thread
        
        const newCommunicationURL = chatConfig?.getCommunicationURL(thread_id)
        if (newCommunicationURL !== chatConfig.communicationURL){
            setChatMessages([]);
            cleanupConnection();
            connectWebSocket(newCommunicationURL)
            loadThreadMessages(thread_id);
        }
        
        setIsMenuOpen(false); // Close side panel after clicking a thread
    };

    const renderThreadList = (pastThreads) => {
        return pastThreads.map((thread, index) => (
            <ListItem key={index} cursor="pointer" onClick={() => handlePastThreadClick(thread.id)}>
                <ListIcon as={ArrowForwardIcon} color="teal.500" />
                {thread.title}
            </ListItem>
        ));
    };

    const createChatThreadWrapper = async () => {

        if (chatMessages.length < 1) {
            return;
        }
        setChatMessages([]);
        setcreateNewThread(true)
        // const thread_id = await createChatThread(newThreadTitle);
        // const newCommunicationURL = chatConfig?.getCommunicationURL(thread_id)
        // cleanupConnection();
        // connectWebSocket(newCommunicationURL);
        // setnewThreadTitle('');

    }


    const handleSend = async (event) => {
        event.preventDefault();
        if (!input.trim()) {
            return;
        }

        setInput('');
        setSelectedFile(null);
        setTextAreaHeight('');
        document.querySelector('textarea').style.height = '';
        setIsLoading(true);
        console.log("input",input)
        
        if (createNewThread) {
            const thread_id = await createChatThread(input.substring(0, 5) + "...");
            setNewThreadsendMessage(input);
        }
        const newMessages = [...chatMessages, inputmessageParser(input)];
        let message = input + (fileContent ? "\nHere is Template file " + fileContent : "")
        setChatMessages(newMessages);
        if (communicationMethod === 'websocket'  && socket && socket.connection.readyState === WebSocket.OPEN && !createNewThread) {
            socket.sendMessage(inputParser(message));
        } else if (communicationMethod === 'sse') {
            const response = await sendSSEMessage(newMessages, 'openai-gpt4', true, 'ushankradadiya@gofynd.com');
            const contentType = response.headers.get('Content-Type');
            try {
                if (contentType && contentType.startsWith('text/event-stream')) {
                    if (response.body) {
                        let index = 0;
                        const resp = { data: "" };
                        for await (const chunk of response.body) {
                            setIsLoading(false);
                            index += 1;
                            const decoder = new TextDecoder('utf-8');
                            const str = decoder.decode(chunk);
                            const data = str.split("data: ");
                            data.forEach((element) => {
                                if (element) {
                                    const jsonData = JSON.parse(element);
                                    if (jsonData?.choices[0]['finish_reason'] !== "stop") {
                                        const text = jsonData.choices[0].delta.content;
                                        resp.data += text;
                                        setChatMessages([...newMessages, { content: resp.data, role: 'system', isUser: false }]);
                                    }
                                }
                            });
                        }
                    }
                } else {
                    const esp = await response.json();
                    console.log(response.data);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
        setcreateNewThread(false);
        

    };


    const handleMessage = (event) => {
        setIsLoading(true);
        setTimeout(() => {
            const output_data = event.data;
            const message = outputParser(output_data);
            if (message) {
                setChatMessages((prev) => [...prev, message]);
            }
            setIsLoading(false);
        }, 2000); // Adjust delay as needed
    };

    const sendSSEMessageDemo = async (message) => {
        try {
            const response = await fetch(chatConfig.communicationURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "action_id": 1,
                    "data": {
                        "messages": [
                            {
                                "role": "user",
                                "content": "Hi"
                            }
                        ]
                    },
                    "model": "openai-gpt4",
                    "stream": true
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to send message via SSE');
            }
        } catch (error) {
            console.error('Error sending SSE message:', error);
        }
    };

    const sendSSEMessage = async (messages, fexModel, stream = false, userEmail) => {
        const url = chatConfig.communicationURL;
        const myHeaders = { "Content-Type": "application/json" };
        const lastMessage = messages[messages.length - 1];

        const raw = JSON.stringify({
            "action_id": 1,
            "data": {
                "messages": messages
            },
            "model": fexModel,
            "stream": stream,
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        console.log("requestOptions >>", requestOptions);
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            console.log("response >>", response);
            return response;
        } catch (error) {
            console.error("Error:", error);
        }
    }


    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            borderRadius="lg"
            overflow="hidden"
            background="#fff"
            bg={cardBgColor}
        >
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
                {/* Hamburger menu icon */}
                <IconButton
                    icon={<HamburgerIcon />}
                    aria-label="Open Menu"
                    onClick={toggleMenu}
                // display={{ base: 'block', md: 'none' }} // Show only on small screens
                />
                {/* Conditionally render past threads in side panel */}
                <Drawer placement="left" onClose={() => setIsMenuOpen(false)} isOpen={isMenuOpen}>
                    <DrawerOverlay>
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>Past Threads</DrawerHeader>
                            <DrawerBody>
                                <List spacing={2}>
                                    {renderThreadList(pastThreads)}
                                </List>
                            </DrawerBody>
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>
                <Heading fontSize="xl">Chat Window</Heading>
                <Spacer />
                <Button bg={cardBgColor} onClick={createChatThreadWrapper}
                    isDisabled={chatMessages.length < 1}>
                    <AddIcon />
                </Button>
                <Flex alignItems="center">
                    <Box
                        size="12px"
                        bg={isConnected ? "green.500" : "red.500"}
                        borderRadius="full"
                        w={4}
                        h={4}
                        animation={isConnected ? `${pulse} 1.5s infinite` : "none"}
                        mr={2}
                    />
                    <Text>{isConnected ? "Active" : "Inactive"}</Text>
                </Flex>
                <IconButton
                    ml={4}
                    onClick={toggleColorMode}
                    icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
                    aria-label="Toggle color mode"
                />
            </Flex>
            <Divider my={4} />
            <VStack
                spacing={4}
                overflowY="auto"
                h="calc(100vh - 200px)"
                p={3}
                bg={bgColor}
                borderRadius="lg"
            >
               


                {/* Render current chat messages */}
                {renderMessages(chatMessages)}
                {isLoading && (
                    <Box  p={3} borderRadius="md" alignSelf="flex-start" display="flex">
                        <Box display="flex" alignItems="center" justifyContent="center">
                            {Array.from({ length: 4 }).map((_, idx) => (
                                <Box
                                key={idx}
                                as="span"
                                mx="4px"
                                height="10px"
                                width="10px"
                                borderRadius="full"
                                bg={
                                    idx === 0
                                    ? "red.500"
                                    : idx === 1
                                    ? "blue.500"
                                    : idx === 2
                                    ? "green.500"
                                    : idx === 3
                                    ? "yellow.500"
                                    : "orange.500"
                                }   
                                animation={`${wave} 1.3s ease-in-out infinite`}
                                style={{ animationDelay: `${idx * 0.1}s` }}
                                ></Box>
                        ))}
                        </Box>
                    </Box>
                )}
                <Box ref={messagesEndRef} />
                
            </VStack>

            <Flex mt={4}>
                
    <form onSubmit={handleSend} style={{ width: '100%', display: 'flex' }}>
        <Box position="relative" flex="1">
            <Textarea
                placeholder="Type your message here..."
                value={input}
                onChange={handleInputChange}
                onInput={handleInput}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' ){
                        if(!e.shiftKey ) {
                            e.preventDefault(); // Prevent form submission
                            document.querySelector('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })); // Programmatically submit the form
                        }
                        else if(e.shiftKey){
                            e.preventDefault(); // Prevent form submission
                            setInput(e.target.value + "\n");
                        }
                    }
                }}
                style={{
                    minHeight: minHeight,
                    height: textAreaHeight,
                    overflowY: 'auto',
                    width: '100%',
                    padding: '0.625rem 2.5rem 0.625rem 0.625rem', // Right padding added for icon space
                    borderRadius: '0.5rem',
                    border: '1px solid #ccc',
                    boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                    resize: 'none', // Prevent manual resizing
                }}
                isDisabled={isLoading}
            />
            <IconButton
                icon={<AttachmentIcon />}
                colorScheme="teal"
                position="absolute"
                right="0.5rem"
                top="0.25rem"
                size="sm"
                _hover={{
                    bg: 'teal.600',
                    transform: 'scale(1.1)',
                    shadow: 'md'
                }}
                _active={{
                    bg: 'teal.700',
                    transform: 'scale(1)',
                    shadow: 'sm'
                }}
                shadow="sm"
                aria-label="Upload PDF"
                isDisabled={isLoading}
                onClick={triggerFileInput}
            />
        </Box>
        <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept=".pdf"
                    onChange={handleFileChange}
                />
         {selectedFile && (
        <Text fontSize="md" p={3} bg="red.200" borderRadius="md">
            {selectedFile.name}
        </Text>
    )}
        <IconButton
            icon={<ArrowForwardIcon />}
            colorScheme="teal"
            ml={2}
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
            rounded="full"
            size="md"
            _hover={{
                bg: 'teal.600',
                transform: 'scale(1.1)',
                shadow: 'md'
            }}
            _active={{
                bg: 'teal.700',
                transform: 'scale(1)',
                shadow: 'sm'
            }}
            shadow="sm"
            aria-label="Send Message"
        />
    </form>
</Flex>

        </Box>
    );
};



// Defined default parser functions
const defaultInputParser = (input) => {
    return input.trim()
};
const defaultInputMessageParser = (messageData) => {
    return { role: "user", content: messageData };
};

// Assign default props
ChatComponent.defaultProps = {
  inputParser: defaultInputParser,
  inputmessageParser: defaultInputMessageParser,
};

export default ChatComponent;

