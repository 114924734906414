import React from 'react';
import { Box, Button, VStack, HStack, Text, Heading, Spinner, Center, useColorMode } from '@chakra-ui/react';
import { useWizard } from "react-use-wizard";
import { useFormContext } from "../utils/useFormContext";
import { useEffect, useState } from 'react';
import Mermaid from "../mermaid_dgm.js";
import { updateData } from '../utils/utilities.js';
import { NoDataFound } from './common/NoDataFound.js';
import { baseBRDBuilderUrl, baseDevasUrl, baseUrl } from '../utils/constants.js';

export function FlowDiagramList() {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const { previousStep, nextStep } = useWizard();
    const [allData, setAllData] = useState([]);
	const [processing, setProcessing] = useState(false);
	const { formData, diagramsData, setDiagramsData, workFlowData, currentStep, setCurrentStep } = useFormContext();

    const workflows = [
        {
            name: 'Add Item flow',
            description: 'Add item. Push to inventory. Use Add Item API.',
        },
        {
            name: 'Update Item flow',
            description: 'Logic for updating item details here.',
        },
        {
            name: 'Update Item flow',
            description: 'Logic for updating item details here.',
        },
        // ... add more workflows as needed
    ];

    const fetchDiagrams = async (regenerate = false) => {
		let docList = formData.documents.map(doc => ({ "value": doc.url }));
		if (!diagramsData || diagramsData.length == 0 || regenerate) {
			setProcessing(true);
			console.log("DocList", docList);
			try {
				const response = await fetch(`${baseDevasUrl}devas/chat/mermaid_diagram`, {
                // const response = await fetch('http://localhost:8081/v1.0/devas/chat/mermaid_diagram', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({workflows: workFlowData }), // Replace with your actual state or props
				});
				const data = await response.json();
				setAllData(data);
				setDiagramsData(data);
			} catch (error) {
				console.error('Failed to fetch APIs:', error);
			}
			setProcessing(false);
		} else {
			setAllData(diagramsData);
		}
		
	};

    useEffect(() => {
		console.log('Diagrams API Effect running');
		fetchDiagrams();
	}, []);


    const handleRegenerate = (event) => {
        event.preventDefault();
        fetchDiagrams(true);
    }

    const handleNextClick = (event) => {
        
        let step = currentStep;
        setCurrentStep(step + 1);
        updateData();
		nextStep();
    }

	const handlePreviousClick = (event) => {
		let step = currentStep;
        setCurrentStep(step - 1);
		previousStep();
	}

    return (
        <Box borderWidth='1px' borderRadius='lg' overflow='hidden' p={6} m={6}>
            {processing ? (
                <Center>
                <Spinner size='xl' />
                </Center>
            ) : allData.length === 0 ? (
                // Show the "No Data Found" message when there are no diagrams
                <NoDataFound forTable={false} />
            ) : (
                <>
                <Heading size='lg' mb={4}>
                    Flow Diagrams
                </Heading>
                <VStack spacing={4} align='stretch' maxHeight='100%' overflowY='auto'>
                    {allData.map((workflow, index) => (
                    <Box key={index} p={5} shadow='md' borderWidth='1px'>
                        <Text fontWeight='bold'>
                        {index + 1}. {workflow.name}
                        </Text>
                        <Mermaid text={workflow.diagram} id_index={index} />
                    </Box>
                    ))}
                </VStack>
                <HStack mt={10} justify='center'>
                    <Button onClick={handlePreviousClick}>Back</Button>
                    <Button onClick={handleRegenerate}>Regenerate Diagrams</Button>
                    <Button onClick={handleNextClick}>Next</Button>
                </HStack>
                </>
            )}
        </Box>
    );
}
