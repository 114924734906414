import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import HomePage from './home';
// import DevasPage from './devas';
import DevasPage from './comp/Devas/DevasUI';
import APIListPage from './apis_list_page';
import { ChakraProvider } from '@chakra-ui/react'
import Integrations from './comp/Integrations';
import { AuthPage } from "./comp/LoginSignup";
import { OAuthCallback, PrivateRoute, MicrosoftOauthCallback } from './utils/OAuth';
import { DataTable } from './comp/MyExtensions';
import { baseBRDBuilderUrl, baseCerebrumUrl, baseUrl, baseNejiUrl, baseNejiWebhookUrl } from './utils/constants';
import StartDevasPage from './comp/DevasInit';
import Dashboard from './comp/ReviewPilot/PrDashboard';
import PrRepositories from './comp/ReviewPilot/PrRepositories';
import ConfigurationPage from './comp/ReviewPilot/repoConfig';
import LandingPage from './comp/landingPage';
import DevasProject from './comp/Devas/DevasProjects';
import ConfigurePage from './comp/common/ConfigureTokens';
import VerificationSuccess from './utils/VerificationSuccess';
import EmailVerification from './utils/VerificationCallback';
import DevasBrdPage from './comp/Devas/DevasCreateBRDCode';
import SearchEngine from './comp/CustomRAG/SearchEngine';
import NoWorkspaceFound from './comp/common/NoWorkspace';
import OrganizationPage from './comp/common/OrgSelection';
import { WorkspaceProvider } from './utils/workspaceContext';
import ForgotPasswordForm from './comp/common/ForgotPassword';
import { RegistrationPage } from './comp/common/NewInvitedUserSignUp';
import DOCHubPage from './comp/Documents/DocUI';
import DocumentsProjects from './comp/Documents/DocumentsProjects'
import SequenceDiagramProjects from './comp/SequenceDiagram/SequenceDiagramProjects'
import SequenceDiagram from './comp/SequenceDiagram/SequenceDiagramUI'

// Import other pages here

function App() {
	// const navigate = useNavigate();

	const handleLogin = () => {
		const loginUrl = `${baseNejiWebhookUrl}authentication/google?redirect_uri=` + encodeURIComponent(window.location.origin + "/oauth-callback");
		window.location.href = loginUrl;
	};

	const handleMicrosoftLogin = () => {
		const loginUrl = baseNejiWebhookUrl + `authentication/login/microsoft?redirect_uri=${encodeURIComponent(window.location.origin + '/oauth-callback')}`;
		window.location.href = loginUrl;
	};

	const checkAuth = (Component) => {
        const token = localStorage.getItem('token');
        return token ? <Component /> : <Navigate to="/" />;
    };

	return (
		<ChakraProvider>
			<WorkspaceProvider>
			<Router>
				<Routes>
					<Route path="/" element={<AuthPage onLogin={handleLogin} onMicrosoftLogin={handleMicrosoftLogin} />} />
					<Route path="/config" element={<ConfigurePage />} />
          			<Route path="/oauth-callback" element={<OAuthCallback />} />
					<Route path="/auth/emailVerification" element={<EmailVerification />}/>
					<Route path="/verification-success" element={<VerificationSuccess />} />
					<Route path="/microsoft-oauth-callback" element={<MicrosoftOauthCallback />} />
					<Route path="/devas/:projectId" element={<DevasPage/>} />
					<Route path="/devas/coder" element={<DevasBrdPage/>} />
					<Route path="/home" element={<PrivateRoute component={HomePage} />} />
					<Route path="/start-devas" element={<PrivateRoute component={StartDevasPage} />} />
					<Route path="/api-list" element={<APIListPage />} />
					{/* Define other routes here */}
					<Route path="my-brds" element={<PrivateRoute component={DataTable} />}/>
					<Route path="temp" element={<PrivateRoute component={Integrations} />}/>
					<Route path="/configuration/:repoId" element={< ConfigurationPage/>} />
					<Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
					<Route path="/repositories" element={<PrivateRoute component={PrRepositories} />} />
					<Route path="/landing" element={<PrivateRoute component={LandingPage} />} />
					<Route path="/doccreator/:projectId" element={<PrivateRoute component={DOCHubPage} />} />
					<Route path="/devas/projects" element={<PrivateRoute component={DevasProject} />} />
					<Route path="/quipwhiz" element={<SearchEngine />} />
					<Route path="/auth/setPassword" element={<ForgotPasswordForm />} />
					<Route path="/no-workspace-found" element={<NoWorkspaceFound />} />
					<Route path="/org-selection" element={<OrganizationPage />} />
					<Route path="/auth/signup" element={<RegistrationPage />} />
					<Route path="/documents/projects" element={<PrivateRoute component={DocumentsProjects} />} />
					<Route path="/sequencediagram/projects" element={<PrivateRoute component={SequenceDiagramProjects} />} />
					<Route path="/sequencediagram/:projectId" element={<PrivateRoute component={SequenceDiagram} />} />
					<Route path="/quipwhiz" element={<PrivateRoute component={SearchEngine} />} />
				</Routes>
			</Router>
			</WorkspaceProvider>
		</ChakraProvider>
	);
}

export default App;

