import React, { useState, useEffect } from 'react';
import {
	Box,
	Checkbox,
	Stack,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Button,
	Heading,
	Spinner,
	Center,
	Flex,
	useColorMode,
	IconButton
} from '@chakra-ui/react';
import { useWizard } from "react-use-wizard";
import { useFormContext } from "../utils/useFormContext";
import { StrictMode } from 'react';
import { updateData } from '../utils/utilities';
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { AddApiModal } from './common/AddNewAPI';

import _ from 'lodash';
import { baseBRDBuilderUrl, baseDevasUrl, baseUrl } from '../utils/constants';

export function ApiSelectionStep() {
	const { setColorMode } = useColorMode();
    setColorMode("light");
	const [selectedApis, setSelectedApis] = useState([]);
	const [allAPIs, setAllAPIs] = useState([]);
	const [processing, setProcessing] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newApiDetails, setNewApiDetails] = useState({
		name: '',
		description: '',
		method: 'GET',
		sampleResponse: '',
		samplePayload: ''
	});
	const { previousStep, nextStep, isLastStep, isFirstStep } = useWizard();
	const { formData, apiData, setApiData, setSelectedExternalAPIs, currentStep, setCurrentStep, setApiMappings, setDiagramsData, setWorkFlowData, setBrdData, setExtraFyndApis } = useFormContext();

	const fetchAPIs = async (regenerate = false) => {
		let docList = formData.documents.map(doc => ({ "value": doc.url }));
		if (!apiData || apiData.length == 0 || regenerate) {
			setProcessing(true);
			try {
				const response = await fetch(`${baseDevasUrl}devas/chat/extract_api`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ quip_links: docList }), // Replace with your actual state or props
				});
				const data = await response.json();
				setAllAPIs(data);
				setApiData(data);
			} catch (error) {
				console.error('Failed to fetch APIs:', error);
			}
			setProcessing(false);
		} else {
			setAllAPIs(apiData);
		}
	};

	useEffect(() => {
		fetchAPIs();
	}, []);

	const handleApiChange = (api) => {
		setSelectedApis((prevSelectedApis) => {
			return prevSelectedApis.includes(api.name)
				? prevSelectedApis.filter((selectedApi) => selectedApi !== api.name)
				: [...prevSelectedApis, api.name];
		});
		
		setSelectedExternalAPIs((prevSelectedApis) => {
			const apiExists = prevSelectedApis.some(selectedApi => _.isEqual(selectedApi, api));
			
			if (apiExists) {
				return prevSelectedApis.filter(selectedApi => !_.isEqual(selectedApi, api));
			} else {
				return [...prevSelectedApis, api];
			}
		});

		setApiMappings([]);
		setWorkFlowData([]);
		setDiagramsData([]);
		setBrdData(null);
		setExtraFyndApis([]);
	};

	const handleNextClick = (event) => {
		let step = currentStep;
		setCurrentStep(step + 1);
		updateData();
		nextStep();
	}

	const handlePreviousClick = (event) => {
		let step = currentStep;
		setCurrentStep(step - 1);
		previousStep();
	}

	// Handle form input changes for the new API details
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewApiDetails({ ...newApiDetails, [name]: value });
	};

	// Add a new API based on the form data
	const handleAddApi = (newApiDetails) => {
		const newApi = { ...newApiDetails, id: Date.now() };
		setAllAPIs([...allAPIs, newApi]);
		setApiData([...apiData, newApi]);
		setIsModalOpen(false);
	};

	const handleDeleteApi = (index) => {
		setAllAPIs(prev => prev.filter((_, i) => i !== index));
	};

	return (
		<Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} m={6}>
			{processing ? (
				<Center>
					<Spinner size="xl" />
				</Center>
			) : (
				<>
					<Flex justify="space-between" align="center" mb={4}>
						<Heading size="lg">Select Required APIs</Heading>
						<Button colorScheme="gray" onClick={() => setIsModalOpen(true)} leftIcon={<AddIcon />}>Add New API</Button> {/* Move the button to the top right */}
					</Flex>
					
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Select</Th>
								<Th>APIs</Th>
								<Th>Action</Th>
							</Tr>
						</Thead>
						<Tbody>
							{allAPIs.map((api, index) => (
								<Tr key={index}>
									<Td>
										<Checkbox
											isChecked={selectedApis.includes(api.name)}
											onChange={() => handleApiChange(api)}
										/>
									</Td>
									<Td>{api.name}</Td>
									<Td>
										<IconButton
											icon={<DeleteIcon />}
											onClick={() => handleDeleteApi(index)}
											aria-label="Delete API"
											colorScheme='red'
										/>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					<Stack direction="row" mt={6} justify={"center"}>
						<Button isDisabled={processing || isFirstStep} onClick={handlePreviousClick}>Back</Button>
						<Button isDisabled={processing || isLastStep} onClick={handleNextClick}>Next</Button>
					</Stack>
				</>
			)}

			{/* Modal for adding a new API */}
			<AddApiModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSave={handleAddApi} />
		</Box>
	);
}
