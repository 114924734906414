import React, { createContext, useContext, useState, useEffect } from 'react';

const FormContext = createContext();

export function FormProvider({ children }) {
	const [formData, setFormData] = useState(() => JSON.parse(localStorage.getItem('form-data')) || {});
	const [apiData, setApiData] = useState(() => JSON.parse(localStorage.getItem('api-data')) || null);
	const [workFlowData, setWorkFlowData] = useState(() => JSON.parse(localStorage.getItem('workflow-data')) || []);
	const [selectedExternalAPIs, setSelectedExternalAPIs] = useState(() => JSON.parse(localStorage.getItem('selectedExtApis-data')) || []);
	const [mappingData, setMappingData] = useState(() => JSON.parse(localStorage.getItem('mapping-data')) || []);
	const [diagramsData, setDiagramsData] = useState(() => JSON.parse(localStorage.getItem('diagrams-data')) || null);
	const [brdData, setBrdData] = useState(() => JSON.parse(localStorage.getItem('brd-data')) || null);
	const [apiMappings, setApiMappings] = useState(() => JSON.parse(localStorage.getItem('apimappings-data')) || []);
	const [currentStep, setCurrentStep] = useState(() => parseInt(localStorage.getItem('form-step'), 10) || 0);
	const [fyndApisData, setFyndApisData] = useState(() => JSON.parse(localStorage.getItem('fyndapi-data')) || []);
	const [extraFyndApis, setExtraFyndApis] = useState(() => JSON.parse(localStorage.getItem('extrafyndapi-data')) || []);
	const [mappedFyndApis, setMappedFyndApis] = useState(() => JSON.parse(localStorage.getItem('mappedfyndapi-data')) || []);
	// const [apisChanged, setApisChanged] = useState(false);

	useEffect(() => {
		const saveData = () => {
			localStorage.setItem('form-data', JSON.stringify(formData));
			localStorage.setItem('form-step', currentStep.toString());
			localStorage.setItem('api-data', JSON.stringify(apiData));
			localStorage.setItem('workflow-data', JSON.stringify(workFlowData));
			localStorage.setItem('selectedExtApis-data', JSON.stringify(selectedExternalAPIs));
			localStorage.setItem('mapping-data', JSON.stringify(mappingData));
			localStorage.setItem('diagrams-data', JSON.stringify(diagramsData));
			localStorage.setItem('brd-data', JSON.stringify(brdData));
			localStorage.setItem('apimappings-data', JSON.stringify(apiMappings));
			localStorage.setItem('fyndapi-data', JSON.stringify(fyndApisData));
			localStorage.setItem('extrafyndapi-data', JSON.stringify(extraFyndApis));
			localStorage.setItem('mappedfyndapi-data', JSON.stringify(mappedFyndApis));
		};
		saveData();
	}, [formData, currentStep, apiData, workFlowData, selectedExternalAPIs, mappingData, diagramsData, brdData, apiMappings, fyndApisData, extraFyndApis, mappedFyndApis]);

	const contextValue = {
		formData, setFormData, apiData, setApiData, workFlowData, setWorkFlowData,
		selectedExternalAPIs, setSelectedExternalAPIs, mappingData, setMappingData,
		diagramsData, setDiagramsData, brdData, setBrdData, apiMappings, setApiMappings, currentStep,
		setCurrentStep, fyndApisData, setFyndApisData,
		mappedFyndApis, setMappedFyndApis, extraFyndApis, setExtraFyndApis
	};

	return (
		<FormContext.Provider value={contextValue}>
		{children}
		</FormContext.Provider>
	);
}

export const useFormContext = () => useContext(FormContext);

