import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Center, Button, Image, VStack, FormControl, Input, Box, Flex, Text, Icon, useToast, useColorMode, Stack, HStack, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, InputGroup, InputRightElement
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { FcGoogle, FcCheckmark, FcCancel } from 'react-icons/fc';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { baseNejiUrl } from '../utils/constants';
import icons from '../../src/utils/icons';

export const AuthPage = ({ onLogin, onMicrosoftLogin }) => {
    const navigate = useNavigate();
    const { setColorMode } = useColorMode();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [forgotEmail, setForgotEmail] = useState('');
    const [isLoginView, setIsLoginView] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showRegisterPassword, setShowRegisterPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleRegisterPasswordVisibility = () => setShowRegisterPassword(!showRegisterPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    function validateEmail(email) {
        const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regexPattern.test(email);
    }

    function validatePhoneNumber(phoneNumber) {
        const regexPattern = /^\d{10}$/;
        return regexPattern.test(phoneNumber);
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            navigate('/landing');
        }
    }, [navigate]);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const handleAuthAction = async () => {
        const endpoint = isLoginView ? 'authentication/login/password' : 'authentication/register';
        const url = `${baseNejiUrl}${endpoint}`;
        const body = isLoginView ? { username: loginEmail, password: loginPassword } : { firstName: firstname, lastName: lastname, username: registerEmail, phoneNumber: phonenumber, password: registerPassword };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
                credentials: 'include'
            });

            const data = await response.json();
            if (response.ok) {
                if (isLoginView) {
                    const sessionResponse = await fetch(`${baseNejiUrl}session`, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                        credentials: 'include'
                    });

                    const sessionData = await sessionResponse.json();
                    if (sessionResponse.ok) {
                        const encodedSession = btoa(JSON.stringify(sessionData));
                        localStorage.setItem("token", encodedSession);

                        localStorage.setItem("email", data.user.email);

                        toast({
                            title: 'Logged In',
                            description: "User logged in successfully",
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right'
                        });
                        navigate('/landing');
                    } else {
                        throw new Error(sessionData.message);
                    }
                } else {
                    toast({
                        title: 'Registration Successful',
                        description: data.message + " to the registered email",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    });
                    setIsLoginView(true);
                }
            } else {
                if (response.status === 400) {
                    toast({
                        title: 'Bad Request',
                        description: data.message,
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    });
                    return; // Prevent further execution
                } else if (response.status === 500) {
                    toast({
                        title: 'Server Error',
                        description: 'Oops! Something went wrong on our end. Please try again later.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    });
                } else {
                    throw new Error(data.message);
                }
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message || 'An unknown error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };

    const handleForgotPassword = async () => {
        const url = `${baseNejiUrl}authentication/forgot-password`;
        const body = { email: forgotEmail, captchaCode: "_skip_" };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
                credentials: 'include'
            });

            const data = await response.json();
            if (response.ok) {
                toast({
                    title: 'Email Sent',
                    description: "A password reset link has been sent to your email.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
                onClose();
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message || 'An unknown error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            });
        }
    };

    const passwordsMatch = registerPassword === confirmPassword;
    const allFieldsFilled = firstname && lastname && validateEmail(registerEmail) && validatePhoneNumber(phonenumber) && registerPassword && confirmPassword;

    return (
        <Center h="100vh" w="100vw" p={4}>
            <Box p={{ base: 4, md: 8 }} borderWidth={2} borderRadius="xl" bg="white" maxW={{ base: '90%', sm: '400px', md: 'md' }}>
                <VStack spacing={{ base: 4, md: 6 }} align="stretch">
                    <Flex align="center" justifyContent="center" mb={5}>
                        <Image src="https://res.cloudinary.com/ddf4fufyx/image/upload/v1714634024/ai-logo-in_njazhv.png" boxSize={{ base: '50px', md: '70px' }} alt="Company Logo" />
                        <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="normal" ml={3}>Fynd Engineering X</Text>
                    </Flex>
                    <Stack spacing={{ base: 4, md: 6 }} width="100%">
                        {!isLoginView && (
                            <>
                                <HStack spacing={{ base: 2, md: 6 }}>
                                    <FormControl id="firstname">
                                        <Input type="text" focusBorderColor='purple.400' value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder="First Name" size="lg" variant="filled" />
                                    </FormControl>
                                    <FormControl id="lastname">
                                        <Input type="text" focusBorderColor='purple.400' value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder="Last Name" size="lg" variant="filled" />
                                    </FormControl>
                                </HStack>
                                <FormControl id="phonenumber">
                                    <Input type="tel" focusBorderColor='purple.400' value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} placeholder="Phone Number" size="lg" variant="filled" />
                                    <Flex alignItems="center" mt={2}>
                                        {phonenumber && !validatePhoneNumber(phonenumber) && (
                                            <>
                                                <Icon as={FcCancel} boxSize={6} color="red.500" />
                                                <Text ml={2} color="red.500">Phone Number is not valid </Text>
                                            </>
                                        )}

                                    </Flex>
                                </FormControl>
                                <FormControl id="register-email">
                                    <Input type="email" focusBorderColor='purple.400' value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} placeholder="Email" size="lg" variant="filled" />
                                    <Flex alignItems="center" mt={2}>
                                        {registerEmail && !validateEmail(registerEmail) && (
                                            <>
                                                <Icon as={FcCancel} boxSize={6} color="red.500" />
                                                <Text ml={2} color="red.500">Email address is not valid </Text>
                                            </>
                                        )}

                                    </Flex>
                                </FormControl>
                                <FormControl id="register-password">
                                    <InputGroup size="lg">
                                        <Input
                                            type={showRegisterPassword ? "text" : "password"}
                                            focusBorderColor='purple.400'
                                            value={registerPassword}
                                            onChange={(e) => setRegisterPassword(e.target.value)}
                                            placeholder="Password"
                                            variant="filled"
                                        />
                                        <InputRightElement width="3rem">
                                            <Button h="1.75rem" size="sm" onClick={toggleRegisterPasswordVisibility}>
                                                {showRegisterPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="confirm-password">
                                    <InputGroup size="lg">
                                        <Input
                                            type={showConfirmPassword ? "text" : "password"}
                                            focusBorderColor='purple.400'
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            placeholder="Confirm Password"
                                            variant="filled"
                                        />
                                        <InputRightElement width="3rem">
                                            <Button h="1.75rem" size="sm" onClick={toggleConfirmPasswordVisibility}>
                                                {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Flex alignItems="center" mt={2}>
                                        {passwordsMatch && confirmPassword && (
                                            <>
                                                <Icon as={FcCheckmark} boxSize={6} color="green.500" />
                                                <Text ml={2} color="green.500">Passwords match</Text>
                                            </>
                                        )}
                                        {!passwordsMatch && registerPassword && confirmPassword && (
                                            <>
                                                <Icon as={FcCancel} boxSize={6} color="red.500" />
                                                <Text ml={2} color="red.500">Passwords not matching</Text>
                                            </>
                                        )}
                                    </Flex>
                                </FormControl>
                            </>
                        )}
                        {isLoginView && (
                            <>
                                <FormControl id="login-email">
                                    <Input type="email" focusBorderColor='purple.400' value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} placeholder="Email" size="lg" variant="filled" />
                                    <Flex alignItems="center" mt={2}>
                                        {loginEmail && !validateEmail(loginEmail) && (
                                            <>
                                                <Icon as={FcCancel} boxSize={6} color="red.500" />
                                                <Text ml={2} color="red.500">Email address is not valid </Text>
                                            </>
                                        )}
                                    </Flex>
                                </FormControl>
                                <FormControl id="login-password">
                                    <InputGroup size="lg">
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            focusBorderColor='purple.400'
                                            value={loginPassword}
                                            onChange={(e) => setLoginPassword(e.target.value)}
                                            placeholder="Password"
                                            variant="filled"
                                        />
                                        <InputRightElement width="3rem">
                                            <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                                                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <Box textAlign="right" mt="-4">
                                    <Link color="purple.500" onClick={onOpen}>Forgot Password?</Link>
                                </Box>
                            </>
                        )}
                        <Button
                            colorScheme="purple"
                            variant="solid"
                            py={5}
                            rightIcon={<ArrowRightIcon />}
                            onClick={handleAuthAction}
                            isDisabled={!isLoginView ? !(allFieldsFilled && passwordsMatch) : !(validateEmail(loginEmail) && loginPassword)}
                        >
                            {isLoginView ? 'Login' : 'Sign Up'}
                        </Button>
                        <Button
                            colorScheme="purple"
                            variant="outline"
                            onClick={() => {
                                setIsLoginView(!isLoginView);
                                setFirstname('');
                                setLastname('');
                                setRegisterEmail('');
                                setPhonenumber('');
                                setRegisterPassword('');
                                setConfirmPassword('');
                                setLoginEmail('');
                                setLoginPassword('');
                            }}
                        >
                            {isLoginView ? 'Sign Up' : 'Back to Login'}
                        </Button>
                    </Stack>
                    {isLoginView && (
                        <Button
                            aria-label="Login with Google"
                            colorScheme="gray"
                            variant="outline"
                            size="lg"
                            onClick={onLogin}
                            borderRadius="full"
                            bg="transparent"
                            p={2}
                            fontSize={"14px"}
                        >
                            <FcGoogle size="32px" style={{ marginRight: 10 }} />
                            Sign in with Google
                        </Button>
                    )}
                    {isLoginView && (
                        <Button
                            aria-label="Login with Outlook"
                            colorScheme="gray"
                            variant="outline"
                            size="lg"
                            onClick={onMicrosoftLogin}
                            borderRadius="full"
                            bg="transparent"
                            p={2}
                            fontSize={"14px"}
                        >
                            <i style={{ marginRight: '10px' }}>{icons['OutlookIcon']}</i>
                            Sign in with Outlook
                        </Button>
                    )}
                </VStack>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Forgot Password</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="forgot-email">
                            <Input
                                type="email"
                                focusBorderColor="purple.400"
                                value={forgotEmail}
                                onChange={(e) => setForgotEmail(e.target.value)}
                                placeholder="Enter your email"
                                size="lg"
                                variant="filled"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="purple" mr={3} onClick={handleForgotPassword}>
                            Send link
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Center>
    );
};
