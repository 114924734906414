import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Text, Switch, Input, Stack,
  Breadcrumb, useToast, BreadcrumbItem, BreadcrumbLink, useColorModeValue, Button, Divider, Spinner, useDisclosure, IconButton, Collapse,
  useColorMode, RangeSlider, RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderMark, Tooltip, Tabs, TabList, TabPanels, Tab, TabPanel, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Textarea
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon, InfoIcon } from '@chakra-ui/icons';
import PrSideBar from './PrSideBar';
import PrHeader from './PrHeader';
import { useParams } from 'react-router-dom';
import { baseReviewPilotUrl } from '../../utils/constants';
import PromptModal from './PromptModal';

const ConfigurationPage = () => {
  const { repoId } = useParams();
  const { setColorMode } = useColorMode();
  setColorMode('light');
  const [repoName, setRepoName] = useState('Repository');
  const [autoReview, setAutoReview] = useState(true);
  const [excludeFilePatterns, setExcludeFilePatterns] = useState('');

  const [criteria, setCriteria] = useState([]);
  const [openCriteria, setOpenCriteria] = useState({});
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRuleTitle, setNewRuleTitle] = useState('');
  const [newRulePrompt, setNewRulePrompt] = useState('');
  const [newRuleExampleCode, setNewRuleExampleCode] = useState('');
  const [generatedPrompt, setGeneratedPrompt] = useState('');

  const [ratingConfiguration, setRatingConfiguration] = useState([]);
  const [sliderValues, setSliderValues] = useState([]);

  const boxBg = useColorModeValue('white', 'gray.700');
  const mainBg = useColorModeValue('blue.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const descriptionColor = useColorModeValue('gray.500', 'white');
  const linkColor = useColorModeValue('purple.500', 'purple.200');
  const activeTabColor = useColorModeValue('purple.700', 'purple.300');

  useEffect(() => {
    const fetchRepoConfiguration = async () => {
      try {
        const response = await fetch(`${baseReviewPilotUrl}codereviewer/repo/${repoId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
		      credentials: "include"
        });
        const data = await response.json();
        setAutoReview(data.data.status === 'enabled');
        setExcludeFilePatterns(data.data.repo_configuration.code_review_criteria.exclude_file_patterns.join(','));
        setRepoName(data.data.name);

        setCriteria(Object.entries(data.data.repo_configuration.code_review_criteria).map(([key, value]) => ({
          key,
          ...value
        })).filter(criterion => criterion.key !== 'exclude_file_patterns'));

        const initialSliderValues = data.data.rating_configuration.reduce((acc, curr) => {
          const badRange = curr.high.rating_range;
          const goodRange = curr.low.rating_range;
          acc[curr.category] = [badRange[1], goodRange[0]];
          return acc;
        }, {});
        setSliderValues(initialSliderValues);
        setRatingConfiguration(data.data.rating_configuration);
      } catch (error) {
        console.error('Failed to fetch repo configuration:', error);
      }
    };

    fetchRepoConfiguration();
  }, [repoId]);

  const handleApplyChanges = async () => {
    setIsLoading(true);
    const formattedExcludeFilePatterns = excludeFilePatterns.trim() ? excludeFilePatterns.split(',').map((pattern) => pattern.trim()) : [];
    const payload = {
      status: autoReview ? 'enabled' : 'disabled',
      repo_configuration: {
        code_review_criteria: criteria.reduce((acc, criterion) => {
          acc[criterion.key] = {
            enabled: criterion.enabled,
            prompt: criterion.prompt,
            type: criterion.type
          };
          return acc;
        }, {}),
        code_review_run_configuration: {
          pr_create: true,
          pr_comment: true,
          pr_update: true,
        },
      },
      rating_configuration: ratingConfiguration.map(curr => {
        return {
          category: curr.category,
          high: {
            criteria: curr.high.criteria,
            rating_range: [0, sliderValues[curr.category][0]]
          },
          medium: {
            criteria: curr.medium.criteria,
            rating_range: [sliderValues[curr.category][0] + 1, sliderValues[curr.category][1]]
          },
          low: {
            criteria: curr.low.criteria,
            rating_range: [sliderValues[curr.category][1] + 1, 10]
          }
        };
      })
    };

    payload.repo_configuration.code_review_criteria.exclude_file_patterns = formattedExcludeFilePatterns;

    try {
      const response = await fetch(`${baseReviewPilotUrl}codereviewer/repo/${repoId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
		credentials: "include"
      });
      if (response.ok) {
        toast({ title: `Configuration updated for ${repoName}`, status: 'success' });
      } else {
        console.error('Failed to apply changes:', response.statusText);
      }
    } catch (error) {
      console.error('Error applying changes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleEnabled = (key) => {
    setCriteria((prevCriteria) =>
      prevCriteria.map((criterion) =>
        criterion.key === key ? { ...criterion, enabled: !criterion.enabled } : criterion
      )
    );
  };

  const handleToggleOpen = (key) => {
    setOpenCriteria((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  // const handleSliderChange = (category, value) => {
  //   setSliderValues(prevValues => ({
  //     ...prevValues,
  //     [category]: value,
  //   }));
  // };

  const handleAddCustomRule = () => {
    setIsModalOpen(true);
  };

  const handleGenerateRule = async () => {
    try {
      
      const response = await fetch(`${baseReviewPilotUrl}codereviewer/prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: newRuleTitle,
          rule: newRulePrompt,
          rule_example: newRuleExampleCode,
        }),
		credentials: "include"
      });
      const data = await response.json();
      if (data.success) {
        setGeneratedPrompt(data.data.res_prompt);
      } else {
        toast({ title: 'Failed to generate rule', status: 'error' });
      }
    } catch (error) {
      console.error('Error generating rule:', error);
      toast({ title: 'Error generating rule', status: 'error' });
    }
  };

  const handleSaveCustomRule = async () => {
    try {
      
      const response = await fetch(`${baseReviewPilotUrl}codereviewer/repos/${repoId}/rule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: newRuleTitle,
          rule: generatedPrompt,
        }),
		credentials: "include"
      });
      const data = await response.json();
      if (data) {
        const newRule = {
          key: newRuleTitle,
          enabled: true,
          prompt: generatedPrompt,
          type: 'custom',
          title: newRuleTitle,
          exampleCode: newRuleExampleCode,
        };
        setCriteria([...criteria, newRule]);
        setIsModalOpen(false);
        setNewRuleTitle('');
        setNewRulePrompt('');
        setNewRuleExampleCode('');
        setGeneratedPrompt('');
      } else {
        toast({ title: 'Failed to save custom rule', status: 'error' });
      }
    } catch (error) {
      console.error('Error saving custom rule:', error);
      toast({ title: 'Error saving custom rule', status: 'error' });
    }
  };

  function handleSliderChange(category, newValue) {
    // Assuming newValue is an array with 2 values: [lowerValue, upperValue]
    let [lowerValue, upperValue] = newValue;

    // Enforce minimum and maximum thumb positions
    lowerValue = Math.max(lowerValue, 1); // Ensure the left thumb does not go below 1
    upperValue = Math.min(upperValue, 9); // Ensure the right thumb does not exceed 9

    const minDistance = 1;
    const maxDistance = 9;
  
    // Calculate the distance between the new values
    const distance = upperValue - lowerValue;
  
    // Check if the distance is within the allowed range
    if (distance >= minDistance && distance <= maxDistance) {
      // If within range, update the state with the new values
      setSliderValues(prevValues => ({
        ...prevValues,
        [category]: [lowerValue, upperValue]
      }));
    } else {
      // If not within range, either revert to previous values, adjust to min/max distance, or handle as needed
      console.log("Range is out of bounds. Adjusting values...");
      // Example adjustment, more sophisticated handling may be required
      if (distance < minDistance) {
        // Adjust to minimum distance if too close
        const adjustedUpperValue = lowerValue + minDistance;
        setSliderValues(prevValues => ({
          ...prevValues,
          [category]: [lowerValue, Math.min(adjustedUpperValue, 10)] // Ensure not exceeding max
        }));
      } else if (distance > maxDistance) {
        // Adjust to maximum distance if too far apart
        const adjustedUpperValue = lowerValue + maxDistance;
        setSliderValues(prevValues => ({
          ...prevValues,
          [category]: [lowerValue, adjustedUpperValue]
        }));
      }
    }
  }
  
  

  const renderCriteria = (criteriaList) => {
    return criteriaList.map((criterion) => (
      <React.Fragment key={criterion.key}>
        <Flex flexDir="column" mb="4">
          <Flex alignItems="center" mb="2">
            <Text flex="1" color={textColor}>{criterion.key.replace(/_/g, ' ')}</Text>
            <Switch isChecked={criterion.enabled} onChange={() => handleToggleEnabled(criterion.key)} colorScheme="purple" isDisabled={isLoading} />
          </Flex>
          <Text fontSize="sm" color={descriptionColor}>{`Ensure ${criterion.key.replace(/_/g, ' ').toLowerCase()}`}</Text>
          {criterion.prompt && criterion.prompt.length > 1 && (
            <Flex alignItems="center" mt={2}>
              <Text fontSize="sm" color="green" fontWeight="bold" flex="none" mr={2}>View Custom Rule</Text>
              <IconButton
                icon={openCriteria[criterion.key] ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => handleToggleOpen(criterion.key)}
                aria-label="Toggle custom rule"
                size="sm"
                variant="ghost"
                isDisabled={isLoading}
              />
            </Flex>
          )}
          {criterion.prompt && criterion.prompt.length > 1 && (
            <Collapse in={openCriteria[criterion.key]} animateOpacity>
              <Box mt={2} p={3} borderWidth="1px" borderRadius="md" bg="gray.50">
                <Text fontSize="sm" color={textColor} whiteSpace="pre-wrap">
                  {criterion.prompt}
                </Text>
              </Box>
            </Collapse>
          )}
        </Flex>
        <Divider />
      </React.Fragment>
    ));
  };

  const defaultCriteria = criteria.filter(criterion => !criterion.type || criterion.type === 'default');
  const customCriteria = criteria.filter(criterion => criterion.type === 'custom');

  return (
    <Flex h="100vh" flexDir="column" bg={mainBg}>
      <PrHeader userName="prathm201999" tokens={true} />
      <Flex flex="1" overflow="hidden" flexDir={{ base: 'column', md: 'row' }}>
        <PrSideBar currentPage="/configuration" />
        <Box flex="1" p="6" bg={boxBg} overflowY="auto">
          <Breadcrumb mb="4" fontSize="sm">
            <BreadcrumbItem>
              <BreadcrumbLink href="/repositories">Repositories</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/configuration/${repoId}`}>{repoName}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
  
          <Flex justifyContent="space-between" alignItems="center" mb="6" flexDir={{ base: 'column', md: 'row' }}>
            <Text fontSize="2xl" fontWeight="bold" color={textColor} mb={{ base: 4, md: 0 }}>{repoName}</Text>
            <Button colorScheme="purple" onClick={handleApplyChanges} isDisabled={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Apply Changes'}
            </Button>
          </Flex>
  
          <Stack spacing={4} p={4} borderRadius="lg" bg={useColorModeValue('white', 'gray.900')} border="1px solid" borderColor="gray.200">
            <Flex flexDir="column" mb="4">
              <Flex alignItems="center" mb="2">
                <Text flex="1" color={textColor}>Enable Automatic Review</Text>
                <Switch isChecked={autoReview} onChange={() => setAutoReview(!autoReview)} colorScheme="purple" isDisabled={isLoading} />
              </Flex>
              <Text fontSize="sm" color={descriptionColor}>Automatically review the code changes when a new pull request is created.</Text>
            </Flex>
  
            <Divider />
  
            <Flex flexDir="column" mb="4">
              <Text mb="2" color={textColor}>Exclude File Patterns (e.g., *.csv,*.tsv)</Text>
              <Input
                type="text"
                placeholder="Enter file patterns to exclude"
                value={excludeFilePatterns}
                onChange={(e) => setExcludeFilePatterns(e.target.value)}
                focusBorderColor="purple.400"
                isDisabled={isLoading}
              />
            </Flex>
  
            <Divider />
  
            <Text fontSize="xl" fontWeight="bold" mb="4" color={textColor}>Review Criteria</Text>
  
            <Tabs index={tabIndex} onChange={setTabIndex}>
              <TabList>
                <Tab
                  _selected={{ color: 'white', bg: 'purple.500', borderRadius: 'md' }}
                  _hover={{ bg: 'purple.400' }}
                  fontSize="md"
                  fontWeight="bold"
                  px={4}
                  py={2}
                >
                  Default
                </Tab>
                <Tab
                  _selected={{ color: 'white', bg: 'purple.500', borderRadius: 'md' }}
                  _hover={{ bg: 'purple.400' }}
                  fontSize="md"
                  fontWeight="bold"
                  px={4}
                  py={2}
                >
                  Custom
                </Tab>
                <Link ml="auto" color={linkColor} onClick={handleAddCustomRule} fontSize="sm">Add Custom Rule</Link>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {renderCriteria(defaultCriteria)}
                </TabPanel>
                <TabPanel>
                  {renderCriteria(customCriteria)}
                </TabPanel>
              </TabPanels>
            </Tabs>
  
            <Text fontSize="xl" fontWeight="bold" mb="4" color={textColor}>Rating Configuration</Text>
            {ratingConfiguration.map((config) => (
              <Box key={config.category} mb="8">
                <Text mb="2">{config.category.charAt(0).toUpperCase() + config.category.slice(1)}</Text>
                <RangeSlider
                  aria-label={[`${config.category}-good`, `${config.category}-bad`]}
                  value={sliderValues[config.category]}
                  min={0}
                  max={10}
                  step={1}
                  onChange={(val) => handleSliderChange(config.category, val)}
                  colorScheme="purple"
                >
                  <RangeSliderTrack bg="purple.200">
                    <RangeSliderFilledTrack bg="purple.200" />
                  </RangeSliderTrack>
                  <RangeSliderThumb boxSize={6} index={0} bg="purple.500">
                    <Box position="absolute" top="2.5" left="50%" color="gray.100" transform="translate(-50%, -50%)">&lt;</Box>
                  </RangeSliderThumb>
                  <RangeSliderThumb boxSize={6} index={1} bg="purple.500">
                    <Box position="absolute" top="2.5" color="gray.100" left="50%" transform="translate(-50%, -50%)">&gt;</Box>
                  </RangeSliderThumb>
                  {[...Array(11).keys()].map(value => (
                    <RangeSliderMark key={value} value={value} mt="3" ml="-2.5" fontSize="sm" color={descriptionColor}>
                      {value}
                    </RangeSliderMark>
                  ))}
                </RangeSlider>
                <Flex justifyContent="space-between" mt="2">
                  <Flex align="center">
                    <Text color="red.500">Bad: &lt;= {sliderValues[config.category][0]}</Text>
                    <Tooltip label={`Criteria: ${config.high.criteria}`} placement="top" hasArrow>
                      <span>
                        <InfoIcon color="red.500" cursor="help" mt="0" ml="2" />
                      </span>
                    </Tooltip>
                  </Flex>
                  <Flex align="center">
                    <Text color="yellow.500">Average: {sliderValues[config.category][0] + 1} - {sliderValues[config.category][1]}</Text>
                    <Tooltip label={`Criteria: ${config.medium.criteria}`} placement="top" hasArrow>
                      <span>
                        <InfoIcon color="yellow.500" cursor="help" mt="0" ml="2" />
                      </span>
                    </Tooltip>
                  </Flex>
                  <Flex align="center">
                    <Text color="green.500">Good: &gt; {sliderValues[config.category][1]}</Text>
                    <Tooltip label={`Criteria: ${config.low.criteria}`} placement="top" hasArrow>
                      <span>
                        <InfoIcon color="green.500" cursor="help" mt="0" ml="2" />
                      </span>
                    </Tooltip>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Stack>
        </Box>
      </Flex>
  
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom Rule</ModalHeader>
          <ModalCloseButton _focus={{ outline: 'none' }} bg="purple.500" _hover={{ bg: 'purple.400' }} />
          <ModalBody>
            <Flex flexDir="column" gap="4">
              <Box>
                <Text mb="2" fontWeight="bold">Title</Text>
                <Input value={newRuleTitle} onChange={(e) => setNewRuleTitle(e.target.value)} placeholder="Enter rule title" />
              </Box>
              <Box>
                <Text mb="2" fontWeight="bold">Prompt</Text>
                <Textarea value={newRulePrompt} onChange={(e) => setNewRulePrompt(e.target.value)} placeholder="Enter rule prompt" />
              </Box>
              <Box>
                <Text mb="2" fontWeight="bold">Example Code</Text>
                <Textarea value={newRuleExampleCode} onChange={(e) => setNewRuleExampleCode(e.target.value)} placeholder="Enter example code" />
              </Box>
              {generatedPrompt && (
                <Box mt="4" p="4" borderWidth="1px" borderRadius="md" bg="gray.50">
                  <Text fontWeight="bold" mb="2">Generated Prompt:</Text>
                  <Text>{generatedPrompt}</Text>
                </Box>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={handleGenerateRule} mr={3}>Generate</Button>
            <Button colorScheme="purple" onClick={handleSaveCustomRule}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
  
};

export default ConfigurationPage;
