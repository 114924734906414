import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { baseCerebrumUrl, baseUrl, baseNejiUrl } from './constants';
import { getSessionDetails } from './utilities';

export const OAuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const sessionResponse = await fetch(`${baseNejiUrl}session`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
    
            const sessionData = await sessionResponse.json();
            if (sessionResponse.ok) {
                const encodedSession = btoa(JSON.stringify(sessionData));
                localStorage.setItem("token", encodedSession);
    
                localStorage.setItem("email", sessionData?.session?.passport?.user?.email);
                navigate('/org-selection');
            } else {
                navigate('/');
            }
        };
    
        fetchData();
    }, [navigate]);
    
    

    return <p>Loading...</p>;
};



export const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        // If there is no token, redirect to the login page immediately
        return <Navigate to="/" replace />;
    }
    // Only render the component if there is a token
    return <Component {...rest} />;
};

export const MicrosoftOauthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const sessionResponse = await fetch(`${baseNejiUrl}session`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
    
            const sessionData = await sessionResponse.json();
            if (sessionResponse.ok) {
                const encodedSession = btoa(JSON.stringify(sessionData));
                localStorage.setItem("token", encodedSession);
    
                localStorage.setItem("email", sessionData?.session?.passport?.user?.email);
                navigate('/org-selection');
            } else {
                navigate('/');
            }
        };
    
        fetchData();
    }, [navigate]);

    return null; // If nothing is rendered, this can be returned to indicate no visual output
}