import React from "react";
import { Wizard } from "react-use-wizard";
import { BasicInfoStep } from "./BasicInfoStep";
import { DocStep } from "./DocStep";
import { ApiSelectionStep } from "./ApiSelectionStep";
import { FyndApiSelectionStep } from "./FyndApiSelectionStep";
import { WorkflowList } from "./WorkflowList";
import { FlowDiagramList } from "./FlowDiagramsList";
import { FormProvider } from "../utils/useFormContext";
import { BRDViewer } from "./BRDViewer";
import { TopNav } from "./common/TopNav";

export function FexForm() {

	// const wizardRef = React.createRef(null);

	function handleStepChange(stepIndex) {
		localStorage.setItem("form-step", "" + stepIndex);
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	}

	function handleSubmit(e) {
		console.log(e);
	}

	function handleReset(e) {}

	return (
		<FormProvider onSubmit={handleSubmit}>
			<TopNav method={handleStepChange}/>
			<Wizard>
				<BasicInfoStep />
				<DocStep />
				<ApiSelectionStep />
				<WorkflowList />
				<FlowDiagramList />
				<BRDViewer />
			</Wizard>
		</FormProvider>
		
	);
}
