import React, { useEffect, useState, useRef } from 'react';
import {
  Box, Flex, Text, Spinner, VStack, HStack, Stat,
  StatLabel, StatNumber, StatHelpText, Button, useColorMode, Input, Table, Thead, Tbody, Tr, Th, Td, IconButton, useToast, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalOverlay, ModalContent, ModalHeader, ModalFooter, FormControl, FormLabel,
  useBreakpointValue, Highlight
} from '@chakra-ui/react';
import { FaChartLine, FaChartBar, FaChartPie, FaChartArea } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Bar, Line, Radar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Filler, RadialLinearScale } from 'chart.js';
import PrSideBar from './PrSideBar';
import PrHeader from './PrHeader';
import CountUp from 'react-countup';
import { baseReviewPilotUrl, baseDevasUrl, baseHiruzenPlatformUrl } from '../../utils/constants';
import { ExternalLinkIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { getOrgNameById, getSessionDetails, isManager, canInvite } from '../../utils/utilities';
import { NoDataFound } from '../common/NoDataFound';
import { roles } from '../../utils/constants';
import { FaExclamationTriangle } from 'react-icons/fa';

// Register the chart components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Filler, RadialLinearScale);

const Dashboard = () => {
  const { setColorMode } = useColorMode();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [azurePat, setAzurePat] = useState('');
  const [devopsUrl, setDevopsUrl] = useState('');
  const [isPatModalOpen, setIsPatModalOpen] = useState(false);
  const [tokens, setTokens] = useState(true);
  
  const [data, setData] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [repositories, setRepositories] = useState([]);
  const [filteredRepositories, setFilteredRepositories] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [pullRequests, setPullRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalSearch, setModalSearch] = useState('');
  const [modalFilteredUsers, setModalFilteredUsers] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(() => {
    const end = new Date(endDate);
    end.setDate(end.getDate() - 15);
    return end;
  });
const [view, setView] = useState('comments');
  const [addedMembers, setAddedMembers] = useState([]);
  const [repoPage, setRepoPage] = useState(1);
  const [chartType, setChartType] = useState('line');
  const [searchTerm, setSearchTerm] = useState('');
  const debounceTimeout = useRef(null);
  const [emailToAdd, setEmailToAdd] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [email, setEmail] = useState('');
  const [selectedRoleSlug, setSelectedRoleSlug] = useState('');
  const token = JSON.parse(atob(localStorage.getItem("token")));
  const roleSlug = token?.session?.passport?.user?.roleSlug;
  const [sendInviteDisabled, setSendInviteDisabled] = useState(false);
  const filteredRoles = roles.filter(role => canInvite(roleSlug, role.slug));

  const isMobile = useBreakpointValue({ base: true, md: false });
  const initializeData = async () => {
    setTimeout(async () => {
      await fetchInitialData();
      await fetchPullRequests();
      await fetchMetrics();
      if (isManager()) {
        await fetchUsers();
      }
    }, 1000);
    
  };

  const refreshData = async () => {
    // initializeData()
    await fetchTokens()
  };

  let adoPat;

  useEffect(() => {
    const callRefresh = async () => {
      await refreshData();
    }
    callRefresh();
  }, []);

  useEffect(() => {
    fetchMetrics();
    fetchPullRequests();
    setCurrentPage(1);
  }, [startDate, endDate, selectedAuthor, selectedRepo]);

  useEffect(() => {
    fetchPullRequests();
  }, [currentPage]);

  const handleAddMemberClick = () => {
    onOpen();
  };

  const fetchUsers = async () => {
    const response = await fetch(`${baseHiruzenPlatformUrl}org/search`, {
      credentials: 'include',
    });
    const data = await response.json();
    if (isManager()) {
      if (!authors || authors.length == 0) {
        fetchInitialData();
      }
    }

    setUsers(data.result);
    setModalFilteredUsers(data.result);
  };

  const addUnderCurrentUser = async (email) => {
    const payload = {
      user_email: email,
      em_email: localStorage.getItem("email"),
      org_id: JSON.parse(atob(localStorage.getItem("token"))).session.passport.user.orgId
    };

    try {
      const response = await fetch(`${baseReviewPilotUrl}teams`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        onClose();
      } else {
        throw new Error(data.message || "Failed to send invite");
      }
    } catch (error) {
    }
  };

  const sendInvite = async () => {
    setSendInviteDisabled(true);
    let orgId = JSON.parse(atob(localStorage.getItem("token"))).session.passport.user.orgId;
    const inviteData = {
      email: email,
      meta: {
        roleSlug: selectedRoleSlug
      }
    };

    try {
      const response = await fetch(`${baseHiruzenPlatformUrl}role/create-invite?orgId=${orgId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(inviteData),
        credentials: 'include'
      });

      const result = await response.json()

      if (response.ok && result.status === "success") {
        onClose();
        toast({
          title: "Invite Sent",
          description: result?.message || result?.result,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        if (roleSlug === "engg-manager" && selectedRoleSlug !== "org-owner") {
          addUnderCurrentUser(email);
          fetchInitialData();
        }
        
        setSendInviteDisabled(false);
      } else {
        toast({
          title: "Error",
          description: result.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchInitialData = async () => {
    if (roleSlug === "engg-manager") {
      try {
        const response = await fetch(`${baseReviewPilotUrl}teams/details`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ em_email: localStorage.getItem("email") }),
          credentials: 'include'
        });
        const data = await response.json();
        if (response.ok) {
          setAuthors(data.data.members.map(email => ({ value: email, label: email })));
        } else {
          throw new Error("Failed to fetch authors");
        }
      } catch (error) {
        setError(error.toString());
      }
    } else if (roleSlug === "super-admin" || roleSlug === "org-owner") {
      try {
        const authorsResponse = await fetch(`${baseReviewPilotUrl}codereviewer/authors`, {
          credentials: 'include'
        });
        const authorsData = await authorsResponse.json();
        setAuthors(authorsData.data.authors.map(author => ({ value: author, label: author })));
      } catch (error) {
        setError(error.toString());
      } finally {
      }
    }
    fetchRepositories();
  };

  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        start_date: startDate.toISOString().slice(0, 10),
        end_date: endDate.toISOString().slice(0, 10),
      });
      const field = 'email';
      if (selectedAuthor) params.append(field, selectedAuthor.value);
      if (selectedRepo) params.append('repo_name', selectedRepo.label);
      if (!selectedAuthor && roleSlug !== "org-owner") params.append(field, localStorage.getItem("email"));
      const metricsResponse = await fetch(`${baseReviewPilotUrl}codereviewer/metrics?${params}`, {
        credentials: 'include'
      });
      const metricsData = await metricsResponse.json();
      setData(metricsData.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreRepositories = () => {
    setRepoPage(prevPage => prevPage + 1);
    fetchRepositories(searchTerm, repoPage + 1);
  };

  const fetchTokens = async () => {
    const roleSlug = getSessionDetails().session.passport?.user?.roleSlug;
    if (roleSlug == "org-owner") {
      const resp = await fetch(`${baseReviewPilotUrl}org_details/${getSessionDetails().session.passport.user.orgId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
     });
     const data = await resp.json();

     if (data.success === true) {
      // const data = await resp.json();
      localStorage.setItem("adoPat", data.data.vc_pat);
      setTokens(true);
      initializeData();
     } else {
      setIsPatModalOpen(true);
      setTokens(false);
     }


    } else {
      try {
        const response = await fetch(`${baseDevasUrl}user/config/${localStorage.getItem("email")}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
  
        const data = await response.json();
  
        if (data.success === true) {
          adoPat = data.data.azure_pat_token;
          localStorage.setItem("adoPat", adoPat);
          initializeData();
          setTokens(true);
        } else {
          // setIsPatModalOpen(true);
          setTokens(false);
        }
      } catch (error) {
  
      }
    }
    
  };

 
const savePatToken = async () => {
  const userEmail = localStorage.getItem("email");
  const sessionDetails = getSessionDetails();
  const orgId = sessionDetails.session.passport.user.orgId;
  const orgName = getOrgNameById(sessionDetails.session.passport.user.workspace, orgId);
  const roleSlug = sessionDetails.session.passport.user.roleSlug;

  if (!azurePat || !devopsUrl) return;

  try {
      const response = await fetch(`${baseDevasUrl}user/config`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ email_id: userEmail, azure_pat_token: azurePat })
      });

      const data = await response.json();

      if (data.success) {
          if (roleSlug === "org-owner") {
              await fetch(`${baseReviewPilotUrl}org_details`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      org_id: orgId,
                      org_name: orgName,
                      vc_url: devopsUrl,
                      vc_pat: azurePat
                  })
              });
          }

          setIsPatModalOpen(false);
          toast({
              title: "Azure PAT saved successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
          });

          initializeData();
      } else {
          toast({
              title: "Failed to save Azure PAT!",
              description: data.message,
              status: "error",
              duration: 3000,
              isClosable: true,
          });
      }
  } catch (error) {
      toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
      });
  }
};

  const fetchRepositories = async (repoNames = '', page = 1) => {
    if (roleSlug === "org-owner") {
      try {
        const queryParams = new URLSearchParams({
          page,
          size: 10,
        });

        if (repoNames && repoNames.length > 0) queryParams.set('search_repo', repoNames);

        const reposResponse = await fetch(`${baseReviewPilotUrl}codereviewer/list_repos?${queryParams}`, {credentials: 'include'});
        const reposData = await reposResponse.json();
        const newRepos = reposData.data.map(repo => ({ value: repo.id, label: repo.name }));
        setRepositories(prevRepos => {
          const mergedRepos = [...prevRepos, ...newRepos];
          const uniqueRepos = Array.from(new Set(mergedRepos.map(repo => repo.value)))
            .map(id => mergedRepos.find(repo => repo.value === id));
          return uniqueRepos;
        });
        setFilteredRepositories(prevRepos => {
          const mergedRepos = [...prevRepos, ...newRepos];
          const uniqueRepos = Array.from(new Set(mergedRepos.map(repo => repo.value)))
            .map(id => mergedRepos.find(repo => repo.value === id));
          return uniqueRepos;
        });
      } catch (error) {
        setError(error);
      }
    } else {
      try {
        const reposResponse = await fetch(`${baseReviewPilotUrl}codereviewer/list_repos/provider`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_pat: adoPat, provider_repo_list: true }),
          credentials: 'include'
        });
        const reposData = await reposResponse.json();
        const repoOptions = reposData.data.map(repo => ({ value: repo.id, label: repo.name }));
        if (reposData.success === false) {
          // toast({
          //   title: "Failed to fetch azure repositories!",
          //   description: "Please configure your Azure devops PAT in profile -> tokens.",
          //   status: "error",
          //   duration: 3000,
          //   isClosable: true,
          //   position: "top"
          // });
          setLoading(false);
        }
        setRepositories(repoOptions);
        setFilteredRepositories(repoOptions);
      } catch (error) {
        setError(error);
      }
    }
  };

  const fetchPullRequests = async () => {
    try {
      const params = new URLSearchParams({
        page: currentPage,
        size: 5,
        start_date: startDate.toISOString().slice(0, 10),
        end_date: endDate.toISOString().slice(0, 10),
      });
      const field = 'email';
      if (selectedAuthor) params.append(field, selectedAuthor.value);
      if (selectedRepo) params.append('repo_name', selectedRepo.label);
      if (!selectedAuthor && roleSlug !== "org-owner") params.append(field, localStorage.getItem("email"));
      const prResponse = await fetch(`${baseReviewPilotUrl}codereviewer/reviewed/pr?${params}`, { credentials: 'include' });
      const prData = await prResponse.json();
      let totalPages = prData.data.pagination_info.total_records / 5;
      if (totalPages > Math.floor(prData.data.pagination_info.total_records / 5)) {
        totalPages = Math.floor(prData.data.pagination_info.total_records / 5) + 1
        setTotalPages(totalPages);
      }
      if (totalPages == 0) {
        setTotalPages(totalPages);
      }
    setPullRequests(prData.data.pr_details);
      
    } catch (error) {
      setError(error);
    }
  };

  const loadAuthorsOptions = (inputValue, callback) => {
    const filtered = authors.filter(author => author.label.toLowerCase().includes(inputValue.toLowerCase()));
    callback(filtered);
  };

  const handleRepoChange = (selectedOption) => {
    setSelectedRepo(selectedOption);
    setPullRequests([]);
    setCurrentPage(1);
    if (selectedOption) {
      fetchPullRequests(selectedOption.value);
      fetchMetrics();
    }
  };

  const handleClearFilters = () => {
    setSelectedAuthor(null);
    setSelectedRepo(null);
    setEndDate(new Date());
    setStartDate(() => {
      const end = new Date(endDate);
      end.setDate(end.getDate() - 15);
      return end;
    });
    setSearchTerm('');
    setCurrentPage(1);
    fetchMetrics();
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (roleSlug === "org-owner") {
      debounceTimeout.current = setTimeout(() => fetchRepositories(event, 1), 1000);
    } else {
      debounceTimeout.current = setTimeout(() => {
        const filtered = repositories.filter(repo => repo.label.toLowerCase().includes(event.toLowerCase()));
        setFilteredRepositories(filtered);
      }, 500);
    }
  };

  const customSelectStyle = {
    control: (provided, state) => ({
      ...provided,
      minWidth: 200,
      width: '100%',
      height: '30px',
      borderColor: state.isFocused ? '#A020F0' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #A020F0' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#A020F0' : provided['&:hover'].borderColor,
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
    }),
  };

  const barChartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: true
        },
        beginAtZero: true
      }
    }
  };

  const radarChartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'left'
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      r: {
        beginAtZero: true,
        angleLines: {
          color: 'rgba(0, 0, 0, 0.1)'
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)'
        },
        pointLabels: {
          color: '#666'
        }
      }
    }
  };

  const lineChartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: true
        },
        beginAtZero: true
      }
    }
  };

  const barChartData = {
    labels: [
      'Security', 'Best Practice', 'Bug',
      'Concern', 'Refactoring', 'Optimization', 'Enhancement',
      'Maintainability'
    ],
    datasets: [{
      label: '# of Comments',
      data: [
        data?.type_of_comments_with_count?.security,
        data?.type_of_comments_with_count?.["best practice"],
        data?.type_of_comments_with_count?.bug,
        data?.type_of_comments_with_count?.concern,
        data?.type_of_comments_with_count?.refactoring,
        data?.type_of_comments_with_count?.optimization,
        data?.type_of_comments_with_count?.enhancement,
        data?.type_of_comments_with_count?.maintainability,
      ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)',
        'rgba(199, 199, 199, 0.5)', 'rgba(10, 10, 10, 0.5)', 'rgba(99, 132, 255, 0.5)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)',
        'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)',
        'rgba(159, 159, 159, 1)', 'rgba(10, 10, 10, 1)', 'rgba(99, 132, 255, 1)'
      ],
      borderWidth: 2,
    }]
  };

  const ratingsBarChartData = {
    labels: [
      'Security', 'Bug',
      'Concern', 'Refactoring', 'Optimization', 'Enhancement',
      'Maintainability'
    ],
    datasets: [{
      label: "Ratings",
      data: [
        data?.bar_chart_rating?.security,
        data?.bar_chart_rating?.bug,
        data?.bar_chart_rating?.concern,
        data?.bar_chart_rating?.refactoring_rating,
        data?.bar_chart_rating?.optimization,
        data?.bar_chart_rating?.enhancement,
        data?.bar_chart_rating?.maintainability,
      ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)',
        'rgba(199, 199, 199, 0.5)', 'rgba(10, 10, 10, 0.5)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)',
        'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)',
        'rgba(159, 159, 159, 1)', 'rgba(10, 10, 10, 1)'
      ],
      borderWidth: 2
    }]
  }

  const lineChartData = {
    labels: data?.line_chart_rating?.times.map(time => new Date(time).toLocaleDateString()),
    datasets: [
      {
        label: 'Security',
        data: data?.line_chart_rating?.ratings?.security,
        borderColor: 'rgba(255, 99, 132, 0.8)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Bug',
        data: data?.line_chart_rating?.ratings?.bug,
        borderColor: 'rgba(54, 162, 235, 0.8)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Optimization',
        data: data?.line_chart_rating?.ratings?.optimization,
        borderColor: 'rgba(255, 206, 86, 0.8)',
        backgroundColor: 'rgba(255, 206, 86, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Enhancement',
        data: data?.line_chart_rating?.ratings?.enhancement,
        borderColor: 'rgba(75, 192, 192, 0.8)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Concern',
        data: data?.line_chart_rating?.ratings?.concern,
        borderColor: 'rgba(153, 102, 255, 0.8)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Best Practice',
        data: data?.line_chart_rating?.ratings?.best_practice,
        borderColor: 'rgba(255, 159, 64, 0.8)',
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Maintainability',
        data: data?.line_chart_rating?.ratings?.maintainability,
        borderColor: 'rgba(199, 199, 199, 0.8)',
        backgroundColor: 'rgba(199, 199, 199, 0.5)',
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Refactoring',
        data: data?.line_chart_rating?.ratings?.refactoring,
        borderColor: 'rgba(159, 159, 159, 0.8)',
        backgroundColor: 'rgba(159, 159, 159, 0.5)',
        fill: false,
        tension: 0.4,
      }
    ]
  };

  const radarChartData = {
    labels: [
      'Security', 'Bug',
      'Concern', 'Refactoring', 'Optimization', 'Enhancement',
      'Maintainability'
    ],
    datasets: [{
      label: "Ratings",
      data: [
        data?.bar_chart_rating?.security,
        data?.bar_chart_rating?.bug,
        data?.bar_chart_rating?.concern,
        data?.bar_chart_rating?.refactoring_rating,
        data?.bar_chart_rating?.optimization,
        data?.bar_chart_rating?.enhancement,
        data?.bar_chart_rating?.maintainability,
      ],
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(54, 162, 235, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(54, 162, 235, 1)'
    }]
  };

  const areaChartData = {
    ...lineChartData,
    datasets: lineChartData.datasets.map(dataset => ({
      ...dataset,
      fill: true,
      backgroundColor: dataset.backgroundColor.replace('0.5', '0.2'), // Make it more transparent
    }))
  };

  const getChartComponent = () => {
    switch (chartType) {
      case 'bar':
        return <Bar data={ratingsBarChartData} options={barChartOptions} />;
      case 'radar':
        return <Radar data={radarChartData} options={radarChartOptions} />;
      case 'area':
        return <Line data={areaChartData} options={lineChartOptions} />;
      default:
        return <Line data={lineChartData} options={lineChartOptions} />;
    }
  };

  useEffect(() => {
    setModalFilteredUsers(
      users.filter(user =>
        user.email.toLowerCase().includes(modalSearch.toLowerCase())
      )
    );
  }, [modalSearch, users]);

  const handleModalSearchChange = (event) => {
    setModalSearch(event.target.value);
  };

  return (
    <Flex h="100vh" flexDir="column" overflow="hidden">
      <PrHeader userName="prathm201999" changeWorkspace={refreshData} tokens={tokens} />
      <Flex flex="1" direction={{ base: 'column', md: 'row' }} overflowY="auto">
        <PrSideBar currentPage="/dashboard" />
        <Box flex="1" p="6" overflowY="auto">
          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Invite Member</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl mb={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    focusBorderColor="purple.400"
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>Role</FormLabel>
                  <Select
                    placeholder="Select role"
                    value={filteredRoles.find(role => role.slug === selectedRoleSlug) ? { label: filteredRoles.find(role => role.slug === selectedRoleSlug).name, value: selectedRoleSlug } : null}
                    onChange={(selectedOption) => setSelectedRoleSlug(selectedOption.value)}
                    options={filteredRoles.map(role => ({
                      label: role.name,
                      value: role.slug
                    }))}
                    styles={customSelectStyle}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="purple" onClick={sendInvite} disabled={sendInviteDisabled}>
                  Send Invite
                </Button>
                <Button variant="ghost" onClick={onClose} ml={3}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Flex alignItems="center" justifyContent="space-between" mb="6" ml={2}>
          <Flex alignItems="center">
              <Text fontSize="2xl" fontWeight="bold">Dashboard Overview</Text>
              {/* {tokens === null && (
                <Text fontSize="sm" fontWeight="bold" ml={3}>
                  <Highlight
                    query={['Please complete your token configuration']}
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.100', color: 'red.700' }}
                  >
                    Please complete your token configuration
                  </Highlight>
                </Text>
              )} */}
            </Flex>

            {['org-owner', 'super-admin', 'engg-manager'].includes(JSON.parse(atob(localStorage.getItem("token"))).session?.passport?.user?.roleSlug) && (
              <Button onClick={handleAddMemberClick} colorScheme="purple">Invite Member</Button>
            )}
          </Flex>
          <VStack spacing={4} align="stretch" mb="8">
            <HStack spacing={4} width="full" flexWrap="wrap">
              <Stat p="4" boxShadow="sm" borderRadius="lg" bg="gray.50" borderColor="gray.200" borderWidth="1px" flex="1">
                <StatLabel fontSize="lg" fontWeight="bold">{(getSessionDetails().session.passport.user.workspace.find(ws => ws.orgId === getSessionDetails().session.passport.user.orgId))?.orgName || ''} Integrated Projects</StatLabel>
                <StatNumber fontSize="2xl"><CountUp end={data?.integrated_projects} duration={2.75} /></StatNumber>
                <StatHelpText>Projects currently active</StatHelpText>
              </Stat>
              <Stat p="4" boxShadow="sm" borderRadius="lg" bg="gray.50" borderColor="gray.200" borderWidth="1px" flex="1">
                <StatLabel fontSize="lg" fontWeight="bold">{(getSessionDetails().session.passport.user.workspace.find(ws => ws.orgId === getSessionDetails().session.passport.user.orgId))?.orgName || ''} Integrated Repositories</StatLabel>
                <StatNumber fontSize="2xl"><CountUp end={data?.integrated_repos} duration={2.75} /></StatNumber>
                <StatHelpText>Repositories connected</StatHelpText>
              </Stat>
            </HStack>
            <HStack spacing={4} width="full" flexWrap="wrap" alignItems="center">
              {['engg-manager', 'org-owner', ].includes(JSON.parse(atob(localStorage.getItem("token"))).session?.passport?.user?.roleSlug) && (
                <Box flex="1">
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadAuthorsOptions}
                    defaultOptions={authors}
                    placeholder="Search by Author"
                    styles={customSelectStyle}
                    onChange={setSelectedAuthor}
                    value={selectedAuthor}
                  />
                </Box>
              )}
              <Box flex="1">
                <Select
                  options={filteredRepositories}
                  placeholder="Filter by Repository"
                  onMenuScrollToBottom={roleSlug === "org-owner" ? loadMoreRepositories : undefined}
                  styles={customSelectStyle}
                  onChange={handleRepoChange}
                  value={selectedRepo}
                  onInputChange={handleSearchInputChange}
                />
              </Box>
              <Box>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  wrapperClassName="datePicker"
                  customInput={<Input focusBorderColor='purple.400' />}
                />
              </Box>
              <Box>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  dateFormat="yyyy-MM-dd"
                  wrapperClassName="datePicker"
                  customInput={<Input focusBorderColor='purple.400' />}
                />
              </Box>
              <Box>
                <Button onClick={handleClearFilters} colorScheme="gray" variant="outline" height="30px" fontSize="0.8rem">
                  Clear Filters
                </Button>
              </Box>
            </HStack>
          </VStack>
          <HStack spacing={6} align="flex-start" flexWrap="wrap">
            {isMobile && (
              <VStack spacing={4} align="stretch" width="full">
                <Button onClick={() => setView("comments")} colorScheme={view === "comments" ? "purple" : "gray"}>
                  Comments
                </Button>
                <Button onClick={() => setView("ratings")} colorScheme={view === "ratings" ? "purple" : "gray"}>
                  Ratings
                </Button>
                <HStack spacing={4} width="full">
                  <Flex flex="1">
                    <Stat p="5" boxShadow="sm" borderRadius="lg" bg="gray.50" borderColor="gray.200" borderWidth="1px" height="100%">
                      <StatLabel fontSize="lg" fontWeight="bold">PRs Reviewed</StatLabel>
                      <StatNumber fontSize="2xl"><CountUp end={data?.prs_reviewed} duration={2.75} /></StatNumber>
                      <StatHelpText>Pull requests analyzed</StatHelpText>
                    </Stat>
                  </Flex>
                  <Flex flex="1.02">
                    <Stat p="5" boxShadow="sm" borderRadius="lg" bg="gray.50" borderColor="gray.200" borderWidth="1px" height="100%">
                      <StatLabel fontSize="lg" fontWeight="bold">Repositories Reviewed</StatLabel>
                      <StatNumber fontSize="2xl"><CountUp end={data?.repos_reviewed} duration={2.75} /></StatNumber>
                      <StatHelpText>Repositories assessed</StatHelpText>
                    </Stat>
                  </Flex>
                </HStack>
              </VStack>
            )}
            <Box flex="1" height="470px" width="full" p="2" borderWidth="1px" borderColor="gray.300" position="relative">
              {loading ? (
                <Spinner size="xl" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
              ) : (
                <>
                  {view === "comments" ? (
                    <Bar data={barChartData} options={barChartOptions} />
                  ) : (
                    <>
                      <VStack spacing={2} className="chart-icons" position="absolute" right="16px" top="16px">
                        <IconButton
                          icon={<FaChartLine />}
                          aria-label="Line Chart"
                          onClick={() => setChartType("line")}
                          colorScheme={chartType === "line" ? "purple" : "gray"}
                        />
                        <IconButton
                          icon={<FaChartBar />}
                          aria-label="Bar Chart"
                          onClick={() => setChartType("bar")}
                          colorScheme={chartType === "bar" ? "purple" : "gray"}
                        />
                        <IconButton
                          icon={<FaChartArea />}
                          aria-label="Area Chart"
                          onClick={() => setChartType("area")}
                          colorScheme={chartType === "area" ? "purple" : "gray"}
                        />
                      </VStack>
                      {getChartComponent()}
                    </>
                  )}
                </>
              )}
            </Box>
            {!isMobile && (
              <VStack spacing={4} align="stretch" minW="240px" width="auto">
                <Button onClick={() => setView("comments")} colorScheme={view === "comments" ? "purple" : "gray"}>
                  Comments
                </Button>
                <Button onClick={() => setView("ratings")} colorScheme={view === "ratings" ? "purple" : "gray"}>
                  Ratings
                </Button>
                <Flex flex="1">
                  <Stat p="5" boxShadow="sm" borderRadius="lg" bg="gray.50" borderColor="gray.200" borderWidth="1px" height="100%">
                    <StatLabel fontSize="lg" fontWeight="bold">PRs Reviewed</StatLabel>
                    <StatNumber fontSize="2xl"><CountUp end={data?.prs_reviewed} duration={2.75} /></StatNumber>
                    <StatHelpText>Pull requests analyzed</StatHelpText>
                  </Stat>
                </Flex>
                <Flex flex="1">
                  <Stat p="5" boxShadow="sm" borderRadius="lg" bg="gray.50" borderColor="gray.200" borderWidth="1px" height="100%">
                    <StatLabel fontSize="lg" fontWeight="bold">Repositories Reviewed</StatLabel>
                    <StatNumber fontSize="2xl"><CountUp end={data?.repos_reviewed} duration={2.75} /></StatNumber>
                    <StatHelpText>Repositories assessed</StatHelpText>
                  </Stat>
                </Flex>
              </VStack>
            )}
          </HStack>
          <Box mt="6">
            <Text fontSize="xl" fontWeight="bold" mb="4">Pull Request History</Text>
            <Box overflowX="auto">
              {pullRequests.length === 0 ? (
                <Table variant="striped" colorScheme="gray">
                  <Tbody>
                    <NoDataFound colSpan={4} forTable={true} />
                  </Tbody>
                </Table>
              ) : (
                <Table variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Title</Th>
                      <Th>Repository</Th>
                      <Th>Go to PR</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {pullRequests.map(pr => (
                      <Tr key={pr.id}>
                        <Td>{pr.id}</Td>
                        <Td>{pr.title}</Td>
                        <Td>{pr.repo_url.split('/').slice(-1)[0]}</Td>
                        <Td>
                          <IconButton
                            as="a"
                            href={pr.pr_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            icon={<ExternalLinkIcon />}
                            aria-label="Go to PR"
                            variant="outline"
                            colorScheme="blue"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
            {pullRequests.length !== 0 && (
              <HStack mt="4" justifyContent="center">
                <IconButton
                  icon={<ChevronLeftIcon />}
                  aria-label="Previous Page"
                  onClick={handlePreviousPage}
                  isDisabled={currentPage === 1}
                />
                <Text>Page {currentPage} of {totalPages}</Text>
                <IconButton
                  icon={<ChevronRightIcon />}
                  aria-label="Next Page"
                  onClick={handleNextPage}
                  isDisabled={currentPage === totalPages}
                />
              </HStack>
            )}
          </Box>
        </Box>
      </Flex>
<Modal isOpen={isPatModalOpen} onClose={() => setIsPatModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Azure DevOps PAT Required</ModalHeader>
        <ModalCloseButton color={"black"} _hover={{ "color": "white", bg: "purple.600" }} />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Azure PAT Token</FormLabel>
            <Input
              value={azurePat}
              onChange={(e) => setAzurePat(e.target.value)}
              placeholder="Enter Azure PAT Token"
              focusBorderColor="purple.400"
            />
          </FormControl>
          { roleSlug === "member" ? `` : (
            <FormControl isRequired mt={4}>
            <FormLabel>DevOps URL</FormLabel>
            <Input
              value={devopsUrl}
              onChange={(e) => setDevopsUrl(e.target.value)}
              placeholder="Enter DevOps URL"
              focusBorderColor="purple.400"
            />
            </FormControl>
          )}
          
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="purple"
            onClick={savePatToken}
            isDisabled={!azurePat || !devopsUrl}
          >
            Save
          </Button>
          <Button
            variant="ghost"
            onClick={() => setIsPatModalOpen(false)}
            ml={3}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    </Flex>
  );
  
};

export default Dashboard;
