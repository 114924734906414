import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Heading, Input, Stack, Button, useColorModeValue, useToast, HStack, Image, useColorMode, VStack, Text, Divider, FormErrorMessage
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ProfileMenu from './MenuProfile';
import { baseDevasUrl, baseReviewPilotUrl } from '../../utils/constants';
import { getSessionDetails } from '../../utils/utilities';

const ConfigurePage = () => {
  const [adoPat, setAdoPat] = useState('');
  const [adoURL, setAdoURL] = useState('');
  const [errors, setErrors] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const boxBg = useColorModeValue('white', 'gray.800');
  const mainBg = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const { setColorMode } = useColorMode();
  setColorMode('light');
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchTokens = async () => {
      const currentRoleSlug = getSessionDetails().session?.passport?.user?.roleSlug;
      if (currentRoleSlug == "org-owner") {
        const resp = await fetch(`${baseReviewPilotUrl}org_details/${getSessionDetails().session.passport.user.orgId}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          credentials: 'include'
       });
  
       if (resp.ok) {
        const data = await resp.json();
        // localStorage.setItem("adoPat", data.data.vc_pat);
        setAdoPat(data.data.vc_pat);
        setAdoURL(data.data.vc_url);
        // setQuipPat(data.data.quip_token);
        // setIsUpdate(true);
        // initializeData();
       } else {
        console.log(resp);
       }

      } else {
        try {
          const response = await fetch(`${baseDevasUrl}user/config/${localStorage.getItem("email")}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
  
          const data = await response.json();
  
          if (data.success) {
            setAdoPat(data.data.azure_pat_token);
            // setQuipPat(data.data.quip_token);
            setIsUpdate(true);
          } else {
          //   toast({
          //     title: "Error",
          //     description: "Failed to load the tokens.",
          //     status: "error",
          //     duration: 3000,
          //     isClosable: true,
          //   });
          }
        } catch (error) {
          toast({
            title: "Error",
            description: "An error occurred while loading the tokens.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
      
    };

    fetchTokens();
  }, [toast]);

  const handleSaveTokens = async () => {
    const userEmail = localStorage.getItem("email");

    // Validate inputs
    const validationErrors = {};
    if (!adoPat) validationErrors.adoPat = 'Azure PAT is required';

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      toast({
        title: "Validation Error",
        description: "Please fill in the Azure Devops token.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    const method = isUpdate ? 'PUT' : 'POST';
    const url = isUpdate ? `${baseDevasUrl}user/config/${userEmail}` : `${baseDevasUrl}user/config`;
    const currentRoleSlug = getSessionDetails().session?.passport?.user?.roleSlug;

    if (currentRoleSlug == "org-owner") {
     const resp = await fetch(`${baseReviewPilotUrl}org_details`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ org_id: getSessionDetails().session?.passport?.user?.orgId, vc_pat: adoPat, vc_url: adoURL })
      });
      if (resp.ok) {
        toast({
          title: "Success",
          description: "The tokens have been saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          navigate(-1);
        }, 500);
      }
    } else {
      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email_id: userEmail, azure_pat_token: adoPat })
        });
  
        const data = await response.json();
  
        if (data.success) {
          toast({
            title: "Success",
            description: "The tokens have been saved.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          if (data.errors?.[0] === "Organization Already Exists") {
            toast({
              title: "Error",
              description: data.errors?.[0],
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error",
              description: "Failed to save the tokens.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
         
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "An error occurred while saving the tokens.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    
  };

  return (
    <Box bg={mainBg} minH="100vh">
      <Flex justifyContent="space-between" alignItems="center" p={4} bg={boxBg} boxShadow="sm">
        <Flex alignItems="center">
          <Image src="https://res.cloudinary.com/ddf4fufyx/image/upload/v1714634024/ai-logo-in_njazhv.png" alt="FEX Logo" boxSize="50px" mr={4} />
          <Heading color={textColor} size="lg">Fynd Engineering X</Heading>
        </Flex>
        <ProfileMenu />
      </Flex>
      <Box p={10}>
        <Flex justifyContent="center" mb={8}>
          <Heading color={textColor}>Configure Tokens</Heading>
        </Flex>
        <VStack spacing={4} justifyContent={"center"}>
            <Text fontWeight={"bold"}>Azure Personal Access Token (Org Level)</Text>
            <Input
                placeholder="Azure Devops PAT (Personal Access Token)"
                value={adoPat}
                onChange={(e) => setAdoPat(e.target.value)}
                focusBorderColor='purple.400'
                width={"50%"}
                type="password"
            />
            {errors.adoPat && <FormErrorMessage>{errors.adoPat}</FormErrorMessage>}
            {
               getSessionDetails().session?.passport?.user?.roleSlug === "org-owner" ? (
                <>
                <Text fontWeight={"bold"}>Azure Devops Base URL</Text>
                <Input
                    placeholder="Azure devops Base URL"
                    value={adoURL}
                    onChange={(e) => setAdoURL(e.target.value)}
                    focusBorderColor='purple.400'
                    width={"50%"}
                />
                </>
               ) : ``
            }
            
            {/* <Text fontWeight={"bold"}>Quip Token</Text>
            <Input
                placeholder="Quip PAT (Personal Access Token)"
                value={quipPat}
                onChange={(e) => setQuipPat(e.target.value)}
                focusBorderColor='purple.400'
                width={"50%"}
            /> */}
            <Divider />
            <HStack justifyContent={"center"}>
                <Button colorScheme="purple" onClick={handleSaveTokens}>Save</Button>
                <Button variant="outline" onClick={() => navigate(-1)}>Cancel</Button>
            </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default ConfigurePage;
