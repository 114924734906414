import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Flex, Heading, Input, Button, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Table, Thead, Tbody, Tr, Th, Td,
  Progress, useToast, Badge, Stack, HStack, IconButton, Tooltip, Select, Checkbox, createIcon, Icon
} from '@chakra-ui/react';
import { AddIcon, ExternalLinkIcon, DeleteIcon, CopyIcon, LockIcon } from '@chakra-ui/icons';
import ReactMarkdown from 'react-markdown';
import chakraUIRenderer from 'chakra-ui-markdown-renderer';
import { PropagateLoader, HashLoader } from 'react-spinners';
import ProfileMenu from '../common/MenuProfile';
import { baseDevasUrl } from '../../utils/constants';

const SearchEngine = () => {
  const { isOpen: isAgentsOpen, onOpen: onAgentsOpen, onClose: onAgentsClose } = useDisclosure();
  const { isOpen: isAddAgentOpen, onOpen: onAddAgentOpen, onClose: onAddAgentClose } = useDisclosure();
  const { isOpen: isPATModalOpen, onOpen: onPATModalOpen, onClose: onPATModalClose } = useDisclosure();


  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [currentAgent, setCurrentAgent] = useState('');
  const [newAgentName, setNewAgentName] = useState('');
  const [quipLink, setQuipLink] = useState('');
//   const [searchTerm, setSearchTerm] = useState('');
  const [agentsSearchTerm, setAgentsSearchTerm] = useState('');
  const [mainSearchTerm, setMainSearchTerm] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isAddingAgentLoading, setIsAddingAgentLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [streamingResponse, setStreamingResponse] = useState('');
  const [searchStarted, setSearchStarted] = useState(false);
  const eventSourceRef = useRef(null);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [patList, setPatList] = useState([]);
  const [newTokenName, setNewTokenName] = useState('');
  const [newExpireBy, setnewExpireBy] = useState('');
  const [noExpiration, setNoExpiration] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [addedPAT, setAddedPAT] = useState(null); // State to store newly added PAT
  const [isPATFormDisabled, setisPATFormDisabled] = useState(true)
  const [files, setFiles] = useState([]);

  
  let disableSearch = false;
  const toast = useToast();

  const fetchAgents = async () => {
    try {
      const response = await fetch(`${baseDevasUrl}agent/email/${localStorage.getItem("email")}`);
      const response_data = await response.json();
      if (response.ok) {
        const agents = response_data.data.agents;
        setAgents(agents);
        setFilteredAgents(agents); // Initialize filtered agents
        setCurrentAgent(agents[0]?.name || "No agents added");
        // setIsSearchDisabled(agents.length == 0);
        setIsSearchDisabled(false);
      } else {
        throw new Error('Failed to fetch agents');
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to fetch agents.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  const handleSetCurrentAgent = (agentName) => {
    setCurrentAgent(agentName);
    onAgentsClose(); // Close the modal after setting the current agent
  };

  const addNewAgent = async () => {
    setIsAddingAgentLoading(true);
    setIsDisabled(true);
    setProgress(0);

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + (50 / 120); // increment by 1/120th every second (2 minutes)
      });
    }, 1000);

    try {
      const formData = new FormData();
      formData.append('quip_folder_link', quipLink);
      formData.append('agent_name', newAgentName);
      formData.append('user_email', localStorage.getItem("email"));
      files.forEach((file) => {
        formData.append('files', file); // Append files with the same key 'files'
      });

      const response = await fetch(`${baseDevasUrl}agent/index`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setAgents([...agents, { name: newAgentName }]);
        setFilteredAgents([...agents, { name: newAgentName }]);
        setNewAgentName('');
        setQuipLink('');
        setFiles([]);
        setProgress(100);
        toast({
          title: "Success",
          description: "Successfully indexed the quip documents from the folder!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        });
      } else {
        throw new Error(data.errors);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      setProgress(0); // reset progress on error
    } finally {
      setIsAddingAgentLoading(false);
      setIsDisabled(false);
      clearInterval(interval);
    }
  };

  const highlightURLs = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<span style="background-color: yellow">${url}</span>`);
  };

  const handleSearch = async () => {
    try {
      setIsSearchLoading(true);
      setSearchStarted(true);
      setIsSearchDisabled(true);
      const query = mainSearchTerm;
      setStreamingResponse('');
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      eventSourceRef.current = new EventSource(`${baseDevasUrl}agent/search/elastic?query=${encodeURIComponent(query)}&user_email=${localStorage.getItem("email")}&agent_name=${currentAgent}`);
      eventSourceRef.current.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        setStreamingResponse((prev) => prev + newMessage);
        setIsSearchLoading(false);
      };
      eventSourceRef.current.onerror = (error) => {
        console.error('EventSource error:', error);
        eventSourceRef.current.close();
        setIsSearchDisabled(false);
      };
      eventSourceRef.current.onclose = () => {
        setStreamingResponse((prev) => highlightURLs(prev));
        setIsSearchLoading(false); // Ensure the loading spinner stops when the event source closes
        setIsSearchDisabled(false);
      };
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to fetch response.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
      setIsSearchLoading(false); // Ensure the loading spinner stops on error
    }
  };

  useEffect(() => {
    setLoaded(true);
    fetchAgents();

  }, []);

  useEffect(() => {
    if (isAgentsOpen) {
      fetchAgents();
    }
  }, [isAgentsOpen]);

  useEffect(() => {
    if (isPATModalOpen) {
      fetchPATs();
    }
  }, [isPATModalOpen]);

  useEffect(() => {
    if (agents && agents.length > 0) {
      setFilteredAgents(
        agents.filter(agent => agent.name && agent.name.toLowerCase().includes(agentsSearchTerm.toLowerCase()))
      );
    }
  }, [agentsSearchTerm, agents]);


  useEffect(() => {
    setIsDisabled(newAgentName.trim() === "" || (quipLink.trim() === "" && files.length === 0));
  }, [newAgentName, quipLink, files]);

  useEffect(() => {
    setisPATFormDisabled(newTokenName.trim() == "" || selectedAgent.trim() == ""  || (!noExpiration && newExpireBy.trim() == ""))
  }, [newTokenName, newExpireBy, selectedAgent, noExpiration]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // Function to determine if expiration indicates no expiration
  const isNoExpiration = (expireBy) => {
    // Check if the expiration date is null or if the year is ahead of 2034
    if (!expireBy) {
      return true; // Treat null expiration as no expiration
    }
    
    const expirationYear = new Date(expireBy).getFullYear();
    const currentYear = new Date().getFullYear();
    return expirationYear > currentYear + 30; // Check if expiration year is more than 30 years ahead
  };

  const fetchPATs = async () => {
    try {
      // const response = await axios.get('/api/pats'); // Replace with your actual API endpoint
      const url = `${baseDevasUrl}quip/${localStorage.getItem("email")}/token`
      const response = await fetch(url);
      const jsonResponse = await response.json();
      if (jsonResponse.success) {
        const tokens = jsonResponse.data.map(item => ({
          id: item.id,
          name: item.name || 'default_name',  // Provide a default name if it's null
          expire_by: isNoExpiration(item.expire_by) ? "No Expiray" : item.expire_by,
          value: item.token,
          agent: item.agent,
        }));

        setPatList(tokens); // Assuming tokens is the array you want to set as PatList
      } else {
        console.error('API responded with success false:', jsonResponse.errors);
      }
    } catch (error) {
      console.error('Error fetching PATs:', error);
    }
  };

  const addToken = async () => {
    try {
      // const response = await axios.post('/api/pats', { name: newTokenName, expireOn: newExpireBy });
      // const newToken = response.data;
      const response = await fetch(`${baseDevasUrl}quip/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newTokenName,
          user_email: localStorage.getItem("email"),
          expire_by: noExpiration ? null : newExpireBy,
          custom_rag_id: selectedAgent
        }),
      });

      const response_data = await response.json();
      const token_data = response_data.data
      if (response.ok && response_data.success) {
        const newToken = {
          id: token_data.id,
          name: token_data.name,
          expire_by: isNoExpiration(token_data.expire_by) ? "No Expiray" : token_data.expire_by,
          value: token_data.token,
          agent: token_data.agent
        }

        setPatList([newToken, ...patList]);
        setNewTokenName('');
        setnewExpireBy('');
        setAddedPAT(newToken);
        toast({
          title: "Success",
          description: "Successfully Created Token, Please copy , it will not available again!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        });
      } else {
        throw new Error(response_data.errors);
      }

    } catch (error) {
      let errorMessage = "An error occurred while adding the PAT.";
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  const deleteToken = async (id) => {
    try {
      // await axios.delete(`/api/pats/${id}`);
      const response = await fetch(`${baseDevasUrl}quip/token/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const response_data = await response.json();
      if (response.ok && response_data.success) {
        const updatedTokens = patList.filter(token => token.id !== id);
        setPatList(updatedTokens);
        toast({
          title: "Success",
          description: "Successfully Deleted Token",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        });
      } else {
        throw new Error(response_data.errors);
      }

    } catch (error) {
      console.error('Error deleting PAT:', error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  const copyToken = (token) => {
    // Logic to copy token to clipboard
    navigator.clipboard.writeText(token.value);
    toast({
      title: "Success",
      description: "Successufully Copied to Clipboard",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    });
  };

  const handlePATModalClose = () => {
    setAddedPAT(null);
    setNewTokenName('');
    setnewExpireBy('');
    setSelectedAgent('');
    // Call the original onClose function if needed
    onPATModalClose();
  };

  const handleFilesChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  return (
    <Flex direction="column" minHeight="100vh" bg="white" color="black" align="center">
      <Flex justify="space-between" align="center" p={4} width="100%" maxWidth="800px">
        <Box></Box> {/* Empty Box to push the heading to center */}

        <Flex justify="space-between" align="center" p={4} position="absolute" top={0} right={0}>
          <Button
            variant={"outline"}
            _hover={{ bg: "purple.100" }}
            borderRadius="md"
            textColor={"purple.500"}
            borderColor={"purple.400"}
            mr={4}
            onClick={onAgentsOpen}
          >
            Show My RAGs
          </Button>
          <Button
            bg="purple.600"
            _hover={{ bg: "purple.500" }}
            borderRadius="md"
            textColor={"white"}
            mr={4}
            onClick={onAddAgentOpen}
            leftIcon={<AddIcon />}
          >
            Add New RAG
          </Button>
          <Button
            variant={"outline"}
            _hover={{ bg: "purple.100" }}
            borderRadius="md"
            textColor={"purple.500"}
            borderColor={"purple.400"}
            mr={4}
            onClick={onPATModalOpen}
          >
            <LockIcon mr={1} /> PATs
          </Button>
          <ProfileMenu
            hideWorkspace={true}
            hideConfig={false}
          />
        </Flex>
      </Flex>

      <Box width="100%" maxWidth="800px" mt={4}>
        <Flex direction="column" align="center" mt={20}>
          <Heading as="h1" size="2xl" mb={4} color="purple.700">
            Gyaani
          </Heading>
          {loaded && (
            <Text mb={4} color="red.500" className="typewriter">
              Gyaani is your AI Based helper.
            </Text>
          )}
          <Flex mb={4} alignItems="center" width="100%">
            <Input
              placeholder="Try it..."
              size="lg"
              bg="white"
              border="2px"
              borderColor="gray.300"
              color="black"
              _placeholder={{ color: 'gray.500' }}
              focusBorderColor='purple.500'
              borderRadius="md"
              value={mainSearchTerm}
              onChange={(e) => setMainSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
              width="100%"
              isDisabled={isSearchDisabled}
            />
            <Button
              size="lg"
              ml={2}
              bg="purple.600"
              color="white"
              _hover={{ bg: "purple.500" }}
              borderRadius="md"
              onClick={handleSearch}
              isDisabled={isSearchDisabled || (!isSearchDisabled && mainSearchTerm.trim() == "")}
            >
              &#9654;
            </Button>
          </Flex>
          <Badge colorScheme='purple' mt={1}>Current RAG: {currentAgent || "None"}</Badge>
          {isSearchLoading && (
            <Box mt={10} mr={1}>
              <HashLoader color={"#6B46C1"} />
            </Box>
          )}
        </Flex>
        {streamingResponse && (
          <Box mt={2} p={4} bg="white.100" borderRadius="md" w="100%" h="400px" overflowY="auto">
            <ReactMarkdown components={chakraUIRenderer()}>{streamingResponse}</ReactMarkdown>
          </Box>
        )}
      </Box>

      {/* Modal for showing agents */}
      <Modal isOpen={isAgentsOpen} onClose={onAgentsClose}>
        <ModalOverlay />
        <ModalContent bg="white" color="black">
          <ModalHeader>Manage RAG Agents</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <Input
                placeholder="Search..."
                value={agentsSearchTerm}
                onChange={(e) => setAgentsSearchTerm(e.target.value)}
                bg="white"
                borderColor="purple.300"
                color="black"
                _placeholder={{ color: 'gray.500' }}
                focusBorderColor='purple.500'
                borderRadius="md"
              />
            </FormControl>
            <Box maxHeight="300px" overflowY="auto">
              <Table variant="simple" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th color="black">Agent Name</Th>
                    <Th color="black">Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredAgents && filteredAgents.map((agent, index) => (
                    <Tr key={index}>
                      <Td color="black">{agent.name}</Td>
                      <Td>
                        <Button size="sm" bg="purple.600" color="white" _hover={{ bg: "purple.500" }} onClick={() => handleSetCurrentAgent(agent.name)}>
                          Set as Current
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onAgentsClose} color="black" _hover={{ bg: "purple.100" }}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for adding new agent */}
      <Modal isOpen={isAddAgentOpen} onClose={onAddAgentClose}>
        <ModalOverlay />
        <ModalContent bg="white" color="black">
          <ModalHeader>Add New RAG Agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mt={4}>
              <FormLabel>Agent Name</FormLabel>
              <Input
                placeholder="Agent Name"
                value={newAgentName}
                onChange={(e) => setNewAgentName(e.target.value)}
                bg="white"
                borderColor="purple.300"
                color="black"
                _placeholder={{ color: 'gray.500' }}
                focusBorderColor='purple.500'
                borderRadius="md"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Quip Folder Link</FormLabel>
              <Input
                placeholder="Quip Folder Link"
                value={quipLink}
                onChange={(e) => setQuipLink(e.target.value)}
                bg="white"
                borderColor="purple.300"
                color="black"
                _placeholder={{ color: 'gray.500' }}
                focusBorderColor='purple.500'
                borderRadius="md"
              />
              <Text mt={2} textColor={"red"} fontSize={"12"}><b>Note:</b>Ensure that the folder link you have added has link sharing enabled.</Text>
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Upload Files (*.pdf, *.docx)</FormLabel>
            <Box
              border="2px"
              borderColor="purple.300"
              borderRadius="md"
              p={2}
              textAlign="center"
              cursor="pointer"
              bg="gray.50"
              _hover={{ bg: "gray.100" }}
            >
              <Input
                type="file"
                multiple
                onChange={handleFilesChange}
                opacity={0}
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                right={0}
                width="100%"
                height="100%"
                cursor="pointer"
              />
              <Text color="purple.500">Choose files</Text>
              <Text fontSize="sm" color="gray.500">
                {files.length > 0 ? `${files.length} file(s) selected` : "No file chosen"}
              </Text>
            </Box>
          </FormControl>
            {isAddingAgentLoading && (
              <Progress
                colorScheme="purple"
                size="lg"
                value={progress}
                mt={4}
                isAnimated
                borderRadius="md"
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onAddAgentClose} color="black" _hover={{ bg: "purple.100" }} >Cancel</Button>
            <Button ml={3} bg="purple.600" color="white" _hover={{ bg: "purple.500" }} onClick={addNewAgent} isDisabled={isDisabled}>Add Agent</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      {/* Modal for adding PAT */}
      <Modal isOpen={isPATModalOpen} onClose={handlePATModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <LockIcon mb={2} mr={1} /> Personal Access Tokens
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <HStack>
                <FormControl>
                  <FormLabel>Token Name</FormLabel>
                  <Input
                    value={newTokenName}
                    onChange={(e) => setNewTokenName(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Agent</FormLabel>
                  <Select
                    value={selectedAgent}
                    onChange={(e) => setSelectedAgent(e.target.value)}
                  >
                    <option value="">Select Agent</option>
                    {agents.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>
              <FormControl>
                {!noExpiration && (
                  <>
                    <FormLabel>Expire On</FormLabel>
                    <Stack align='stretch' w='full'>

                      <Input
                        w='50%'
                        type="date"
                        value={newExpireBy}
                        onChange={(e) => setnewExpireBy(e.target.value)}
                      />
                    </Stack>
                  </>
                )}
                <Checkbox
                  w='full'
                  isChecked={noExpiration}
                  onChange={(e) => setNoExpiration(e.target.checked)}
                  mt={2}
                >
                  Do Not Expire
                </Checkbox>

              </FormControl>
              <Button
                leftIcon={<AddIcon />}
                onClick={addToken}
                isDisabled={isPATFormDisabled}
                colorScheme="purple"
              >
                Add Token
              </Button>
            </Stack>

            <Table mt={4} variant="simple">
              <Thead>
                <Tr>
                  <Th>Agent</Th>
                  <Th>Token Name</Th>
                  <Th>Expires On</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {patList.map((token) => (
                  <Tr key={token.id}>
                    <Td>{token.agent}</Td>
                    <Td>{token.name}</Td>
                    <Td>{token.expire_by || 'No Expiration'}</Td>
                    <Td>
                      {token.id == addedPAT?.id && (
                        <Tooltip label="Copy Token">
                          <IconButton
                            icon={<CopyIcon />}
                            aria-label="Copy"
                            onClick={() => copyToken(addedPAT)}
                            colorScheme="white"
                            color={'black'}
                            _hover={{ color: 'gray', background: 'white' }}
                            mr={2}
                          />
                        </Tooltip>
                      )}
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Delete"
                        onClick={() => deleteToken(token.id)}
                        colorScheme="white"
                        color={'red'}
                        _hover={{ color: 'indianred', background: 'white' }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handlePATModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Flex>
  );
}

export default SearchEngine;
