import { Box, Button, Radio, RadioGroup, Stack, Text, Input, useColorMode } from "@chakra-ui/react";
import React, { useState, useEffect } from "react"; // Ensure React is imported since JSX is used
import { useWizard } from "react-use-wizard";
import { useFormContext } from "../utils/useFormContext";
import { updateData } from "../utils/utilities";
import "../style.css";

export function BasicInfoStep() {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const { nextStep, goToStep } = useWizard();
    const [radioValue, setRadioValue] = useState('marketplace'); // State for storing radio button selection
    const { formData, setFormData, currentStep, setCurrentStep } = useFormContext();
    const [brdName, setBrdName] = useState("");
    
    useEffect(() => {
        if (localStorage.getItem("form-data")) {
            setRadioValue(JSON.parse(localStorage.getItem("form-data")).type);
            setBrdName(JSON.parse(localStorage.getItem("form-data")).brd_name);
        } else {
            setFormData({...formData, type: "marketplace" });
        }
        const savedStep = parseInt(localStorage.getItem("form-step"));
        if (savedStep === 0) {
            setCurrentStep(savedStep);
            goToStep(savedStep);
        }
        if (savedStep && savedStep >= 0 && savedStep < 10) {
            setCurrentStep(savedStep);
            goToStep(savedStep);
        }
    }, []);

    const handleRadioChange = (event) => {
        setRadioValue(event);
        setFormData({...formData, type: event });
    };

    const handleBrdNameChange = (event) => {
        const value = event.target.value;
        setBrdName(value);
        setFormData({ ...formData, brd_name: value });
    };

    const handleNextClick = (event) => {
        
        let step = currentStep;
        setCurrentStep(step + 1);
        updateData();
        nextStep();
    }

    return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} m={6}>
        <RadioGroup
            className="type-selection"
            value={radioValue}
            defaultValue="marketplace"
            onChange={(e) => handleRadioChange(e)}
        >
            <Stack spacing={5} direction='column'>
            <Text fontWeight="bold">Select Extension Type</Text>
            <Stack spacing={10} direction='row'>
                <Radio colorScheme='purple' value='marketplace'>
                MarketPlace
                </Radio>
                <Radio colorScheme='purple' value='other'>
                Other
                </Radio>
            </Stack>
            </Stack>
        </RadioGroup>
        <Stack mt={4} align={"center"}>
            <Text fontWeight="bold">BRD Name</Text>
            <Input
                placeholder="Enter document name"
                name="DesignDocName"
                value={brdName}
                onChange={handleBrdNameChange}
                w={"40%"}
            />
        </Stack>
        <Stack direction="row" spacing={4} justify="center" mt={6}>
            <Button onClick={handleNextClick}>Next</Button>
        </Stack>
        </Box>
    );
}
