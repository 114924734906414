import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Button,
  Heading,
  Divider,
  HStack,
  Center,
  Spinner,
  Textarea,
  useColorMode
} from '@chakra-ui/react';
import { useWizard } from "react-use-wizard";
import { useFormContext } from "../utils/useFormContext";
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { baseBRDBuilderUrl, baseDevasUrl, baseUrl } from '../utils/constants';

export function BRDViewer() {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const { previousStep, nextStep } = useWizard();
    const [allData, setAllData] = useState("Initial BRD Data");
    const [processing, setProcessing] = useState(false);
    const { formData, apiData, workFlowData, diagramsData, brdData, setBrdData, currentStep, setCurrentStep, selectedExternalAPIs, mappedFyndApis, extraFyndApis } = useFormContext();
    const [reviewComments, setReviewComments] = useState("");
    const navigate = useNavigate(); 

    const fetchBRD = async (regenerate = false) => {
        document.documentElement.setAttribute('data-color-mode', 'light')
        let docList = formData.documents.map(doc => ({ "value": doc.url }));
        if ((!brdData || brdData.length === 0) || regenerate) {
            setProcessing(true);
            try {
                const fyndAPIs = [...mappedFyndApis, ...extraFyndApis];
                const response = await fetch(`${baseDevasUrl}devas/chat/generate_brd`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ 
                        quip_links: docList, workflows: workFlowData, selected_apis: selectedExternalAPIs,
                        mermaid_diagrams: diagramsData, review_comments: reviewComments, fynd_apis: fyndAPIs
                    }),
                });
                const data = await response.json();
                setAllData(data);
                setBrdData(data);
            } catch (error) {
                console.error('Failed to fetch APIs:', error);
            }
            setProcessing(false);
        } else {
            setBrdData(brdData);
            setAllData(brdData);
        }
    };

    useEffect(() => {
        fetchBRD();
    }, []);

    const handleNextClick = () => {
        setCurrentStep(currentStep + 1);
        nextStep();
    }

    const handlePreviousClick = () => {
        setCurrentStep(currentStep - 1);
        previousStep();
    }

    const handleBRDChange = (newBRD) => {
        setBrdData(newBRD);
    }

    const handleReviewChange = (event) => {
        setReviewComments(event.target.value);
    }

    const handleRegenerate = () => {
        fetchBRD(true);
    }

    const handleGenerateUsingDevas = () => {
        // Add the actual function call here to generate using Devas
        navigate('/devas/coder', { state: { brdData, name: JSON.parse(localStorage.getItem("form-data")).brd_name }})
    }

    return (
        <VStack spacing={4} p={4}>
            <Heading size="md">System Design Document</Heading>
            <Box
                height="500px"
                width="100%"
                overflowY="auto"
                overflowX="auto"
                p={5}
                borderWidth="1px"
                borderRadius="lg"
                textAlign="left"
            >
                {processing ? (
                    <Center>
                        <Spinner size="xl" />
                    </Center>
                ) : (
                    <MarkdownEditor
                        value={allData}
                        onChange={handleBRDChange}
                        height={420}
                        visiableDragbar={false}
                        theme="light"
                    />
                )}
            </Box>

            <Divider />

            <Heading size="md">Comments</Heading>
            <Textarea
                placeholder="Add your comments here..."
                size="md"
                value={reviewComments}
                onChange={handleReviewChange}
                height="100px"
                borderColor="gray.300"
                focusBorderColor="purple.400"
            />

            <HStack mt={10} justify="center">
                <Button onClick={handlePreviousClick}>Back</Button>
                <Button onClick={handleRegenerate}>Regenerate BRD</Button>
                <Button onClick={handleGenerateUsingDevas} rightIcon={<ExternalLinkIcon />}>Generate using Devas</Button>
                <Button onClick={handleNextClick}>Next</Button>
            </HStack>
        </VStack>
    );
}
