// Layout.js
import React from "react";
import { Box } from "@chakra-ui/react";
import Navbar from "./NavBar"; // Make sure to import the Navbar component

export const Layout = ({ children }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Navbar /> {/* Include the navigation bar here */}
      <Box flex={3} ml="40vh"> {/* Adjust margin to fit the sidebar width */}
        {children} {/* The children prop will be the main content of the current page */}
      </Box>
    </Box>
  );
};

